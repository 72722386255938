// export { default } from './EventTeams';
import PageLayout from "../../layout"
import { adminRole } from '../../../_services/auth';
// import EventTeams from './EventTeams';
import EventTeamHeadJudges from './EventTeam_HeadJudges';
import EventPoolTeams from './EventPoolTeams';


const EventTeamRoleBasedPage = props => {
  return (
    <PageLayout>
      {adminRole() === 'Judge' && (
        <EventPoolTeams />
      )}
      {adminRole() === 'Moderator' && (
        <EventPoolTeams />
      )}
      {adminRole() === 'Head_Judge' && (
        <EventTeamHeadJudges />
      )}
      {adminRole() === 'Timekeeper' && (
        <EventPoolTeams />
      )}
      {adminRole() === 'Scrutineer' && (
        <EventPoolTeams />
      )}
    </PageLayout>
  )
}

export default EventTeamRoleBasedPage
