import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { LoadingView } from '../common';
import PageLayout from "../layout"
import { admin_Status } from '../../_services/auth';
import moment from 'moment';
import toastr from 'toastr';
import { EventApi } from '../../api';
import { postData, getData, putData } from '../../_services/apiCommonService';

import { Row, Col, Modal, Button, Input, Form, Radio, Table, DatePicker } from 'antd';
import './EventInfo.scss';
import { EditOutlined } from '@ant-design/icons';

const EventInfo = () => {
  if (admin_Status() === 'false') {
    window.location.href = '/login';
  }

  const [loading, setloading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [activeUsers, setActiveUsers] = useState('acitve');
  const [newEventsList, setNewEventsList] = useState([]);
  const [activeEventsList, setActiveEventsList] = useState([]);
  const [inActiveEventsList, setInActiveEventsList] = useState([]);
  const [events, setEvents] = useState(null);

  const [activePage, setActivePage] = useState(1);
  const [form] = Form.useForm();
  const { RangePicker } = DatePicker;

  const columns = [
    {
      title: 'Event',
      dataIndex: 'name',
      sorter: (a, b) => a.name.length - b.name.length,
      render: (record, val) => {
        return (
          <Link
            to={{
              pathname: `/events/${val.id}`,
              state: record
            }}
          >
            {record}
          </Link>
        )
      },
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      render: record => moment(record).calendar(),
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      render: record => moment(record).calendar(),
    },
    {
      title: 'Action',
      dataIndex: 'name',
      render: (record, data) => {
        return (
          <span
            onClick={(e) => showSelectedEventModal(record, data)}
            className='EditOutlinedAction nameLink'
          >
            <EditOutlined />
          </span>
        )
      }
    },
  ];

  const fetchAllNewEvents = () => {
    getData(EventApi.getNotStartedEvents().url)
      .then((response) => {
        const data = response.data.events;
        setNewEventsList(
          data.map(event => ({
            key: event.id,
            id: event.id,
            name: event.name,
            startDate: event.startDate,
            endDate: event.endDate,
            isActive: `${event.isActive}`,
          }))
        );
      })
      .catch((e) => {
        toastr.error(e.data.message);
      })
      .finally(() => {
        setloading(false)
      });
  };

  const fetchAllActiveEvents = () => {
    getData(EventApi.getStartedEvents().url)
      .then((response) => {
        const data = response.data.events;
        setActiveEventsList(
          data.map(event => ({
            key: event.id,
            id: event.id,
            name: event.name,
            startDate: event.startDate,
            endDate: event.endDate,
            isActive: `${event.isActive}`,
          }))
        );
      })
      .catch((e) => {
        toastr.error(e.data.message);
      })
      .finally(() => {
        setloading(false)
      });
  };

  const fetchAllInActiveEvents = () => {
    getData(EventApi.getCompletedEvents().url)
      .then((response) => {
        const data = response.data.events;
        setInActiveEventsList(
          data.map(event => ({
            key: event.id,
            id: event.id,
            name: event.name,
            startDate: event.startDate,
            endDate: event.endDate,
            isActive: `${event.isActive}`,
          }))
        );
      })
      .catch((e) => {
        toastr.error(e.data.message);
      })
      .finally(() => {
        setloading(false)
      });
  };

  useEffect(() => {
    setActiveUsers('acitve');
    fetchAllActiveEvents();
    // fetchAllInActiveEvents();
  }, [events]);

  /***************************Start Add Event*****************************/
  const OpenCreateEventModal = () => {
    setIsModalVisible(true);
  };

  const CloseCreateEventModal = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const onChangeUserStatus = (e) => {
    setActiveUsers(e.target.value);
    if (e.target.value === 'newevent') {
      fetchAllNewEvents();
    } else if (e.target.value === 'acitve') {
      fetchAllActiveEvents();
    } else {
      fetchAllInActiveEvents();
    }
  }

  const updateData = async (fieldsValue) => {
    const rangeValue = fieldsValue['range-picker'];
    const values = {
      'name': fieldsValue.name,
      'startDate': rangeValue[0],
      'endDate': rangeValue[1],
    };
    setloading(true);

    postData(EventApi.addEvents().url, values)
      .then((response) => {
        setEvents(response.data);
        toastr.success('Successfully Submitted');
        setIsModalVisible(false);
      })
      .catch((e) => {
        toastr.error(e.data.message);
      })
      .finally(() => {
        setloading(false);
        form.resetFields();
      });
  };

  const renderModalContent = () => {
    if (loading) return <LoadingView message="Loading..." />;
    return (
      <div className="CreateEvent">
        <Row>
          <Col sm={24}>
            <Form
              form={form}
              layout="vertical"
              name="form_in_modal"
              initialValues={{ remember: false }}
            >
              <Form.Item
                name="name"
                label="Name"
                rules={[{ required: true, message: 'Please enter name' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="range-picker"
                label="Date"
                rules={[{ required: true, message: 'Please select date' }]}
              >
                <RangePicker placeholder={['Start Date', 'End Date']} />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </div>
    );
  };
  /***************************End Add Event*****************************/
  /***************************Start Edit Event**************************/
  const [selectedEventModal, setSelectedEventModal] = useState(false);
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [selectedEventName, setSelectedEventName] = useState('');
  const [selectedEventStartDate, setSelectedEventStartDate] = useState('');
  const [selectedEventEndDate, setSelectedEventEndDate] = useState('');

  const showSelectedEventModal = (record, data) => {
    setSelectedEventModal(true);
    setSelectedEventId(data.id);
    setSelectedEventName(record);
    setSelectedEventStartDate(data.startDate);
    setSelectedEventEndDate(data.endDate);
  };

  const CancelSelectedEventModal = () => {
    setSelectedEventModal(false);
    setSelectedEventId(null);
    setSelectedEventName('');
    setSelectedEventStartDate('');
    setSelectedEventEndDate('');
  };

  const onChangeSelectedEventName = e => {
    setSelectedEventName(e.target.value);
  };

  const onChangeSelectedEventDate = (dates) => {
    setSelectedEventStartDate(dates[0]);
    setSelectedEventEndDate(dates[1]);
  }

  const updateSelectedEventModal = () => {
    if (selectedEventName === '') {
      toastr.error('Please enter comment');
    } else {
      const values = {
        'name': selectedEventName,
        'startDate': selectedEventStartDate,
        'endDate': selectedEventEndDate,
      };
      setloading(true);
      putData(EventApi.updateEditEvent(selectedEventId).url, values)
        .then((response) => {
          toastr.success('Successfully Submitted');
        })
        .catch((e) => {
          toastr.error(e.data.message);
        })
        .finally(() => {
          setloading(false);
          setSelectedEventModal(false);
          fetchAllActiveEvents();
          setSelectedEventId(null);
          setSelectedEventName('');
          setSelectedEventStartDate('');
          setSelectedEventEndDate('');
        })
    }

  };

  const renderEditEventModal = () => {
    return (
      <React.Fragment>
        <p><span className="redMandatory">*</span><strong>Name</strong></p>
        <Input
          value={selectedEventName}
          onChange={onChangeSelectedEventName}
        />
        <p><span className="redMandatory">*</span><strong>Date</strong></p>
        <RangePicker
          value={[moment(selectedEventStartDate, 'YYYY-MM-DD'), moment(selectedEventEndDate, 'YYYY-MM-DD')]}
          onChange={onChangeSelectedEventDate}
          placeholder={['Start Date', 'End Date']}
        />
      </React.Fragment>
    )
  }

  /***************************End Edit Event**************************/
  const handlePageChange = value => {
    setActivePage(value.current);
  }

  const renderEventsInfoContent = () => {
    if (loading) return <LoadingView message="Loading..." />;
    return (
      <div className="site-card-wrapper">
        <Row gutter={16}>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
            <Button type="primary" onClick={OpenCreateEventModal}>
              Add Event
            </Button>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
            <div className="eventActiveRow">
              <Radio.Group defaultValue="acitve" buttonStyle="solid" onChange={onChangeUserStatus}>
                <Radio.Button value="newevent">New Event</Radio.Button>
                <Radio.Button value="acitve">Active Events</Radio.Button>
                <Radio.Button value="inacitve">Completed Events</Radio.Button>\
              </Radio.Group>
            </div>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>

            {
              activeUsers === 'newevent' && (
                <Table
                  columns={columns}
                  dataSource={newEventsList}
                  rowKey={record => record.id}
                  className='addEventList'
                  pagination={{ current: activePage }}
                  onChange={handlePageChange}
                />
              )
            }
            {
              activeUsers === 'acitve' && (
                <Table
                  columns={columns}
                  dataSource={activeEventsList}
                  rowKey={record => record.id}
                  className='addEventList'
                  pagination={{ current: activePage }}
                  onChange={handlePageChange}
                />
              )
            }
            {
              activeUsers === 'inacitve' && (
                <Table
                  columns={columns}
                  dataSource={inActiveEventsList}
                  rowKey={record => record.id}
                  className='addEventList'
                  pagination={{ current: activePage }}
                  onChange={handlePageChange}
                />
              )
            }
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <PageLayout>
      <div className="eventsInfo ">
        {renderEventsInfoContent()}
      </div>
      <Modal title="Add Event"
        okText="Add Event"
        visible={isModalVisible}
        onCancel={CloseCreateEventModal}
        cancelText="Cancel"
        onOk={() => {
          form
            .validateFields()
            .then(values => {
              form.resetFields();
              updateData(values);
            })
            .catch(info => {
              console.log('Validate Failed:', info);
            });
        }}
      >
        {renderModalContent()}
      </Modal>
      <Modal
        title="Edit Event"
        visible={selectedEventModal}
        okText='Update'
        onOk={updateSelectedEventModal}
        onCancel={CancelSelectedEventModal}
        cancelText="Cancel"
      >
        {renderEditEventModal()}
      </Modal>
    </PageLayout>
  );
};

export default EventInfo;


