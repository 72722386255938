import React, { useState, useEffect } from 'react';
import { useParams, useHistory, useLocation } from "react-router-dom";
import { LoadingView } from '../../common';

import toastr from 'toastr';
import { EventApi } from '../../../api';
import { postData, getData } from '../../../_services/apiCommonService';
import { eventId } from '../../../_services/auth';

import { Row, Col, Collapse, Tabs, Table, Input, Popconfirm, Button, Breadcrumb, } from 'antd';
import '../../Judges/Event/EventTeams.scss';

const EventHeadJudges = props => {
  const location = useLocation();
  if (location.state === undefined) {
    window.location.href = '/';
  }

  let eventName = location.state.eventName
  let teamName = location.state.teamName
  let poolId = location.state.poolId

  const { tId } = useParams();
  const history = useHistory();
  const { Panel } = Collapse;
  const { TabPane } = Tabs;
  const { TextArea } = Input;

  const [loading, setloading] = useState(true);
  const [eventTeamDisciplinesList, setEventTeamDisciplinesList] = useState([]);
  const [disciplineId, setDisciplineId] = useState(null);
  const [judgeList, setJudgeList] = useState([]);
  const [judgeId, setJudgeId] = useState(null);

  const [usersCommentsList, setUsersCommentsList] = useState([]);
  const [commentsList, setCommentsList] = useState([]);
  const [judgeComment, setJudgeComment] = useState('');
  const [moderatorComment, setModeratorComment] = useState('');
  const [headJudgesComment, setHeadJudgesComment] = useState('');
  const [disciplineScore, setDisciplineScore] = useState(null);

  const [submittedStatus, setSubmittedStatus] = useState('');
  const [btnActionText, setBtnActionText] = useState('');
  const [mode, setMode] = useState('top');

  const [performanceText, setPerformanceText] = useState(null);

  const columns = [
    {
      title: 'Comment',
      dataIndex: 'comment',
      width: '85%',
      render: record => {
        return (
          `${record.name}`
        )
      },
    },
    {
      title: 'Score',
      dataIndex: 'score',
      render: record => {
        return (
          `${record}`
        )
      },
    },
  ];

  const loadTeamsDisciplines = () => {
    setloading(true);
    getData(EventApi.headJudges_GetTeamsDisciplines(eventId(), poolId, tId).url)
      .then((response) => {
        const data = response.data.disciplines;
        setEventTeamDisciplinesList(
          data.map(et => ({
            key: et.id,
            id: et.id,
            name: et.name,
            parent: et.parent,
            beforeName: et.beforeName,
            duringName: et.duringName,
            performanceName: et.performanceName,
          }))
        );
      })
      .catch((e) => {
        toastr.error(e.data.message);
      })
      .finally(() => {
        setloading(false)
      });
  };

  useEffect(() => {
    if (tId) {
      loadTeamsDisciplines();
      setMode('top')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tId]);

  const getDisciplinesId = async (key) => {

    if (!key) {
      console.log(key);
    } else {
      let keyValue = key,
        pos = keyValue.indexOf(',');

      let keyId = Number(keyValue.substring(0, pos));
      let keyPerformanceName = keyValue.substring(pos + 1);

      setDisciplineId(keyId);
      setUsersCommentsList([]);

      if (!keyPerformanceName) {
        getData(EventApi.headJudges_Getjudges(eventId(), poolId, tId, keyId).url)
          .then((response) => {
            const data = response.data.judges;
            if (data !== null) {
              setJudgeList(data);
              const firstDisciplinesId = keyId;
              const firstJudgeId = data[0].id;
              loadTabsFirstJudge(firstDisciplinesId, firstJudgeId);
            } else {
              console.log(judgeComment);
              console.log(moderatorComment);
            }
          })
      } else {
        setPerformanceText(keyPerformanceName);
        getData(EventApi.headJudges_Getjudges(eventId(), poolId, tId, keyId).url)
          .then((response) => {
            const data = response.data.judges;
            if (data !== null) {
              setJudgeList(data);
              const firstDisciplinesId = keyId;
              const firstJudgeId = data[0].id;
              loadTabsFirstJudgeParent(firstDisciplinesId, firstJudgeId, keyPerformanceName);
            } else {
              console.log(judgeComment);
              console.log(moderatorComment);
            }

          })
      }


    }

  }

  const loadTabsFirstJudge = async (firstDisciplinesId, firstJudgeId) => {
    getData(EventApi.headJudges_GetCommentsStats(eventId(), poolId, tId, firstDisciplinesId, firstJudgeId).url)
      .then((response) => {
        const data = response.data.stats;
        if (data !== null) {
          setJudgeId(firstJudgeId);
          setJudgeComment(data.judgeComment);
          setModeratorComment(data.moderatorComment);
          setDisciplineScore(data.disciplineScore);
          setCommentsList(data.commentScores);
          setSubmittedStatus(data.status);
        }

      })

    getData(EventApi.getUserCommentsListByJudge(eventId(), poolId, tId, firstDisciplinesId, firstJudgeId).url)
      .then((response) => {
        const data = response.data.commentTexts;
        if (data !== null) {
          setUsersCommentsList(data)
        }
      }
      )
  }

  const loadTabsFirstJudgeParent = async (firstDisciplinesId, firstJudgeId, keyPerformanceName) => {
    getData(EventApi.headJudges_GetCommentsStatsParent(eventId(), poolId, tId, firstDisciplinesId, firstJudgeId, keyPerformanceName).url)
      .then((response) => {
        const data = response.data.stats;
        if (data !== null) {
          setJudgeId(firstJudgeId);
          setJudgeComment(data.judgeComment);
          setModeratorComment(data.moderatorComment);
          setDisciplineScore(data.disciplineScore);
          setCommentsList(data.commentScores);
          setSubmittedStatus(data.status);
        }

      })

    getData(EventApi.getUserCommentsListByJudgeParent(eventId(), poolId, tId, firstDisciplinesId, firstJudgeId, keyPerformanceName).url)
      .then((response) => {
        const data = response.data.commentTexts;
        if (data !== null) {
          setUsersCommentsList(data)
        }
      }
      )
  }

  const Tabscallback = async (key) => {
    setJudgeId(key);
    setJudgeComment('');
    setDisciplineScore(null);
    setCommentsList([]);

    getData(EventApi.headJudges_GetCommentsStats(eventId(), poolId, tId, disciplineId, key).url)
      .then((response) => {
        const data = response.data.stats;
        if (data !== null) {
          setJudgeComment(data.disciplineComment);
          setModeratorComment(data.moderatorComment);
          setDisciplineScore(data.disciplineScore);
          setCommentsList(data.commentScores);
          setSubmittedStatus(data.status);
        }
      })

    getData(EventApi.getUserCommentsListByJudge(eventId(), poolId, tId, disciplineId, key).url)
      .then((response) => {
        const data = response.data.commentTexts;
        if (data !== null) {
          setUsersCommentsList(data)
        }
      }
      )
  }

  const TabscallbackParent = async (key) => {
    setJudgeId(key);
    setJudgeComment('');
    setDisciplineScore(null);
    setCommentsList([]);

    getData(EventApi.headJudges_GetCommentsStatsParent(eventId(), poolId, tId, disciplineId, key, performanceText).url)
      .then((response) => {
        const data = response.data.stats;
        if (data !== null) {
          setJudgeComment(data.disciplineComment);
          setModeratorComment(data.moderatorComment);
          setDisciplineScore(data.disciplineScore);
          setCommentsList(data.commentScores);
          setSubmittedStatus(data.status);
        }
      })

    getData(EventApi.getUserCommentsListByJudgeParent(eventId(), poolId, tId, disciplineId, key, performanceText).url)
      .then((response) => {
        const data = response.data.commentTexts;
        if (data !== null) {
          setUsersCommentsList(data)
        }
      }
      )
  }

  const ApprovedReview = 'APPROVED';

  const getBtnActionInfo = async (BtnActionInfo) => {
    setBtnActionText(BtnActionInfo);
  }

  const onChangeHeadJudgeComment = (e) => {
    setHeadJudgesComment(e.target.value);
  };

  const onChangeDisciplineScore = (e) => {
    let { value, min, max } = e.target;
    value = Math.max(Number(min), Math.min(Number(max), Number(value)));
    setDisciplineScore(value);
  };

  const updateModerator = async () => {
    if (headJudgesComment === undefined || headJudgesComment === '') {
      toastr.error('Please enter Head Judges comment');
    } else {
      const moderator = {
        action: btnActionText,
        disciplineScore: disciplineScore,
        headJudgeComment: headJudgesComment,
        performanceName: performanceText,
      }
      // setloading(true);
      postData(EventApi.headJudges_AddCommentsStats(eventId(), poolId, tId, disciplineId, judgeId).url, moderator)
        .then((response) => {
          toastr.success('Successfully Submitted');
          setSubmittedStatus(response.data.stats.status);
        })
        .catch((error) => {
          toastr.error(
            error.data.message === 'You cannot update the discipline at this state'
              ? 'Already Submitted' : 'Something Wrong!!!');
        })
        .finally(() => {
          setloading(false);
          loadTeamsDisciplines();
        });
    }
  };

  const genExtra = () => {
    if (submittedStatus === 'APPROVED') {
      return (
        <span>Score Approved</span>
      )
    } else if (submittedStatus === 'HEAD_JUDGE_REVIEW_REQUIRED') {
      return (
        <span>Head Judge Review</span>
      )
    }
    else if (submittedStatus === 'JUDGE_RESUBMITTED') {
      return (
        <span>Moderator Review</span>
      )
    }
    else if (submittedStatus === 'JUDGE_REVIEW_REQUIRED') {
      return (
        <span>Sent back to Judge</span>
      )
    }
    else if (submittedStatus === 'JUDGE_SUBMITTED') {
      return (
        <span>Moderator Review</span>
      )
    }
    else {
      return (
        <span>Score Pending</span>
      )
    }
  };

  const renderEventsInfoContent = () => {
    if (loading) return <LoadingView message="Loading..." />;
    return (
      <div className="teamDisciplines moderators">
        <Collapse accordion onChange={getDisciplinesId}>

          {(eventTeamDisciplinesList || []).map(disc => (
            <React.Fragment>
              {
                disc.parent === null ? (
                  <Panel header={disc.name} key={[disc.id, disc.performanceName]}>
                    <Row>
                      <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
                        <Tabs defaultActiveKey="1" tabPosition={mode} onChange={Tabscallback}>
                          {(judgeList || []).map(judge => (
                            <TabPane tab={judge.name} key={judge.id}>
                              <span className='submittedStatus'>
                                {genExtra()}
                              </span>
                              <div className="TabsRow ">
                                <div className="TabsCol customStyle">
                                  <Table dataSource={commentsList} columns={columns} pagination={false} />
                                </div>
                                <div className="TabsCol customStyle">
                                  <div className='scoreCircle'>
                                    <Input
                                      type='number'
                                      min='85'
                                      max='100'
                                      value={disciplineScore}
                                      onChange={onChangeDisciplineScore}
                                      placeholder="Score"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="TabsRow2">
                                <p><strong>Head Judge Comment</strong></p>
                                <TextArea
                                  rows={4}
                                  placeholder="Type your comment here"
                                  onChange={onChangeHeadJudgeComment}
                                  disabled={(
                                    submittedStatus === 'APPROVED'
                                  ) ? true : false}
                                />
                              </div>
                              <br />
                              <div className="commentsListBox">
                                {usersCommentsList.length > 0 && (<p><strong>Comments</strong></p>)}
                                <ul>
                                  {
                                    (usersCommentsList || []).map(item => (
                                      <li>
                                        {item.roleName === 'JUDGE' && (<strong>Judge</strong>)}
                                        {item.roleName === 'MODERATOR' && (<strong>Moderator</strong>)}
                                        {item.roleName === 'HEAD_JUDGE' && (<strong>Head judge</strong>)}
                                        <br />
                                        {item.comment}
                                      </li>
                                    ))
                                  }
                                </ul>
                              </div>
                            </TabPane>
                          ))}
                        </Tabs>
                      </Col>
                    </Row>

                    <Row>
                      <Col span={24}>
                        <div className="moderatorsFooter">
                          <Popconfirm
                            title="Are you sure?"
                            onConfirm={() => updateModerator()}
                            disabled={(
                              submittedStatus === 'APPROVED' ||
                              submittedStatus === 'JUDGE_RESUBMITTED' ||
                              submittedStatus === 'JUDGE_SUBMITTED'
                            ) ? true : false}
                          >
                            <Button type="primary" onClick={() => getBtnActionInfo(ApprovedReview)}>Proceed</Button>
                          </Popconfirm>
                        </div>
                      </Col>
                    </Row>
                  </Panel>
                ) : (
                  <Panel header={disc.performanceName} key={[disc.id, disc.performanceName]}>
                    <Row>
                      <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
                        <Tabs defaultActiveKey="1" tabPosition={mode} onChange={TabscallbackParent}>
                          {(judgeList || []).map(judge => (
                            <TabPane tab={judge.name} key={judge.id}>
                              <span className='submittedStatus'>
                                {genExtra()}
                              </span>
                              <div className="TabsRow ">
                                <div className="TabsCol customStyle">
                                  <Table dataSource={commentsList} columns={columns} pagination={false} />
                                </div>
                                <div className="TabsCol customStyle">
                                  <div className='scoreCircle'>
                                    <Input
                                      type='number'
                                      min='85'
                                      max='100'
                                      value={disciplineScore}
                                      onChange={onChangeDisciplineScore}
                                      placeholder="Score"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="TabsRow2">
                                <p><strong>Head Judge Comment</strong></p>
                                <TextArea
                                  rows={4}
                                  placeholder="Type your comment here"
                                  onChange={onChangeHeadJudgeComment}
                                  disabled={(
                                    submittedStatus === 'APPROVED'
                                  ) ? true : false}
                                />
                              </div>
                              <br />
                              {
                                // <div className="TabsRow">
                                //   <div className="TabsCol">
                                //     <p><strong>Judge's Comment</strong></p>
                                //     <TextArea
                                //       rows={4}
                                //       value={judgeComment}
                                //       disabled
                                //     />
                                //   </div>
                                //   <div className="TabsCol">
                                //     <div className="moderatorsComment">
                                //       <p><strong>Moderator's Comment</strong></p>
                                //       <TextArea
                                //         rows={4}
                                //         placeholder="Type your comment here"
                                //         value={moderatorComment}
                                //         disabled
                                //       />
                                //     </div>
                                //   </div>
                                // </div>
                              }
                              <div className="commentsListBox">
                                {usersCommentsList.length > 0 && (<p><strong>Comments</strong></p>)}
                                <ul>
                                  {
                                    (usersCommentsList || []).map(item => (
                                      <li>
                                        {item.roleName === 'JUDGE' && (<strong>Judge</strong>)}
                                        {item.roleName === 'MODERATOR' && (<strong>Moderator</strong>)}
                                        {item.roleName === 'HEAD_JUDGE' && (<strong>Head judge</strong>)}
                                        <br />
                                        {item.comment}
                                      </li>
                                    ))
                                  }
                                </ul>
                              </div>
                            </TabPane>
                          ))}
                        </Tabs>
                      </Col>
                    </Row>

                    <Row>
                      <Col span={24}>
                        <div className="moderatorsFooter">
                          <Popconfirm
                            title="Are you sure?"
                            onConfirm={() => updateModerator()}
                            disabled={(
                              submittedStatus === 'APPROVED' ||
                              submittedStatus === 'JUDGE_RESUBMITTED' ||
                              submittedStatus === 'JUDGE_SUBMITTED'
                            ) ? true : false}
                          >
                            <Button type="primary" onClick={() => getBtnActionInfo(ApprovedReview)}>Proceed</Button>
                          </Popconfirm>
                        </div>
                      </Col>
                    </Row>
                  </Panel>
                )
              }

            </React.Fragment>

          ))}

        </Collapse>

      </div>
    );
  };

  const renderBreadcrumb = () => (
    <Breadcrumb>
      <Breadcrumb.Item href="/">
        {eventName}
      </Breadcrumb.Item>
      <Breadcrumb.Item onClick={() => history.goBack()}>
        <span>{teamName}</span>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        <span>Disciplines</span>
      </Breadcrumb.Item>
    </Breadcrumb>
  );

  return (
    <React.Fragment>
      {renderBreadcrumb()}
      <div className="EventTeams">
        {renderEventsInfoContent()}
      </div>
    </React.Fragment>
  )
}

export default EventHeadJudges
