import { useState, useEffect } from "react";
import { Row, Col, Table } from "antd";
import PageLayout from "../../layout";
import { EventApi } from "../../../api";
import { getData } from "../../../_services/apiCommonService";
import toastr from "toastr";
import { EditOutlined } from "@ant-design/icons";

function MyActions(props) {
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    getNotifications();
  }, []);

  const getNotifications = () => {
    getData(EventApi.getNotifications(localStorage.getItem("userId")).url)
      .then((response) => {
        setNotifications(response.data.userNotifications || []);
      })
      .catch((err) => {
        toastr.error(err);
      });
  };

  const columns = [
    {
      title: "Event",
      dataIndex: "event",
      render: (record) => {
        return <span className="nameLink">{record.name}</span>;
      },
    },
    {
      title: "Team",
      dataIndex: "team",
      render: (record, val) => {
        return <span>{record.name}</span>;
      },
    },
    {
      title: "Discipline",
      dataIndex: "discipline",
      render: (record) => {
        return <span>{record.name}</span>;
      },
    },
    {
      title: "Action",
      dataIndex: "name",
      render: (record, data) => {
        return (
          <span
            onClick={(e) => props.history.push("/my-actions/"+data.id, { notification: data })}
            className="nameLink"
          >
            <EditOutlined />
          </span>
        );
      },
    },
  ];

  return (
    <PageLayout>
      <div>
        <h4 style={{ color: "#fff" }}>My Actions</h4>
        <Row gutter={16}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
            <Table
              columns={columns}
              dataSource={notifications}
            />
          </Col>
        </Row>
      </div>
    </PageLayout>
  );
}

export default MyActions;
