
import React from 'react';
import PageLayout from "../components/layout"

import toastr from 'toastr';
import { UsersApi } from '../api';
import { postData } from '../_services/apiCommonService';

import { Button, Input, Form } from 'antd';
import './LoginPage.scss';

const resetPassword = (props) => {

  const onFinish = (values) => {
    console.log('Success:', values);
    postData(UsersApi.userResetPassword().url, values)
      .then((response) => {
        toastr.success('Successfully Submitted');
        localStorage.removeItem('user');
        window.location.href = '/login';
      })
      .catch((e) => {
        toastr.error(e.data.message);
      })
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <PageLayout>
      <div className="resetPassword">
        <Form
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            name="currentPassword"
            label="Current Password"
            rules={[
              {
                required: true,
                message: 'Please enter current password!',
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            name="newPassword"
            label="New Password"
            rules={[
              {
                required: true,
                message: 'Please enter new password!',
              },
            ]}
            hasFeedback
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            label="Confirm Password"
            dependencies={['newPassword']}
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Please enter confirm password!',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('newPassword') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('The two passwords that you entered do not match!'));
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item className="resetBtn">
            <Button type="primary" htmlType="submit">
              Reset Password
            </Button>
          </Form.Item>
        </Form>
      </div>
    </PageLayout>
  );
};

export default resetPassword;
