
import React, { useState, useEffect } from 'react';
import { LoadingView } from '../common';
import PageLayout from "../layout"
import { admin_Status } from '../../_services/auth';
import toastr from 'toastr';
import { TeamsApi } from '../../api';
import { postData, getData, putData } from '../../_services/apiCommonService';

import { Row, Col, Modal, Button, Input, Form, Table, Select } from 'antd';
import './TeamsInfo.scss';

const TeamsInfo = () => {
  if (admin_Status() === 'false') {
    window.location.href = '/login';
  }

  const [loading, setloading] = useState(true);
  const [teamsList, setTeamsList] = useState([]);
  const [regionsList, setRegionsList] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [teams, setTeams] = useState(null);
  const [activePage, setActivePage] = useState(1);

  const [form] = Form.useForm();
  const { Option } = Select;

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: (a, b) => a.name.length - b.name.length,
      render: (record, val) => {
        return (
          <span
            className="nameLink"
            onClick={(e) => showSelectedTeamModal(record, val)}
          >
            {record}
          </span>
        )
      },
    },
    {
      title: 'Region',
      dataIndex: 'region',
      render: (record) => {
        return (
          <span>
            {record.name}
          </span>
        )
      },
    },
  ];

  const fetchAllTeams = () => {
    setloading(true);
    getData(TeamsApi.getTeams().url)
      .then((response) => {
        const data = response.data.teams;
        setTeamsList(
          data.map(team => ({
            id: team.id,
            name: team.name,
            region: team.region === null ? '-' : team.region,
          }))
        );
      })
      .catch((e) => {
        toastr.error(e.data.message);
      })
      .finally(() => {
        setloading(false)
      });
  };

  const fetchAllRegions = () => {
    setloading(true);
    getData(TeamsApi.getRegions().url)
      .then((response) => {
        const data = response.data.regions;
        setRegionsList(
          data.map(reg => ({
            id: reg.id,
            name: reg.name,
          }))
        );
      })
      .catch((e) => {
        toastr.error(e.data.message);
      })
      .finally(() => {
        setloading(false)
      });
  };

  useEffect(() => {
    fetchAllTeams();
    fetchAllRegions();
  }, [teams]);

  /***************************Start Add Teams**************************/
  const OpenCreateTeamModal = () => {
    setIsModalVisible(true);
  };

  const CloseCreateTeamModal = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const updateData = async (values) => {
    setloading(true);
    postData(TeamsApi.addTeams().url, values)
      .then((response) => {
        setTeams(response.data);
        toastr.success('Successfully Submitted');
      })
      .catch((e) => {
        toastr.error(e.data.message);
      })
      .finally(() => {
        setloading(false);
        setIsModalVisible(false);
        form.resetFields();
      });
  };

  const renderModalContent = () => {
    return (
      <div className="CreateEvent">
        <Row>
          <Col sm={24}>
            <Form
              form={form}
              layout="vertical"
              name="form_in_modal"
            >
              <Form.Item
                name="name"
                label="Name"
                rules={[{ required: true, message: 'Please enter name' }]}
              >
                <Input
                  name="teamTitle"
                />
              </Form.Item>
              <Form.Item
                name="regionId"
                label="Region"
                rules={[{ required: true, message: 'Please enter region' }]}
              >
                <Select
                  placeholder="Please select region"
                  allowClear
                >
                  {
                    regionsList.map(reg => (
                      <Option value={reg.id} key={reg.id}>{reg.name}</Option>
                    ))
                  }
                </Select>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </div>
    );
  };
  /***************************End Add Teams****************************/
  /***************************Start Edit Teams*************************/
  const [selectedTeamId, setSelectedTeamId] = useState(null);
  const [selectedTeamName, setSelectedTeamName] = useState('');
  const [selectedRegionsId, setSelectedRegionsId] = useState(null);
  const [selectedTeamModal, setSelectedTeamModal] = useState(false);

  const showSelectedTeamModal = async (record, data) => {
    setSelectedTeamModal(true);
    setSelectedTeamId(data.id);
    setSelectedTeamName(data.name);
    setSelectedRegionsId(data.region.id);
  };

  const cancelSelectedTeamModal = async () => {
    setSelectedTeamModal(false);
    setSelectedTeamId(null);
    setSelectedTeamName('');
    setSelectedRegionsId(null);
  };

  const onChangeEditTeamName = e => {
    setSelectedTeamName(e.target.value);
  }

  const onChangeEditRegionsId = value => {
    setSelectedRegionsId(value);
  }

  const updateSelectedTeams = async () => {

    if (selectedTeamName === '') {
      toastr.error('Please enter name');
    } else {
      const values = {
        name: selectedTeamName,
        regionId: selectedRegionsId,
      }
      setloading(true);
      putData(TeamsApi.updateEditTeam(selectedTeamId).url, values)
        .then((response) => {
          toastr.success('Successfully Submitted');
        })
        .catch((e) => {
          toastr.error(e.data.message);
        })
        .finally(() => {
          setloading(false);
          setSelectedTeamModal(false);
          setSelectedTeamId(null);
          setSelectedTeamName('');
          setSelectedRegionsId(null);
          fetchAllTeams();
        })
    }
  };

  const renderSelectedTeamModalContent = () => {
    return (
      <div>
        <p><span className="redMandatory">*</span><strong>Team</strong></p>
        <Input
          value={selectedTeamName}
          onChange={onChangeEditTeamName}
        />

        <p><span className="redMandatory">*</span><strong>Region</strong></p>
        <Select
          value={selectedRegionsId}
          style={{ width: '100%' }}
          onChange={onChangeEditRegionsId}
        >
          {
            regionsList.map(reg => (
              <Option value={reg.id} key={reg.id}>{reg.name}</Option>
            ))
          }
        </Select>
      </div>
    );
  };

  /***************************End Edit Teams***************************/
  const handlePageChange = value => {
    setActivePage(value.current);
  }

  const renderTeamsInfoContent = () => {
    if (loading) return <LoadingView message="Loading..." />;
    return (
      <div className="site-card-wrapper">
        <Row gutter={16}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
            <Button type="primary" onClick={OpenCreateTeamModal}>
              Add Team
            </Button>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
            <Table
              columns={columns}
              dataSource={teamsList}
              rowKey={record => record.id}
              rowClassName="teamsRow"
              pagination={{ current: activePage }}
              onChange={handlePageChange}
            />
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <PageLayout>
      <div className="TeamsInfo ">
        {renderTeamsInfoContent()}
      </div>
      <Modal
        title="Add Team"
        okText="Add Team"
        visible={isModalVisible}
        onCancel={CloseCreateTeamModal}
        onOk={() => {
          form
            .validateFields()
            .then(values => {
              updateData(values);
            })
            .catch(info => {
              console.log('Validate Failed:', info);
            });
        }}>

        {renderModalContent()}
      </Modal>
      <Modal
        title="Edit Team"
        visible={selectedTeamModal}
        okText="Update"
        onOk={updateSelectedTeams}
        onCancel={cancelSelectedTeamModal}
      >
        {renderSelectedTeamModalContent()}
      </Modal>
    </PageLayout>
  );
};

export default TeamsInfo;
