/***********Event Page API***********/
const getNotStartedEvents = () => ({
  url: `/events?status=NOT_STARTED`,
});
const getStartedEvents = () => ({
  url: `/events?status=STARTED`,
});
const getCompletedEvents = () => ({
  url: `/events?status=COMPLETED`,
});
const addEvents = () => ({
  url: `/events`,
});
const getEventsById = eventID => ({
  url: `/events/${eventID}`,
});
const updateEditEvent = eventID => ({
  url: `/events/${eventID}`,
});

const eventStart = eventID => ({
  url: `/events/${eventID}/START`,
});
const eventComplete = eventID => ({
  url: `/events/${eventID}/COMPLETE`,
});

/***********Team Tab API***********/
const getAllEventTeams = eventID => ({
  url: `/events/${eventID}/teams`,
});
const addAllEventTeams = eventID => ({
  url: `/events/${eventID}/teams`,
});
const getEventTeamById = (eventID, teamID) => ({
  url: `/events/${eventID}/teams/${teamID}`,
});
const updateEditEventTeam = (eventID, teamID) => ({
  url: `/events/${eventID}/teams/${teamID}`,
});
const getAllAvailableTeamsForEvent = eventID => ({
  url: `/events/${eventID}/teams-available-for-event`,
});

/***********Pools Tab API***********/
const getAllEventPools = eventID => ({
  url: `/events/${eventID}/pools`,
});
const addAllEventPools = eventID => ({
  url: `/events/${eventID}/pools`,
});
const getEventPoolsById = (eventID, poolID) => ({
  url: `/events/${eventID}/pools/${poolID}`,
});
const updateEditEventPool = (eventID, poolID) => ({
  url: `/events/${eventID}/pools/${poolID}`,
});
const getAllAvailableEventTeamsForPool = eventID => ({
  url: `/events/${eventID}/teams-available-for-pool?isFinalPool=false`,
});

/***********Users Tab API***********/
const getAllEventUsers = eventID => ({
  url: `/events/${eventID}/users`,
});
const addAllEventUsers = eventID => ({
  url: `/events/${eventID}/users`,
});
const getAllRole = () => ({
  url: `/user/roles`,
});
const updateEditEventUser = (eventID, userID) => ({
  url: `/events/${eventID}/users/${userID}`,
});

const getAllAvailableEventUsers = eventID => ({
  url: `/events/${eventID}/users-available-for-event`,
});


/***********Home page Event  API***********/
const getActiveUserEvents = () => ({
  url: `/user/events?isActive=true`,
});
const getInActiveUserEvents = () => ({
  url: `/user/events?isActive=false`,
});
const getEventTeams = eventID => ({
  url: `/user/events/${eventID}/teams`,
});
const getEventTeamsPoolDetails = eventID => ({
  url: `/events/${eventID}/teams-pool-details`,
});

const getEventTeamsDisciplines = (eventID, poolId, teamID) => ({
  url: `/user/events/${eventID}/pools/${poolId}/teams/${teamID}/disciplines`,
});
/***********judge API***********/
const getEventTeamsDisciplinesComment = (eventID, poolId, teamID, disciplineID) => ({
  url: `/user/events/${eventID}/pools/${poolId}/teams/${teamID}/disciplines/${disciplineID}/comments`,
});
const getEventTeamsDisciplinesCommentParent = (eventID, poolId, teamID, disciplineID) => ({
  url: `/user/events/${eventID}/pools/${poolId}/teams/${teamID}/disciplines/${disciplineID}/comments`,
});
const getEventTeamsDisciplinesCommentStats = (eventID, poolId, teamID, disciplineID) => ({
  url: `/user/events/${eventID}/pools/${poolId}/teams/${teamID}/disciplines/${disciplineID}/stats`,
});
const getEventTeamsDisciplinesCommentStatsParent = (eventID, poolId, teamID, disciplineID, performanceText) => ({
  url: `/user/events/${eventID}/pools/${poolId}/teams/${teamID}/disciplines/${disciplineID}/stats?performanceName=${performanceText}`,
});
const saveEventTeamsDisciplinesComment = (eventID, poolId, teamID, disciplineID) => ({
  url: `/user/events/${eventID}/pools/${poolId}/teams/${teamID}/disciplines/${disciplineID}/stats?action=SAVE`,
});
const addEventTeamsDisciplinesComment = (eventID, poolId, teamID, disciplineID) => ({
  url: `/user/events/${eventID}/pools/${poolId}/teams/${teamID}/disciplines/${disciplineID}/stats?action=SUBMIT`,
});
const addEventTeamsDisciplinesCommentParent = (eventID, poolId, teamID, disciplineID, performanceText) => ({
  url: `/user/events/${eventID}/pools/${poolId}/teams/${teamID}/disciplines/${disciplineID}/stats?performanceName=${performanceText}`,
});

const getNotifications = (userID) => ({
  url: `/user/events/${userID}/notifications?status=JUDGE_REVIEW_REQUIRED`,
})

/***********moderator API***********/
const moderator_Getjudges = (eventID, poolId, teamID, disciplineID) => ({
  url: `/user/events/${eventID}/pools/${poolId}/teams/${teamID}/disciplines/${disciplineID}/judges`,
});
const moderator_GetCommentsStats = (eventID, poolId, teamID, disciplineID, judgeID) => ({
  url: `/user/events/${eventID}/pools/${poolId}/teams/${teamID}/disciplines/${disciplineID}/judges/${judgeID}/stats`,
});
const moderator_GetCommentsStatsParent = (eventID, poolId, teamID, disciplineID, judgeID, performanceText) => ({
  url: `/user/events/${eventID}/pools/${poolId}/teams/${teamID}/disciplines/${disciplineID}/judges/${judgeID}/stats?performanceName=${performanceText}`,
});

const moderator_AddCommentsStats = (eventID, poolId, teamID, disciplineID, userID) => ({
  url: `/user/events/${eventID}/pools/${poolId}/teams/${teamID}/disciplines/${disciplineID}/judges/${userID}/stats`,
});

/***********head-judge API***********/

const headJudges_getEventTeams = eventID => ({
  url: `/user/events/${eventID}/teams-pool-details/head-judge-review-required`,
});
const headJudges_GetTeamsDisciplines = (eventID, poolId, teamID) => ({
  url: `/user/events/${eventID}/pools/${poolId}/teams/${teamID}/disciplines/head-judge-review-required`,
});
const headJudges_Getjudges = (eventID, poolId, teamID, disciplineID) => ({
  url: `/user/events/${eventID}/pools/${poolId}/teams/${teamID}/disciplines/${disciplineID}/judges/head-judge-review-required`,
});

const headJudges_GetCommentsStats = (eventID, poolId, teamID, disciplineID, judgeID) => ({
  url: `/user/events/${eventID}/pools/${poolId}/teams/${teamID}/disciplines/${disciplineID}/judges/${judgeID}/stats/head-judge`,
});
const headJudges_GetCommentsStatsParent = (eventID, poolId, teamID, disciplineID, judgeID, performanceText) => ({
  url: `/user/events/${eventID}/pools/${poolId}/teams/${teamID}/disciplines/${disciplineID}/judges/${judgeID}/stats/head-judge?performanceName=${performanceText}`,
});

const headJudges_AddCommentsStats = (eventID, poolId, teamID, disciplineID, userID) => ({
  url: `/user/events/${eventID}/pools/${poolId}/teams/${teamID}/disciplines/${disciplineID}/judges/${userID}/stats/head-judge`,
});

/***********Timekepers API***********/
const timekepersInstruments = () => ({
  url: `/instruments`,
});
const get_Timekepers_Instruments = (eventID, poolID, teamID) => ({
  url: `/user/events/${eventID}/pools/${poolID}/teams/${teamID}/penalty`,
});
const AddTimekepersInstruments = (eventID, poolID, teamID) => ({
  url: `/user/events/${eventID}/pools/${poolID}/teams/${teamID}/penalty`,
});
const get_Timekepers_Details = (eventID, poolID, teamID) => ({
  url: `/user/events/${eventID}/pools/${poolID}/teams/${teamID}/timekeepers`,
});

/***********Scrutineer API***********/
const get_Timekepers_Scrutineer = (eventID, poolID, teamID, userID) => ({
  url: `/user/events/${eventID}/pools/${poolID}/teams/${teamID}/timekeeper/${userID}/penalty`,
});

const get_Timekepers_ScrutineerPenalty = (eventID, poolID, teamID) => ({
  url: `/user/events/${eventID}/pools/${poolID}/teams/${teamID}/scrutineer-penalty`,
});

const add_Timekepers_ScrutineerPenalty = (eventID, poolID, teamID) => ({
  url: `/user/events/${eventID}/pools/${poolID}/teams/${teamID}/scrutineer-penalty`,
});

const get_AverageTimeTakenPerformances = (eventID, poolID, teamID) => ({
  url: `/user/events/${eventID}/pools/${poolID}/teams/${teamID}/average-time-taken-for-performances`,
});

/***********judges-scores API***********/
const getJudgesTeamsScore = (eventID, poolID) => ({
  url: `/events/${eventID}/pools/${poolID}/teams-score`,
});
const getJudgesTeamsDisciplinesScore = (eventID, poolID, teamID) => ({
  url: `/events/${eventID}/pools/${poolID}/teams/${teamID}/disciplines-score`,
});
const getJudgesScores = (eventID, poolID, teamID, disciplineID) => ({
  url: `/events/${eventID}/pools/${poolID}/teams/${teamID}/disciplines/${disciplineID}/judges-scores`,
});
const getJudgesScoresChild = (eventID, poolID, teamID, disciplineID, performanceText) => ({
  url: `/events/${eventID}/pools/${poolID}/teams/${teamID}/disciplines/${disciplineID}/judges-scores?performanceName=${performanceText}`,
});
const getCalculateTeamScores = (eventID, poolID, teamID) => ({
  url: `/events/${eventID}/pools/${poolID}/teams/${teamID}/calculate-score`,
});
const getCalculateDisciplineScores = (eventID, poolID, teamID, disciplineID) => ({
  url: `/events/${eventID}/pools/${poolID}/teams/${teamID}/disciplines/${disciplineID}/calculate-score`,
});
const getCalculateDisciplineScoresChild = (eventID, poolID, teamID, disciplineID, performanceText) => ({
  url: `/events/${eventID}/pools/${poolID}/teams/${teamID}/disciplines/${disciplineID}/calculate-score?performanceName=${performanceText}`,
});

const getUserDetails = () => ({
  url: `/me`,
});

const getFinalTeams = (eventID, poolID) => ({
  url: `/events/${eventID}/pools/${poolID}/final-teams`,
});

const movefinalizeTeams = (eventID, poolID) => ({
  url: `/events/${eventID}/pools/${poolID}/push-final-teams`,
});

const publishFinalizeTeams = (eventID, poolID) => ({
  url: `/events/${eventID}/pools/${poolID}/publish-final-teams`,
});

const singlePoolMoveTeam = (eventID, poolID, teamID) => ({
  url: `/events/${eventID}/pools/${poolID}/teams/${teamID}/push-team-to-final`,
});


const getPenalties = (eventID, poolID, teamID) => ({
  url: `/events/${eventID}/pools/${poolID}/teams/${teamID}/penalties`,
});

const getUserCommentsList = (eventID, poolID, teamID, disciplineID) => ({
  url: `/user/events/${eventID}/pools/${poolID}/teams/${teamID}/disciplines/${disciplineID}/comment-texts`,
});

const getUserCommentsListParent = (eventID, poolID, teamID, disciplineID, performanceText) => ({
  url: `/user/events/${eventID}/pools/${poolID}/teams/${teamID}/disciplines/${disciplineID}/comment-texts?performanceName=${performanceText}`,
});

const getUserCommentsListByJudge = (eventID, poolID, teamID, disciplineID, userID) => ({
  url: `/user/events/${eventID}/pools/${poolID}/teams/${teamID}/disciplines/${disciplineID}/judges/${userID}/comment-texts`,
});

const getUserCommentsListByJudgeParent = (eventID, poolID, teamID, disciplineID, userID, performanceText) => ({
  url: `/user/events/${eventID}/pools/${poolID}/teams/${teamID}/disciplines/${disciplineID}/judges/${userID}/comment-texts?performanceName=${performanceText}`,
});

const getReportDownload = (eventID, poolID, teamID) => ({
  url: `/events/${eventID}/pools/${poolID}/teams/${teamID}/report`,
});

const getTopTeamsInInitialPoolsReport = (eventID) => ({
  url: `/events/${eventID}/top-teams-initial-pools`,
});

const getTopTeamsInEachDisciplineReport = (eventID) => ({
  url: `/events/${eventID}/disciplines-top-teams`,
});

const getTeamPlacingsInFinalPoolReport = (eventID) => ({
  url: `/events/${eventID}/team-placings-in-final-pool`,
});

const getMasterReport = (eventID) => ({
  url: `/events/${eventID}/master-sheet-report`,
});

const updateEventJudge = (eventID, userID) => ({
  url: `/events/${eventID}/users-replace/${userID}`,
});
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  eventStart,
  eventComplete,
  getNotStartedEvents,
  getStartedEvents,
  getCompletedEvents,
  getEventsById,
  addEvents,
  updateEditEvent,
  getAllEventTeams,
  addAllEventTeams,
  getEventTeamById,
  updateEditEventTeam,
  getAllAvailableTeamsForEvent,
  getAllEventPools,
  addAllEventPools,
  getEventPoolsById,
  updateEditEventPool,
  getAllAvailableEventTeamsForPool,
  getAllEventUsers,
  addAllEventUsers,
  updateEditEventUser,
  getAllAvailableEventUsers,
  getAllRole,
  getActiveUserEvents,
  getInActiveUserEvents,
  getEventTeams,
  getEventTeamsDisciplines,
  getEventTeamsDisciplinesComment,
  getEventTeamsDisciplinesCommentParent,
  addEventTeamsDisciplinesComment,
  saveEventTeamsDisciplinesComment,
  addEventTeamsDisciplinesCommentParent,
  getEventTeamsDisciplinesCommentStats,
  getEventTeamsDisciplinesCommentStatsParent,
  moderator_Getjudges,
  moderator_GetCommentsStats,
  moderator_GetCommentsStatsParent,
  moderator_AddCommentsStats,
  headJudges_getEventTeams,
  headJudges_GetTeamsDisciplines,
  headJudges_Getjudges,
  headJudges_GetCommentsStatsParent,
  headJudges_GetCommentsStats,
  headJudges_AddCommentsStats,
  timekepersInstruments,
  get_Timekepers_Instruments,
  AddTimekepersInstruments,
  getEventTeamsPoolDetails,
  getJudgesTeamsScore,
  getJudgesTeamsDisciplinesScore,
  getJudgesScores,
  getJudgesScoresChild,
  getCalculateTeamScores,
  getCalculateDisciplineScores,
  getCalculateDisciplineScoresChild,
  getFinalTeams,
  movefinalizeTeams,
  publishFinalizeTeams,
  singlePoolMoveTeam,
  getUserDetails,
  getPenalties,
  getUserCommentsList,
  getUserCommentsListParent,
  getUserCommentsListByJudge,
  getUserCommentsListByJudgeParent,
  getReportDownload,
  getTopTeamsInInitialPoolsReport,
  getTopTeamsInEachDisciplineReport,
  getTeamPlacingsInFinalPoolReport,
  getMasterReport,
  get_Timekepers_Details,
  get_Timekepers_Scrutineer,
  get_Timekepers_ScrutineerPenalty,
  add_Timekepers_ScrutineerPenalty,
  get_AverageTimeTakenPerformances,
  getNotifications,
  updateEventJudge,
};
