
import PageLayout from "../../layout"
import { adminRole } from '../../../_services/auth';
import EventJudges from './Event_Judges'
import EventModerators from './Event_Moderators'
import EventHeadJudges from './Event_HeadJudges'
import EventTimekepers from './Event_Timekepers'
import EventTimekeperScrutineer from './Event_Scrutineer'

const EventRoleBasedPage = props => {
  return (
    <PageLayout>
      {adminRole() === 'Judge' && (
        <EventJudges />
      )}
      {adminRole() === 'Moderator' && (
        <EventModerators />
      )}
      {adminRole() === 'Head_Judge' && (
        <EventHeadJudges />
      )}
      {adminRole() === 'Timekeeper' && (
        <EventTimekepers />
      )}
      {adminRole() === 'Scrutineer' && (
        <EventTimekeperScrutineer />
      )}

    </PageLayout>
  )
}

export default EventRoleBasedPage

// https://codesandbox.io/s/react-table-array-1d0u9?fontsize=14&hidenavigation=1&theme=dark&file=/src/App.js:880-1714
