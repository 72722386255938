import React from 'react';
import { Row, Col, Input, Image } from 'antd';
import { userService } from '../_services';
import toastr from 'toastr';
import './LoginPage.scss';
import Logo from '../assets/images/2021_Te Matatini_50_Year_Tohu-02.png'

import {
    SyncOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';

class LoginPage extends React.Component {
    constructor(props) {
        super(props);

        userService.logout();

        this.state = {
            email: '',
            password: '',
            submitted: false,
            loading: false,
            error: ''
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({ submitted: true });
        const { email, password } = this.state;

        // stop here if form is invalid
        if (!(email && password)) {
            return;
        }

        this.setState({ loading: true });
        userService.login(email, password)
            .then(
                user => {
                    const { from } = this.props.location.state || { from: { pathname: "/" } };
                    this.props.history.push(from);
                },
                error => {
                    this.setState({ error: error, loading: false })
                    toastr.error(error);
                }

            );
    }

    render() {
        const { email, password, submitted, loading, error } = this.state;
        return (
            <div className="login-page">
                <div className="loginPageContent">
                    <Row>
                        <Col span={24}>
                            <Image
                                width={200}
                                src={Logo}
                                preview={false}
                            />
                            <br /><br />
                            <form name="form" action="">
                                <div className={'form-group' + (submitted && !email ? ' has-error' : '')}>
                                    <Input placeholder="Email" className="form-control" name="email" value={email} onChange={this.handleChange} />
                                    {submitted && !email &&
                                        <div className="help-block">Email is required</div>
                                    }
                                </div>
                                <div className={'form-group' + (submitted && !password ? ' has-error' : '')}>
                                    <Input.Password placeholder="Password" className="form-control" name="password" value={password} onChange={this.handleChange} />
                                    {submitted && !password &&
                                        <div className="help-block">Password is required</div>
                                    }
                                </div>
                                <div className="form-group submitRow">
                                    <div className="loadingSpin">
                                        {loading &&
                                            <SyncOutlined spin />
                                        }
                                    </div>
                                    <input type="submit" disabled={loading} className="loginBtn" onClick={this.handleSubmit} value="Login" />

                                </div>
                                {error &&
                                    (
                                        <React.Fragment>
                                            <div className='redMandatory'>Please enter correct email & password</div>
                                        </React.Fragment>
                                    )
                                }
                            </form>

                            <div>
                                <Link
                                    className="login-form-forgot"
                                    to="/forgetPassword"
                                >
                                    Forgot Password
                                </Link>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

export { LoginPage };
