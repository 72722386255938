const getComments = () => ({
  url: `/comments`,
});
const getCommentsById = commentsID => ({
  url: `/comments/${commentsID}`,
});
const addComments = () => ({
  url: `/comments`,
});
const updateEditComments = commentsID => ({
  url: `/comments/${commentsID}`,
});
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getComments,
  getCommentsById,
  addComments,
  updateEditComments,
};
