import React from "react"
import { Row, Col, } from 'antd';
import { Layout } from 'antd';
import MainMenu from '../components/navigation/MainMenu';
import Logo from '../assets/images/2021_Te Matatini_Logo_Small_Scale-Black.png'

import toastr from 'toastr';

const { Header, Content, Footer } = Layout;

toastr.options = {
  closeButton: true,
  debug: false,
  newestOnTop: false,
  progressBar: false,
  positionClass: 'toast-bottom-right',
  preventDuplicates: false,
  onclick: null,
  showDuration: '300',
  hideDuration: '1000',
  timeOut: '5000',
  extendedTimeOut: '1000',
  showEasing: 'swing',
  hideEasing: 'linear',
  showMethod: 'fadeIn',
  hideMethod: 'fadeOut',
};

export default function PageLayout({ children }) {
  return (
    <div>
      <Layout className="layout">
        <Header>
          <Row>
            {
              //     <Col span={8}>
              //     <div className="logo"><Image
              //     width={80}
              //     src={Logo}
              //     preview={false}
              // /></div>
              //     </Col>
            }
            <Col span={24}><MainMenu /></Col>
          </Row>

        </Header>
        <Content style={{ padding: '0 50px' }}>
          <div className="site-layout-content">
            <div>
              {children}
            </div>
          </div>
        </Content>
        {
          <Footer>
            <div className="footerLogo">
              <img alt="example" src={Logo} />
            </div>
          </Footer>
        }
      </Layout>

    </div>
  )
}



