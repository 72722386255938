import React, { useState, useEffect } from 'react';
import { useParams, useHistory, useLocation } from "react-router-dom";
import { LoadingView } from '../../common';

import toastr from 'toastr';
import { EventApi } from '../../../api';
import { postData, getData } from '../../../_services/apiCommonService';
import { eventId } from '../../../_services/auth';

import { Row, Col, Collapse, Tabs, Table, Input, Popconfirm, Button, Breadcrumb, } from 'antd';
import '../../Judges/Event/EventTeams.scss';

const judgeReview = 'JUDGE_REVIEW_REQUIRED';
const headJudgeReview = 'HEAD_JUDGE_REVIEW_REQUIRED';
const ApprovedReview = 'APPROVED';

const EventModerators = props => {
  const location = useLocation();
  if (location.state === undefined) {
    window.location.href = '/';
  }

  let eventName = location.state.eventName
  let teamName = location.state.teamName
  let poolId = location.state.poolId

  const { tId } = useParams();
  const history = useHistory();
  const { Panel } = Collapse;
  const { TabPane } = Tabs;
  const { TextArea } = Input;

  const [loading, setloading] = useState(true);
  const [eventTeamDisciplinesList, setEventTeamDisciplinesList] = useState([]);
  const [disciplineId, setDisciplineId] = useState(null);
  const [judgeList, setJudgeList] = useState([]);
  const [judgeId, setJudgeId] = useState(null);

  const [commentsList, setCommentsList] = useState([]);
  const [judgeComment, setJudgeComment] = useState('');
  const [moderatorComment, setModeratorComment] = useState('');
  const [disciplineScore, setDisciplineScore] = useState(null);
  const [btnActionText, setBtnActionText] = useState('');
  const [usersCommentsList, setUsersCommentsList] = useState([]);
  const [submittedStatus, setSubmittedStatus] = useState('');
  const [statsDataStatus, setStatsDataStatus] = useState(null);
  const [mode, setMode] = useState('top');

  const [performanceText, setPerformanceText] = useState(null);

  const columns = [
    {
      title: 'Comment',
      dataIndex: 'comment',
      width: '85%',
      render: record => {
        return (
          `${record.name}`
        )
      },
    },
    {
      title: 'Score',
      dataIndex: 'score',
      render: record => {
        return (
          `${record}`
        )
      },
    },
  ];

  const loadTeamsDisciplines = () => {
    setloading(true);
    getData(EventApi.getEventTeamsDisciplines(eventId(), poolId, tId).url)
      .then((response) => {
        const data = response.data.disciplines;
        setEventTeamDisciplinesList(
          data.map(et => ({
            key: et.id,
            id: et.id,
            name: et.name,
            children: et.children,
          }))
        );
      })
      .catch((e) => {
        toastr.error(e.data.message);
      })
      .finally(() => {
        setloading(false)
      });
  };

  useEffect(() => {
    if (tId) {
      loadTeamsDisciplines();
      setMode('top')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tId]);

  const onClose = (e) => {
    const x = document.querySelectorAll(".customCollapse .ant-collapse-item-active");
    if (x.length) {
      for (let i = 0; i < x.length; i++) {
        setTimeout(function () {
          const el = document.querySelector(".ant-collapse-item-active");
          el.children[0].click();
        }, 100);
      }
    }
  }

  const getDisciplinesId = async (key) => {
    onClose();
    setDisciplineId(key);
    setStatsDataStatus(null);
    setJudgeComment('');
    setModeratorComment('');
    setSubmittedStatus('');
    if (!key) {
      console.log(key);
      console.log(judgeComment);
    } else {
      getData(EventApi.moderator_Getjudges(eventId(), poolId, tId, key).url)
        .then((response) => {
          const data = response.data.judges;
          if (data !== null) {
            setJudgeList(data);
            const firstDisciplinesId = key;
            const firstJudgeId = data[0].id;
            loadTabsFirstJudge(firstDisciplinesId, firstJudgeId);
          }

        })
    }

  }

  const onClickParentDetails = async (key) => {
    setPerformanceText(key);
    setStatsDataStatus(null);
    setJudgeComment('');
    setModeratorComment('');
    setSubmittedStatus('');
    if (!key) {
      console.log(key);
      console.log(judgeComment);
    } else {
      getData(EventApi.moderator_Getjudges(eventId(), poolId, tId, disciplineId).url)
        .then((response) => {
          const data = response.data.judges;
          if (data !== null) {
            setJudgeList(data);
            const firstDisciplinesId = disciplineId;
            const firstJudgeId = data[0].id;
            loadTabsFirstJudgeParent(firstDisciplinesId, firstJudgeId, key);
          }

        })
    }

  }

  const loadTabsFirstJudge = async (firstDisciplinesId, firstJudgeId) => {
    getData(EventApi.moderator_GetCommentsStats(eventId(), poolId, tId, firstDisciplinesId, firstJudgeId).url)
      .then((response) => {
        const data = response.data.stats;

        if (data === null) {
          setStatsDataStatus('null');
        }
        if (data !== null) {
          setJudgeId(firstJudgeId);
          setSubmittedStatus(data.status);
          setJudgeComment(data.judgeComment);
          setDisciplineScore(data.disciplineScore);
          setCommentsList(data.commentScores);
          setModeratorComment(data.moderatorComment);
        }

      })
    getData(EventApi.getUserCommentsListByJudge(eventId(), poolId, tId, firstDisciplinesId, firstJudgeId).url)
      .then((response) => {
        const data = response.data.commentTexts;
        setUsersCommentsList(data)
      }
      )
  }

  const loadTabsFirstJudgeParent = async (firstDisciplinesId, firstJudgeId, performanceText) => {
    getData(EventApi.moderator_GetCommentsStatsParent(eventId(), poolId, tId, firstDisciplinesId, firstJudgeId, performanceText).url)
      .then((response) => {
        const data = response.data.stats;

        if (data === null) {
          setStatsDataStatus('null');
        }
        if (data !== null) {
          setJudgeId(firstJudgeId);
          setSubmittedStatus(data.status);
          setJudgeComment(data.judgeComment);
          setDisciplineScore(data.disciplineScore);
          setCommentsList(data.commentScores);
          setModeratorComment(data.moderatorComment);
        }

      })
    getData(EventApi.getUserCommentsListByJudgeParent(eventId(), poolId, tId, firstDisciplinesId, firstJudgeId, performanceText).url)
      .then((response) => {
        const data = response.data.commentTexts;
        setUsersCommentsList(data)
      }
      )
  }

  const Tabscallback = async (key) => {
    setJudgeId(key);
    setSubmittedStatus('');
    setStatsDataStatus(null);
    setJudgeComment('');
    setModeratorComment('');
    setDisciplineScore(null);
    setCommentsList([]);
    setUsersCommentsList([]);

    getData(EventApi.moderator_GetCommentsStats(eventId(), poolId, tId, disciplineId, key).url)
      .then((response) => {
        const data = response.data.stats;
        if (data === null) {
          setStatsDataStatus('null');
        }
        if (data !== null) {
          setSubmittedStatus(data.status);
          setJudgeComment(data.judgeComment);
          setDisciplineScore(data.disciplineScore);
          setCommentsList(data.commentScores);
          setModeratorComment(data.moderatorComment)

        }

      })

    getData(EventApi.getUserCommentsListByJudge(eventId(), poolId, tId, disciplineId, key).url)
      .then((response) => {
        const data = response.data.commentTexts;
        setUsersCommentsList(data)
      }
      )
  }

  const TabscallbackParent = async (key) => {
    setJudgeId(key);
    setSubmittedStatus('');
    setStatsDataStatus(null);
    setJudgeComment('');
    setModeratorComment('');
    setDisciplineScore(null);
    setCommentsList([]);
    setUsersCommentsList([]);

    getData(EventApi.moderator_GetCommentsStatsParent(eventId(), poolId, tId, disciplineId, key, performanceText).url)
      .then((response) => {
        const data = response.data.stats;
        if (data === null) {
          setStatsDataStatus('null');
        }
        if (data !== null) {
          setSubmittedStatus(data.status);
          setJudgeComment(data.judgeComment);
          setDisciplineScore(data.disciplineScore);
          setCommentsList(data.commentScores);
          setModeratorComment(data.moderatorComment)

        }

      })

    getData(EventApi.getUserCommentsListByJudgeParent(eventId(), poolId, tId, disciplineId, key, performanceText).url)
      .then((response) => {
        const data = response.data.commentTexts;
        setUsersCommentsList(data)
      }
      )
  }

  const onChangeModeratorComment = (e) => {
    setModeratorComment(e.target.value);
  };

  const getBtnActionInfo = async (BtnActionInfo) => {
    setBtnActionText(BtnActionInfo);
  }

  const reviewModerator = async () => {

    const moderator = {
      action: btnActionText,
      moderatorComment: moderatorComment,
      performanceName: performanceText,
    }
    // setloading(true);
    postData(EventApi.moderator_AddCommentsStats(eventId(), poolId, tId, disciplineId, judgeId).url, moderator)
      .then((response) => {
        toastr.success('Successfully Submitted');
        setSubmittedStatus(response.data.stats.status);
      })
      .catch((error) => {
        toastr.error(
          error.data.message === 'You cannot update the discipline at this state'
            ? 'Already Submitted' : `${error.data.message}`);
      })
      .finally(() => {
        setloading(false);
        // loadTeamsDisciplines();
        genExtra();
      });
  };

  const updateModerator = async () => {
    if (btnActionText === 'JUDGE_REVIEW_REQUIRED') {

      if (moderatorComment === undefined || moderatorComment === '') {
        toastr.error('Please enter Moderator comment');
      } else {
        reviewModerator();
      }

    } else if (btnActionText === 'HEAD_JUDGE_REVIEW_REQUIRED') {
      if (moderatorComment === undefined || moderatorComment === '') {
        toastr.error('Please enter Moderator comment');
      } else {
        reviewModerator();
      }
    } else {
      reviewModerator();
    }
  }



  const genExtra = () => {
    if (submittedStatus === 'APPROVED') {
      return (
        <span>Score Approved</span>
      )
    } else if (submittedStatus === 'HEAD_JUDGE_REVIEW_REQUIRED') {
      return (
        <span>Head Judge Review</span>
      )
    }
    else if (submittedStatus === 'JUDGE_RESUBMITTED') {
      return (
        <span>Moderator Review</span>
      )
    }
    else if (submittedStatus === 'JUDGE_REVIEW_REQUIRED') {
      return (
        <span>Sent back to Judge</span>
      )
    }
    else if (submittedStatus === 'JUDGE_SUBMITTED') {
      return (
        <span>Moderator Review</span>
      )
    }
    else {
      return (
        <span>Score Pending</span>
      )
    }
  };

  const renderEventsInfoContent = () => {
    if (loading) return <LoadingView message="Loading..." />;
    return (
      <div className="teamDisciplines moderators">
        <Collapse accordion onChange={getDisciplinesId}>

          {(eventTeamDisciplinesList || []).map(disc => (
            <Panel header={disc.name} key={disc.id}>

              {
                disc.children === null ? (
                  <div>
                    <Row>
                      <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
                        <Tabs defaultActiveKey="1" tabPosition={mode} onChange={Tabscallback}>
                          {(judgeList || []).map(judge => (
                            <TabPane tab={judge.name} key={judge.id}>
                              {statsDataStatus !== 'null' ? (
                                <React.Fragment>
                                  <span className='submittedStatus'>
                                    {genExtra()}
                                  </span>
                                  <div className="TabsRow">
                                    <div className="TabsCol customStyle">
                                      <Table dataSource={commentsList} columns={columns} pagination={false} />
                                    </div>
                                    <div className="TabsCol customStyle">
                                      <div className="scoreCircle">{disciplineScore}</div>
                                    </div>
                                  </div>
                                  <p><strong>Conflict of Interest : </strong> {judge.isConflictofInterest ? 'Yes' : 'No'}</p>
                                  <div
                                    className={(
                                      submittedStatus === 'APPROVED' ||
                                      submittedStatus === 'HEAD_JUDGE_REVIEW_REQUIRED' ||
                                      submittedStatus === 'JUDGE_REVIEW_REQUIRED'
                                    ) ? 'itemNotDisplay' : 'itemDisplay'}
                                  >
                                    <div className="TabsRow">
                                      {
                                        // <div className="TabsCol">
                                        //   <p><strong>Judge's Comment</strong></p>
                                        //   <TextArea
                                        //     rows={4}
                                        //     value={judgeComment}
                                        //     disabled
                                        //   />
                                        // </div>
                                      }
                                      

                                      <div className="TabsCol">
                                        <div className="moderatorsComment">
                                          <p><strong>Moderator's Comment</strong></p>
                                          <TextArea
                                            rows={4}
                                            placeholder="Type your comment here"
                                            value={moderatorComment}
                                            onChange={onChangeModeratorComment}
                                            disabled={(
                                              submittedStatus === 'APPROVED' ||
                                              submittedStatus === 'HEAD_JUDGE_REVIEW_REQUIRED' ||
                                              submittedStatus === 'JUDGE_REVIEW_REQUIRED'
                                            ) ? true : false}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="commentsListBox">
                                    {usersCommentsList.length > 0 && (<p><strong>Comments</strong></p>)}
                                    <ul>
                                      {
                                        (usersCommentsList || []).map(item => (
                                          <li>
                                            {item.roleName === 'JUDGE' && (<strong>Judge</strong>)}
                                            {item.roleName === 'MODERATOR' && (<strong>Moderator</strong>)}
                                            {item.roleName === 'HEAD_JUDGE' && (<strong>Head judge</strong>)}
                                            <br />
                                            {item.comment}
                                          </li>
                                        ))
                                      }
                                    </ul>
                                  </div>

                                </React.Fragment>
                              ) : (
                                <div>
                                  <h3>Pending...</h3>
                                </div>
                              )}

                            </TabPane>
                          ))}
                        </Tabs>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>

                        {statsDataStatus !== 'null' ? (
                          <React.Fragment>
                            <div className="moderatorsFooter">
                              <Popconfirm
                                title="Are you sure?"
                                onConfirm={() => updateModerator()}
                              >
                                <Button
                                  type="primary"
                                  onClick={() => getBtnActionInfo(judgeReview)}
                                  disabled={(
                                    submittedStatus === 'APPROVED' ||
                                    submittedStatus === 'HEAD_JUDGE_REVIEW_REQUIRED' ||
                                    submittedStatus === 'JUDGE_REVIEW_REQUIRED'
                                  ) ? true : false}
                                >
                                  Review by Judge
                                </Button>
                              </Popconfirm>
                              <Popconfirm
                                title="Are you sure?"
                                onConfirm={() => updateModerator()}
                              >
                                <Button
                                  type="primary"
                                  onClick={() => getBtnActionInfo(headJudgeReview)}
                                  disabled={(
                                    submittedStatus === 'APPROVED' ||
                                    submittedStatus === 'HEAD_JUDGE_REVIEW_REQUIRED' ||
                                    submittedStatus === 'JUDGE_REVIEW_REQUIRED'
                                  ) ? true : false}
                                >
                                  Review by Head Judge
                                </Button>
                              </Popconfirm>
                              <Popconfirm
                                title="Are you sure?"
                                onConfirm={() => updateModerator()}
                              >
                                <Button
                                  type="primary"
                                  onClick={() => getBtnActionInfo(ApprovedReview)}
                                  disabled={(
                                    submittedStatus === 'APPROVED' ||
                                    submittedStatus === 'HEAD_JUDGE_REVIEW_REQUIRED' ||
                                    submittedStatus === 'JUDGE_REVIEW_REQUIRED'
                                  ) ? true : false}
                                >
                                  Proceed
                                </Button>
                              </Popconfirm>
                            </div>
                          </React.Fragment>
                        ) : null}
                      </Col>
                    </Row>
                  </div>
                ) : (
                  <div className="customCollapse">
                    <Collapse accordion onChange={onClickParentDetails}>
                      {(disc.children || []).map(child => (
                        <Panel
                          header={child.name}
                          key={child.name}>
                          <div className="parentKeyBefore">
                            <div>
                              <Row>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
                                  <Tabs defaultActiveKey="1" tabPosition={mode} onChange={TabscallbackParent}>
                                    {(judgeList || []).map(judge => (
                                      <TabPane tab={judge.name} key={judge.id}>
                                        {statsDataStatus !== 'null' ? (
                                          <React.Fragment>
                                            <span className='submittedStatus'>
                                              {genExtra()}
                                            </span>
                                            <div className="TabsRow">
                                              <div className="TabsCol customStyle">
                                                <Table dataSource={commentsList} columns={columns} pagination={false} />
                                              </div>
                                              <div className="TabsCol customStyle">
                                                <div className="scoreCircle">{disciplineScore}</div>
                                              </div>
                                            </div>
                                            <div
                                              className={(
                                                submittedStatus === 'APPROVED' ||
                                                submittedStatus === 'HEAD_JUDGE_REVIEW_REQUIRED' ||
                                                submittedStatus === 'JUDGE_REVIEW_REQUIRED'
                                              ) ? 'itemNotDisplay' : 'itemDisplay'}
                                            >
                                              <div className="TabsRow">
                                                {
                                                  // <div className="TabsCol">
                                                  //   <p><strong>Judge's Comment</strong></p>
                                                  //   <TextArea
                                                  //     rows={4}
                                                  //     value={judgeComment}
                                                  //     disabled
                                                  //   />
                                                  // </div>
                                                }
                                                <div className="TabsCol">
                                                  <div className="moderatorsComment">
                                                    <p><strong>Moderator's Comment</strong></p>
                                                    <TextArea
                                                      rows={4}
                                                      placeholder="Type your comment here"
                                                      value={moderatorComment}
                                                      onChange={onChangeModeratorComment}
                                                      disabled={(
                                                        submittedStatus === 'APPROVED' ||
                                                        submittedStatus === 'HEAD_JUDGE_REVIEW_REQUIRED' ||
                                                        submittedStatus === 'JUDGE_REVIEW_REQUIRED'
                                                      ) ? true : false}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="commentsListBox">
                                              {usersCommentsList.length > 0 && (<p><strong>Comments</strong></p>)}
                                              <ul>
                                                {
                                                  (usersCommentsList || []).map(item => (
                                                    <li>
                                                      {item.roleName === 'JUDGE' && (<strong>Judge</strong>)}
                                                      {item.roleName === 'MODERATOR' && (<strong>Moderator</strong>)}
                                                      {item.roleName === 'HEAD_JUDGE' && (<strong>Head judge</strong>)}
                                                      <br />
                                                      {item.comment}
                                                    </li>
                                                  ))
                                                }
                                              </ul>
                                            </div>

                                          </React.Fragment>
                                        ) : (
                                          <div>
                                            <h3>Pending...</h3>
                                          </div>
                                        )}

                                      </TabPane>
                                    ))}
                                  </Tabs>
                                </Col>
                              </Row>
                              <Row>
                                <Col span={24}>

                                  {statsDataStatus !== 'null' ? (
                                    <React.Fragment>
                                      <div className="moderatorsFooter">
                                        <Popconfirm
                                          title="Are you sure?"
                                          onConfirm={() => updateModerator()}
                                        >
                                          <Button
                                            type="primary"
                                            onClick={() => getBtnActionInfo(judgeReview)}
                                            disabled={(
                                              submittedStatus === 'APPROVED' ||
                                              submittedStatus === 'HEAD_JUDGE_REVIEW_REQUIRED' ||
                                              submittedStatus === 'JUDGE_REVIEW_REQUIRED'
                                            ) ? true : false}
                                          >
                                            Review by Judge
                                          </Button>
                                        </Popconfirm>
                                        <Popconfirm
                                          title="Are you sure?"
                                          onConfirm={() => updateModerator()}
                                        >
                                          <Button
                                            type="primary"
                                            onClick={() => getBtnActionInfo(headJudgeReview)}
                                            disabled={(
                                              submittedStatus === 'APPROVED' ||
                                              submittedStatus === 'HEAD_JUDGE_REVIEW_REQUIRED' ||
                                              submittedStatus === 'JUDGE_REVIEW_REQUIRED'
                                            ) ? true : false}
                                          >
                                            Review by Head Judge
                                          </Button>
                                        </Popconfirm>
                                        <Popconfirm
                                          title="Are you sure?"
                                          onConfirm={() => updateModerator()}
                                        >
                                          <Button
                                            type="primary"
                                            onClick={() => getBtnActionInfo(ApprovedReview)}
                                            disabled={(
                                              submittedStatus === 'APPROVED' ||
                                              submittedStatus === 'HEAD_JUDGE_REVIEW_REQUIRED' ||
                                              submittedStatus === 'JUDGE_REVIEW_REQUIRED'
                                            ) ? true : false}
                                          >
                                            Proceed
                                          </Button>
                                        </Popconfirm>
                                      </div>
                                    </React.Fragment>
                                  ) : null}
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </Panel>
                      )
                      )}

                    </Collapse>
                  </div>
                )
              }

            </Panel>
          ))}

        </Collapse>

      </div>
    );
  };

  const renderBreadcrumb = () => (
    <Breadcrumb>
      <Breadcrumb.Item href="/">
        {eventName}
      </Breadcrumb.Item>
      <Breadcrumb.Item onClick={() => history.goBack()}>
        <span>{teamName}</span>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        <span>Disciplines</span>
      </Breadcrumb.Item>
    </Breadcrumb>
  );

  return (
    <React.Fragment>
      {renderBreadcrumb()}
      <div className="EventTeams">
        {renderEventsInfoContent()}
      </div>
    </React.Fragment>
  )
}

export default EventModerators
