
import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { PrivateRoute } from './_components';
import { LoginPage } from './LoginPage';
import resetPassword from './LoginPage/resetPassword';
import forgetPassword from './LoginPage/forgetPassword';
import HomePage from "./components/HomePage";
import users from "./components/users";
import teams from './components/teams';
import discipline from './components/discipline';
import comments from './components/comments'
import events from './components/events';
import EventsDetails from "./components/events/EventsDetails";

import EventTeamRoleBasedPage from "./components/Judges/Team";
import EventRoleBasedPage from "./components/Judges/Event";
import MyActions from './components/Judges/MyActions/MyActions';
import Action from './components/Judges/MyActions/Action';

import './styles/main.scss';
import 'antd/dist/antd.css';

function App() {

  return (
    <div className="page-body">
      <Router>
        <div>
          <PrivateRoute exact path="/" component={HomePage} />
          <PrivateRoute exact path="/comments" component={comments} />
          <Route path="/login" component={LoginPage} />
          <Route exact path="/forgetPassword" component={forgetPassword} />

          <PrivateRoute exact path="/resetPassword" component={resetPassword} />
          <PrivateRoute exact path="/users" component={users} />
          <PrivateRoute exact path="/teams" component={teams} />
          <PrivateRoute exact path="/discipline" component={discipline} />

          <PrivateRoute exact path="/events" component={events} />
          <PrivateRoute exact path="/events/:eventId" component={EventsDetails} />

          <PrivateRoute path="/user/events/:eId" component={EventTeamRoleBasedPage} />
          <PrivateRoute exact path="/event/team/:tId" component={EventRoleBasedPage} />
          <PrivateRoute exact path="/my-actions" component={MyActions}/>
          <PrivateRoute exact path="/my-actions/:id" component={Action} />
        </div>
      </Router>

    </div>
  );
}

export default App;
