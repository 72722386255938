import React from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';

const LoadingView = ({ message, wrapperStyle }) => (
  <div
    style={{
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      ...wrapperStyle,
    }}
    className="h-100"
  >
    <Spin size="large" />

    <h2 className="mt-3">{message}</h2>
  </div>
);

LoadingView.propTypes = {
  message: PropTypes.string,
  wrapperStyle: PropTypes.object,
};

LoadingView.defaultProps = {
  message: 'Loading...',
  wrapperStyle: {},
};

export default LoadingView;
