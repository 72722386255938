import React, { useState, useEffect } from 'react';
import { LoadingView } from '../common';
import PageLayout from "../layout"
import { admin_Status } from '../../_services/auth';
import toastr from 'toastr';
import { DisciplinesApi } from '../../api';
import { postData, getData, putData } from '../../_services/apiCommonService';

import { Row, Col, Modal, Button, Input, Form, Checkbox, Table, Radio, InputNumber, Select } from 'antd';
import './Discipline.scss';

const Discipline = () => {
  if (admin_Status() === 'false') {
    window.location.href = '/login';
  }
  const [loading, setloading] = useState(true);
  const [disciplineList, setDisciplineList] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [discipline, setDiscipline] = useState(null);
  const [mandatoryStatus, setMandatoryStatus] = useState(true);
  const [aggregateStatus, setAggregateStatus] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [form] = Form.useForm();
  const [isChildStatus, setIsChildStatus] = useState('false');
  const [eligibleParentsList, setEligibleParentsList] = useState([]);

  const { Option } = Select;

  const columns = [
    {
      title: 'Discipline',
      dataIndex: 'name',
      sorter: (a, b) => a.name.length - b.name.length,
      render: (record, val) => {
        return (
          <span
            className='nameLink'
            onClick={(e) => showEditDisciplinesModal(record, val)}
          >
            {record}
          </span>
        )
      },
    },
    {
      title: 'Display Order',
      dataIndex: 'position',
      sorter: (a, b) => a.position - b.position,
      render: (record) => {
        return (
          <span>{record}</span>
        )
      },
    },

    {
      title: 'Priority',
      dataIndex: 'priority',
      sorter: (a, b) => a.position - b.position,
      render: (record) => {
        return (
          <span>{record}</span>
        )
      },
    },
    {
      title: 'Aggregate',
      dataIndex: 'isAggregate',
      render: (record) => {
        return (
          `${record === 'true' ? 'Yes' : 'No'}`
        )
      },
    },
    {
      title: 'Mandatory',
      dataIndex: 'isMandatory',
      filters: [
        {
          text: 'Yes',
          value: `${true}`,
        },
        {
          text: 'No',
          value: `${false}`,
        },
      ],
      onFilter: (value, record) => record.isMandatory.indexOf(value) === 0,
      render: (record) => {
        return (
          `${record === 'true' ? 'Yes' : 'No'}`
        )
      },
    },
  ];

  const fetchAllDiscipline = () => {
    setloading(true)
    getData(DisciplinesApi.getDisciplines().url)
      .then((response) => {
        const data = response.data.disciplines;
        setDisciplineList(
          data.map(discipline => ({
            key: discipline.id,
            id: discipline.id,
            name: discipline.name,
            position: discipline.position,
            priority: discipline.priority,
            isAggregate: `${discipline.isAggregate}`,
            isMandatory: `${discipline.isMandatory}`,
            parent: discipline.parent,
            beforeName: discipline.beforeName,
            duringName: discipline.duringName,
          }))
        );
      })
      .catch((e) => {
        toastr.error(e.data.message);
      })
      .finally(() => {
        setloading(false)
      });
  };

  useEffect(() => {
    fetchAllDiscipline();
    setMandatoryStatus(true);
    setAggregateStatus(true)
  }, [discipline]);
  /***************************Start Add Disciplines***************************/
  const onChangeIsChildStatus = (e) => {
    setIsChildStatus(`${e.target.checked}`);
    if (e.target.checked === true) {
      getData(DisciplinesApi.getEligibleParents().url)
        .then((response) => {
          const data = response.data.eligibleParentDisciplines;
          setEligibleParentsList(data);
        })
        .catch((e) => {
          toastr.error(e.data.message);
        })
    }
  };

  const OpenCreateUserModal = () => {
    setIsModalVisible(true);
    setIsChildStatus('false');
  };

  const CloseCreateUserModal = () => {
    setIsModalVisible(false);
    setMandatoryStatus(true);
    setIsChildStatus('false');
    form.resetFields();
  };

  const addNewDisciplines = async (values) => {
    setloading(true);
    postData(DisciplinesApi.addDisciplines().url, values)
      .then((response) => {
        setDiscipline(response.data);
        toastr.success('Successfully Submitted');
        setIsModalVisible(false);
      })
      .catch((e) => {
        toastr.error(e.data.message);
      })
      .finally(() => {
        setloading(false);
        form.resetFields();
        setIsChildStatus('false');
      });
  };

  const renderModalContent = () => {
    return (
      <div className="CreateUser">
        <Row>
          <Col sm={24}>
            <Form
              form={form}
              layout="vertical"
              name="form_in_modal"
              initialValues={{ position: 1, isAggregate: true, isMandatory: true, priority: 0 }}
            >
              <Form.Item
                name="name"
                label="Discipline"
                rules={[{ required: true, message: 'Please enter discipline' }]}
              >
                <Input />
              </Form.Item>
              <Checkbox
                onChange={onChangeIsChildStatus}
                checked={isChildStatus === 'true' ? true : false}
              >Is Child ?</Checkbox>
              <br /><br />
              <div className='isChildBox'>
                <Form.Item
                  name="parentId"
                  label="Select a Parent"
                  className={isChildStatus === 'true' ? 'fieldShow' : 'fieldHide'}
                  rules={[{ required: isChildStatus === 'true' ? true : false, message: 'Please Select a Parent' }]}
                >
                  <Select
                    placeholder="Select a Parent"
                  >
                    {
                      (eligibleParentsList || []).map(item => (
                        <Option value={item.id} key={item.id}>{item.name}</Option>
                      ))
                    }
                  </Select>
                </Form.Item>
                <Form.Item
                  name="beforeName"
                  label="Before Performance Name"
                  className={isChildStatus === 'true' ? 'fieldShow' : 'fieldHide'}
                  rules={[{ required: isChildStatus === 'true' ? true : false, message: 'Enter Before Performance Name' }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="duringName"
                  label="During Performance Name"
                  className={isChildStatus === 'true' ? 'fieldShow' : 'fieldHide'}
                  rules={[{ required: isChildStatus === 'true' ? true : false, message: 'Enter During Performance Name' }]}
                >
                  <Input />
                </Form.Item>
              </div>



              <Form.Item
                name="position"
                label="Display Order"
                // rules={[{ required: true, message: 'Please enter display order' }]}
                className={isChildStatus === 'false' ? 'fieldShow' : 'fieldHide'}
              >
                <InputNumber min={1} />
              </Form.Item>
              <Form.Item
                name="priority"
                label="Priority"
                // rules={[{ required: true, message: 'Please enter priority' }]}
                className={isChildStatus === 'false' ? 'fieldShow' : 'fieldHide'}
              >
                <InputNumber min={1} />
              </Form.Item>
              <Form.Item
                name="isAggregate"
                className={isChildStatus === 'false' ? 'fieldShow' : 'fieldHide'}
              >
                <Radio.Group onChange={() => setMandatoryStatus(!mandatoryStatus)}>
                  <Radio value={aggregateStatus}>Aggregate</Radio>
                  <Radio value='false'>Non Aggregate</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                name="isMandatory"
                valuePropName="checked"
                className={isChildStatus === 'false' ? 'fieldShow' : 'fieldHide'}
              >
                <Checkbox disabled={mandatoryStatus ? 'disabled' : ''}>Mandatory</Checkbox>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </div>
    );
  };
  /***************************End Add Disciplines*****************************/
  /***************************Start Edit Disciplines***************************/
  const [editDisciplineModal, setEditDisciplineModal] = useState(false);
  const [selectedDisciplineId, setSelectedDisciplineId] = useState(null);
  const [selectedDisciplineName, setSelectedDisciplineName] = useState('');
  const [selectedDisciplineOrderBy, setSelectedDisciplineOrderBy] = useState(null);
  const [selectedDisciplinePriority, setSelectedDisciplinePriority] = useState(null);
  const [selectedAggregateStatus, setSelectedAggregateStatus] = useState(null);
  const [selectedMandatoryStatus, setSelectedMandatoryStatus] = useState(null);

  const [selectedParentStatus, setSelectedParentStatus] = useState('false');
  const [selectedParentId, setSelectedParentId] = useState(null);
  const [selectedBeforeName, setSelectedBeforeName] = useState('');
  const [selectedDuringName, setSelectedDuringName] = useState('');

  const showEditDisciplinesModal = (record, data) => {
    setEditDisciplineModal(true);
    setSelectedDisciplineId(data.id)
    setSelectedDisciplineName(data.name);
    setSelectedDisciplineOrderBy(data.position);
    setSelectedDisciplinePriority(data.priority);
    setSelectedMandatoryStatus(data.isMandatory);
    setSelectedAggregateStatus(data.isAggregate);
    setSelectedBeforeName(data.beforeName);
    setSelectedDuringName(data.duringName);
    if (data.parent) {
      setSelectedParentId(data.parent.id)
      setSelectedParentStatus('true')
    } else {
      setSelectedParentId(null)
      setSelectedParentStatus('false')
    }

    getData(DisciplinesApi.getEligibleParents().url)
      .then((response) => {
        const data = response.data.eligibleParentDisciplines;
        setEligibleParentsList(data);
      })
      .catch((e) => {
        toastr.error(e.data.message);
      })
  };

  const cancelEditDisciplinesModal = () => {
    setEditDisciplineModal(false);
    setSelectedDisciplineName('');
    setSelectedDisciplineOrderBy(null);
    setSelectedDisciplinePriority(null);
    setSelectedMandatoryStatus(true);
    setSelectedAggregateStatus(true);
    setSelectedParentId(null)
    setSelectedBeforeName('');
    setSelectedDuringName('');
  };

  const onChangeDisciplineName = e => {
    setSelectedDisciplineName(e.target.value);
  }

  const onChangeDisciplineOrderBy = e => {
    setSelectedDisciplineOrderBy(e.target.value);
  }

  const onChangeDisciplinePriority = e => {
    setSelectedDisciplinePriority(e.target.value);
  }

  const onChangeUpdateisAggregate = e => {
    setSelectedAggregateStatus(e.target.value);
    if (e.target.value === 'true') {
      setSelectedMandatoryStatus('true');
    } else {
      setSelectedMandatoryStatus('false');
    }
  };

  const onChangeUpdateisMandatory = e => {
    setSelectedMandatoryStatus(`${e.target.checked}`);
  }

  const onChangeParentId = value => {
    setSelectedParentId(value);
  }

  const onChangeBeforeName = e => {
    setSelectedBeforeName(e.target.value);
  }

  const onChangeDuringName = e => {
    setSelectedDuringName(e.target.value);
  }

  const onChangeSelectedIsChildStatus = (e) => {
    setSelectedParentStatus(`${e.target.checked}`);
  };

  const UpdateEditDisciplinesModal = () => {
    if (selectedDisciplineName === '') {
      toastr.error('Please enter disciplines');
    } else if (selectedDisciplineOrderBy === '') {
      toastr.error('Please enter display order');
    } else {
      const values = {
        name: selectedDisciplineName,
        position: selectedDisciplineOrderBy,
        priority: selectedDisciplinePriority,
        isAggregate: selectedAggregateStatus,
        isMandatory: selectedMandatoryStatus,
        parentId: `${selectedParentStatus === 'true' ? selectedParentId : null}`,
        beforeName: `${selectedParentStatus === 'true' ? selectedBeforeName : ''}`,
        duringName: `${selectedParentStatus === 'true' ? selectedDuringName : ''}`,
      }
      setloading(true);
      putData(DisciplinesApi.updateEditDisciplines(selectedDisciplineId).url, values)
        .then((response) => {
          toastr.success('Successfully Submitted');
          setEditDisciplineModal(false);
          setSelectedDisciplineName('');
          setSelectedDisciplineOrderBy(null);
          setSelectedMandatoryStatus(true);
          setSelectedAggregateStatus(true);
          setSelectedParentId(null)
          setSelectedBeforeName('');
          setSelectedDuringName('');
        })
        .catch((e) => {
          toastr.error(e.data.message);
        })
        .finally(() => {
          setloading(false);
          fetchAllDiscipline();


        })
    }
  };

  const renderSelectedDisciplineModal = () => {
    return (
      <div className="CreateUser">
        <Row>
          <Col sm={24}>
            <p><span className="redMandatory">*</span><strong>Discipline</strong></p>
            <Input
              value={selectedDisciplineName}
              onChange={onChangeDisciplineName}
            />
          </Col>
          <Col sm={24}>
            <br />
            <Checkbox
              onChange={onChangeSelectedIsChildStatus}
              checked={selectedParentStatus === 'true' ? true : false}
            >Is Child ?</Checkbox>
            <br />
          </Col>
          <Col sm={24} className={selectedParentStatus === 'true' ? 'fieldShow' : 'fieldHide'}>
            <p><span className="redMandatory">*</span><strong>Select a Parent</strong></p>
            <Select
              style={{ width: '100%' }}
              placeholder="Select a Parent"
              value={selectedParentId}
              onChange={onChangeParentId}
            >
              {
                (eligibleParentsList || []).map(item => (
                  <Option value={item.id} key={item.id}>{item.name}</Option>
                ))
              }
            </Select>
          </Col>

          <Col sm={24} className={selectedParentStatus === 'true' ? 'fieldShow' : 'fieldHide'}>
            <p><span className="redMandatory">*</span><strong>Before Performance Name</strong></p>
            <Input
              value={selectedBeforeName}
              onChange={onChangeBeforeName}
            />
          </Col>
          <Col sm={24} className={selectedParentStatus === 'true' ? 'fieldShow' : 'fieldHide'}>
            <p><span className="redMandatory">*</span><strong>During Performance Name</strong></p>
            <Input
              value={selectedDuringName}
              onChange={onChangeDuringName}
            />
          </Col>
          <Col sm={24} className={selectedParentStatus === 'false' ? 'fieldShow' : 'fieldHide'}>
            <p><span className="redMandatory">*</span><strong>Display Order</strong></p>
            <Input
              type='number'
              min='1'
              value={selectedDisciplineOrderBy}
              onChange={onChangeDisciplineOrderBy}
            />
          </Col>
          <Col sm={24} className={selectedParentStatus === 'false' ? 'fieldShow' : 'fieldHide'}>
            <p><span className="redMandatory">*</span><strong>Priority</strong></p>
            <Input
              type='number'
              min='0'
              value={selectedDisciplinePriority}
              onChange={onChangeDisciplinePriority}
            />
          </Col>
          <Col sm={24} className={selectedParentStatus === 'false' ? 'fieldShow' : 'fieldHide'}>
            <p><strong> <br /></strong></p>
            <Radio.Group onChange={onChangeUpdateisAggregate} value={selectedAggregateStatus}>
              <Radio value='true'>Aggregate</Radio>
              <Radio value='false'>Non Aggregate</Radio>
            </Radio.Group>
          </Col>
          <Col sm={24} className={selectedParentStatus === 'false' ? 'fieldShow' : 'fieldHide'}>
            <p><strong> <br /></strong></p>
            <Checkbox
              onChange={onChangeUpdateisMandatory}
              checked={selectedMandatoryStatus === 'true' ? true : false}
              disabled={selectedAggregateStatus === 'true' ? true : false}
            >
              Mandatory
            </Checkbox>
          </Col>
        </Row>
      </div>
    );
  };
  /***************************End Edit Disciplines*****************************/

  const handlePageChange = value => {
    setActivePage(value.current);
  }

  const renderdisciplineListContent = () => {
    if (loading) return <LoadingView message="Loading..." />;
    return (
      <div className="site-card-wrapper">
        <Row gutter={16}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
            <Button type="primary" onClick={OpenCreateUserModal}>
              Add Discipline
            </Button>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
            <Table
              columns={columns}
              dataSource={disciplineList}
              rowClassName="disciplineRow"
              pagination={{ current: activePage }}
              onChange={handlePageChange}
            />
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <PageLayout>
      <div className="disciplineInfo ">
        {renderdisciplineListContent()}
      </div>
      <Modal
        width="45vw"
        title="Add Discipline"
        okText="Add Discipline"
        visible={isModalVisible}
        onCancel={CloseCreateUserModal}
        cancelText="Cancel"
        onOk={() => {
          form
            .validateFields()
            .then(values => {
              form.resetFields();
              addNewDisciplines(values);
            })
            .catch(info => {
              console.log('Validate Failed:', info);
            });
        }}
      >
        {renderModalContent()}
      </Modal>
      <Modal
        width="45vw"
        title='Edit Discipline'
        visible={editDisciplineModal}
        okText='Update'
        onOk={UpdateEditDisciplinesModal}
        cancelText="Cancel"
        onCancel={cancelEditDisciplinesModal}>
        {renderSelectedDisciplineModal()}
      </Modal>
    </PageLayout>
  );
};

export default Discipline;
