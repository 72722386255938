const getActiveUsers = () => ({
  url: `/users?isActive=true`,
});
const getInActiveUsers = () => ({
  url: `/users?isActive=false`,
});
const getUsersById = userID => ({
  url: `/users/${userID}`,
});
const addUsers = () => ({
  url: `/users`,
});
const UpdateUsers = userID => ({
  url: `/users/${userID}`,
});
const userResetPassword = () => ({
  url: `/users/reset-password`,
});
const userForgetPassword = () => ({
  url: `/users/forgot-password`,
});

const updateUserEmail = (userID, email) => ({
  url: `/users/email/${userID}?email=${email}`,
})


// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getActiveUsers,
  getInActiveUsers,
  addUsers,
  getUsersById,
  UpdateUsers,
  userResetPassword,
  userForgetPassword,
  updateUserEmail,
};

