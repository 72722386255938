import axios from "axios";
import { login_token } from "./auth";

const createHistory = require("history").createHashHistory;
var history = createHistory();

export const postData = (
  url,
  data,
  options = {
    without_base_url: false,
    method_type: "post",
  }
) => {
  var BASE_URL = process.env.REACT_APP_API_BASE_URL;
  var request_url = BASE_URL + url;

  if (options.without_base_url) {
    request_url = url;
  }
  return new Promise((resolve, reject) => {
    axios({
      method: options.method_type,
      url: request_url,
      data: data,
      headers: {
        Authorization: `Bearer ${login_token()}`,
      },
    })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        if (error.response) {
          if (
            error.response.status === 401 &&
            error.response.data.message === "Unauthenticated."
          ) {
            history.push("/login");
          }
          reject(error.response);
        } else {
          reject({ data: { message: "Network Error" } });
        }
      });
  });
};

export const getData = (
  url,
  data,
  options = {
    without_base_url: false,
    method_type: "GET",
  }
) => {
  var BASE_URL = process.env.REACT_APP_API_BASE_URL;
  var request_url = BASE_URL + url;

  if (options.without_base_url) {
    request_url = url;
  }
  return new Promise((resolve, reject) => {
    axios({
      method: options.method_type,
      url: request_url,
      data: data,
      headers: {
        Authorization: `Bearer ${login_token()}`,
      },
    })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        if (error.response) {
          if (
            error.response.status === 401 &&
            error.response.data.message === "Full authentication is required to access this resource"
          ) {
            // history.push("/login");
            // window.location.href = '/'
            localStorage.removeItem('user');
          }
          reject(error.response);
        } else {
          reject({ data: { message: "Network Error" } });
        }
      });
  });
};

export const putData = (
  url,
  data,
  options = {
    without_base_url: false,
    method_type: "PUT",
  }
) => {
  var BASE_URL = process.env.REACT_APP_API_BASE_URL;
  var request_url = BASE_URL + url;

  if (options.without_base_url) {
    request_url = url;
  }
  return new Promise((resolve, reject) => {
    axios({
      method: options.method_type,
      url: request_url,
      data: data,
      headers: {
        Authorization: `Bearer ${login_token()}`,
      },
    })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        if (error.response) {
          if (
            error.response.status === 401 &&
            error.response.data.message === "Unauthenticated."
          ) {
            history.push("/login");
          }
          reject(error.response);
        } else {
          reject({ data: { message: "Network Error" } });
        }
      });
  });
};


export const getPdfData = (
  url,
  data,
  options = {
    without_base_url: false,
    method_type: "GET",
  }
) => {
  var BASE_URL = process.env.REACT_APP_API_BASE_URL;
  var request_url = BASE_URL + url;

  if (options.without_base_url) {
    request_url = url;
  }
  return new Promise((resolve, reject) => {
    axios({
      method: options.method_type,
      url: request_url,
      data: data,
      headers: {
        Authorization: `Bearer ${login_token()}`,
      },
      responseType: 'blob'
    })
      .then(function (response) {
        resolve(response);
        // const file = new Blob(
        //   [response.data],
        //   { type: 'application/pdf' });

        // console.log('file :', response);
        // const fileURL = URL.createObjectURL(file,);
        // window.open(fileURL);

        const filename = (response.request.getResponseHeader('Content-Disposition').split('filename="')[1].split(';')[0]).slice(0, -6)
        // console.log('file :', filename);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${filename}.pdf`);
        document.body.appendChild(link);
        link.click();

      })
      .catch(function (error) {
        if (error.response) {
          if (
            error.response.status === 401 &&
            error.response.data.message === "Full authentication is required to access this resource"
          ) {
            // history.push("/login");
            // window.location.href = '/'
            localStorage.removeItem('user');
          }
          reject(error.response);
        } else {
          reject({ data: { message: "Network Error" } });
        }
      });
  });
};

export const getExcelData = (
  url,
  data,
  options = {
    without_base_url: false,
    method_type: "GET",
  }
) => {
  var BASE_URL = process.env.REACT_APP_API_BASE_URL;
  var request_url = BASE_URL + url;

  if (options.without_base_url) {
    request_url = url;
  }
  return new Promise((resolve, reject) => {
    axios({
      method: options.method_type,
      url: request_url,
      data: data,
      headers: {
        Authorization: `Bearer ${login_token()}`,
      },
      responseType: 'blob'
    })
      .then(function (response) {
        resolve(response);

        const filename = (response.request.getResponseHeader('Content-Disposition').split('filename="')[1].split(';')[0]).slice(0, -6)
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${filename}.xlsx`);
        document.body.appendChild(link);
        link.click();

      })
      .catch(function (error) {
        if (error.response) {
          if (
            error.response.status === 401 &&
            error.response.data.message === "Full authentication is required to access this resource"
          ) {
            // history.push("/login");
            // window.location.href = '/'
            localStorage.removeItem('user');
          }
          reject(error.response);
        } else {
          reject({ data: { message: "Network Error" } });
        }
      });
  });
};


