const getMehi = (eventID, poolId, teamID) => ({
  url: `/user/events/${eventID}/pools/${poolId}/teams/${teamID}/mehi`,
});

const addMehi = (eventID, poolId, teamID) => ({
  url: `/user/events/${eventID}/pools/${poolId}/teams/${teamID}/mehi`,
});

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getMehi,
  addMehi,
};

