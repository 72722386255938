import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { LoadingView } from '../common';
import PageLayout from "../layout"

import toastr from 'toastr';
import { CommentsApi, DisciplinesApi } from '../../api';
import { postData, getData, putData } from '../../_services/apiCommonService';

import { Row, Col, Modal, Button, Input, Checkbox, Table, Steps, Select, Popconfirm } from 'antd';
import { MinusCircleOutlined } from '@ant-design/icons';
import './Comments.scss';

const Comments = () => {
  const [loading, setloading] = useState(true);
  const [disciplineList, setDisciplineList] = useState([]);
  const [commentsList, setCommentsList] = useState([]);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [current, setCurrent] = useState(0);
  const [commentValue, setCommentValue] = useState(null);
  const [disciplinesValue, setDisciplinesValue] = useState([]);
  const [commentsRes, setCommentsRes] = useState(null);

  const [activePage, setActivePage] = useState(1);

  const [isCommentsModalVisible, setIsCommentsModalVisible] = useState(false);
  const [selectedCommentId, setSelectedCommentId] = useState(null);
  const [commentsTitle, setCommentsTitle] = useState('');
  const [selectedDisciplinesValue, setSelectedDisciplinesValue] = useState([]);
  const [newSelectedDisciplines, setNewSelectedDisciplines] = useState(null);

  let tempDisciplineList = [];

  const { Step } = Steps;
  const { Option } = Select;
  const { TextArea } = Input;

  const columns = [
    {
      title: 'Comments',
      dataIndex: 'name',
      sorter: (a, b) => a.name.length - b.name.length,
      render: (record, val) => {
        return (
          <span
            className="nameLink"
            onClick={(e) => showEditCommentsModal(record, val)}
          >
            {record}
          </span>
        )
      },
    },
  ];

  const fetchAllDiscipline = () => {
    setloading(true)
    getData(DisciplinesApi.getDisciplinesExcludeParents().url)
      .then((response) => {
        setDisciplineList(response.data.excludedParentDisciplines);
      })
      .catch((err) => {
        toastr.error(err.data.message);
      })
      .finally(() => {
        setloading(false);
      });
  };

  const fetchCommentsList = () => {
    setloading(true)
    getData(CommentsApi.getComments().url)
      .then((response) => {
        setCommentsList(response.data.comments);
      })
      .catch((err) => {
        toastr.error(err.data.message);
      })
      .finally(() => {
        setloading(false)
      });
  };

  useEffect(() => {
    fetchCommentsList();
    fetchAllDiscipline();
    setCommentsList();
  }, [commentsRes]);

  /***************************Start Add Comments***************************/
  const OpenAddCommentModal = async () => {
    setIsModalVisible(true);
  };

  const CloseAddCommentModal = async () => {
    setIsModalVisible(false);
    setCommentValue(null);
    setDisciplinesValue([]);
    setCurrent(0);
  };

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
    setDisciplinesValue([]);
  };

  const onChangecommentValue = e => {
    setCommentValue(e.target.value);
  };

  const onChangeSelectDisciplinesValue = checkedValues => {
    setDisciplinesValue(checkedValues);
  };

  const updateComment = async () => {
    if (commentValue === '' || commentValue === null) {
      toastr.error('Please add comment name');
    }
    else if (disciplinesValue.length === 0) {
      toastr.error('Please disciplines ');
    }
    else {
      const comments = {
        name: commentValue,
        disciplineIds: disciplinesValue,
      }
      setloading(true);

      postData(CommentsApi.addComments().url, comments)
        .then((response) => {
          setCommentsRes(response.data);
          toastr.success('Successfully Submitted');

        })
        .catch((e) => {
          toastr.error(e.data.message);
        })
        .finally(() => {
          setloading(false);
          setIsModalVisible(false);
          setCommentValue(null);
          setDisciplinesValue([]);
          prev();
        })
    }
  };

  const renderSelectedComment = () => {
    if (loading) return <LoadingView message="Loading..." />;
    return (
      <React.Fragment>
        <p><strong>Comment</strong></p>
        <TextArea placeholder="Comment" onChange={onChangecommentValue} value={commentValue} rows={4}/>
      </React.Fragment>
    );
  };

  const renderSelectedDisciplines = () => {
    if (loading) return <LoadingView message="Loading..." />;
    return (
      <React.Fragment>
        <p><strong>Disciplines</strong></p>
        <Checkbox.Group style={{ width: '100%' }} onChange={onChangeSelectDisciplinesValue}>
          {
            disciplineList.map(discipline => (
              <Checkbox value={discipline.id} key={discipline.id}>{discipline.name}</Checkbox>
            ))
          }
        </Checkbox.Group>
      </React.Fragment>
    );
  };

  const steps = [
    {
      title: 'Add Comment',
      content: renderSelectedComment(),

    },
    {
      title: 'Select Disciplines',
      content: renderSelectedDisciplines(),
    },
  ];

  const renderSteps = () => {
    return (
      <Steps current={current} className="modalHeadingSteps">
        {steps.map(item => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
    );
  };

  const renderModalContent = () => {
    if (loading) return <LoadingView message="Loading..." />;
    return (
      <div className="steps-content">{steps[current].content}</div>
    );
  };

  const switchFooter = () => (
    <div className="steps-action">
      <Button type="default" onClick={() => CloseAddCommentModal()}>Cancel</Button>
      {current < steps.length - 1 && (
        <React.Fragment>
          {commentValue === null || commentValue === ''
            ? <Button type="primary" onClick={() => updateComment()}>Next</Button>
            : <Button type="primary" onClick={() => next()}>Next</Button>
          }
        </React.Fragment>
      )}
      {current > 0 && (
        <Button onClick={() => prev()}>
          Previous
        </Button>
      )}
      {current === steps.length - 1 && (
        <React.Fragment>
          {disciplinesValue.length === 0 || commentValue === ''
            ? <Button type="primary" onClick={() => updateComment()}>Done</Button>
            : <Button type="primary" onClick={() => updateComment()}>Done</Button>
          }
        </React.Fragment>
      )}

    </div>
  );

  /***************************End Add Comments*****************************/

  /***************************Start Edit Comments**************************/
  const showEditCommentsModal = (record, data) => {
    setIsCommentsModalVisible(true);
    setSelectedCommentId(data.id);
    setCommentsTitle(record);
    setSelectedDisciplinesValue(data.disciplines)
  };

  const cancelEditCommentsModal = () => {
    setIsCommentsModalVisible(false);
  };

  const onChangeEditCommentsName = e => {
    setCommentsTitle(e.target.value);
  }

  const onChangeAddDisciplines = (value) => {
    setNewSelectedDisciplines(value);
  }

  const OnClickRemoveDisciplines = async (index, e) => {
    var data = selectedDisciplinesValue;
    data.splice(index, 1);
    setSelectedDisciplinesValue([...data]);
  };

  const updateEditDisciplines = async () => {
    const disciplineIds = [];

    (selectedDisciplinesValue || []).map(item => {
      disciplineIds.push(item.id);
      return item
    })

    const finalDisciplineIds = (disciplineIds.concat(newSelectedDisciplines)).filter(function (el) {
      return el != null;
    });

    if (commentsTitle === '') {
      toastr.error('Please enter comment');
    } else {
      const values = {
        name: commentsTitle,
        disciplineIds: finalDisciplineIds
      }
      setloading(true);
      putData(CommentsApi.updateEditComments(selectedCommentId).url, values)
        .then((response) => {
          toastr.success('Successfully Submitted');
        })
        .catch((e) => {
          toastr.error(e.data.message);
        })
        .finally(() => {
          setloading(false);
          setIsCommentsModalVisible(false);
          fetchCommentsList();
          setSelectedDisciplinesValue([]);
          setNewSelectedDisciplines([]);
        })
    }
  };

  const editDisciplinesFooter = () => (
    <div className="steps-action">
      <Button type="default" onClick={() => cancelEditCommentsModal()}>Cancel</Button>
      <Button type="primary" onClick={() => updateEditDisciplines()}>Update</Button>
    </div>
  );

  const renderSelectedDisciplinesModal = () => {
    // const tempDisciplineList = [];
    (disciplineList || []).map(item => {
      if (selectedDisciplinesValue.find(o => o.id === item.id)) {
        // console.log(item)
      }
      else {
        tempDisciplineList.push(item);
      }
      return item;
    })
    return (
      <React.Fragment>
        <div className="modalForm">
          <p><span className="redMandatory">*</span><strong>Comment</strong></p>
          <TextArea value={commentsTitle} onChange={onChangeEditCommentsName} rows={4}/>

          <p><strong>Select disciplines</strong></p>

          <ul className="selectedDisciplines">
            {(selectedDisciplinesValue || []).map((item, index) => (
              <li key={item.id}>
                <span>{item.name}</span>
                <span>
                  <Popconfirm title="Sure to delete?" onConfirm={e => OnClickRemoveDisciplines(index, e)}>
                    <Link className="rowDelete"><MinusCircleOutlined /></Link>
                  </Popconfirm>
                </span>
              </li>
            ))}
          </ul>

          <p><strong>Add More Disciplines</strong></p>
          <Select
            mode="multiple"
            allowClear
            style={{ width: '100%' }}
            placeholder="Please select"
            onChange={onChangeAddDisciplines}
          >
            {
              (tempDisciplineList || []).map(discipline => (
                <Option key={discipline.id} value={discipline.id}>{discipline.name}</Option>
              ))
            }
          </Select>
        </div>
      </React.Fragment>
    );
  };
  /***************************End Edit Comments****************************/
  const handlePageChange = value => {
    setActivePage(value.current);
  }

  const renderCommentsInfoContent = () => {
    if (loading) return <LoadingView message="Loading..." />;
    return (
      <div className="site-card-wrapper">
        <Row gutter={16}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
            <Button type="primary" onClick={OpenAddCommentModal}>
              Add Comment
            </Button>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
            <Table
              columns={columns}
              dataSource={commentsList}
              rowClassName="commmentsRow"
              rowKey={record => record.id}
              loading={loading}
              pagination={{ current: activePage }}
              onChange={handlePageChange}
            />
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <PageLayout>
      <div className="commentsInfo ">
        {renderCommentsInfoContent()}
      </div>
      <Modal
        className="addCommentModal"
        title={renderSteps()}
        okText="Add comment"
        visible={isModalVisible}
        onCancel={CloseAddCommentModal}
        cancelText="Cancel"
        footer={switchFooter()}
      >
        {renderModalContent()}
      </Modal>
      <Modal
        title='Edit Comment'
        visible={isCommentsModalVisible}
        okText="Update"
        onCancel={cancelEditCommentsModal}
        footer={editDisciplinesFooter()}
      >
        {renderSelectedDisciplinesModal()}
      </Modal>
    </PageLayout>
  );
};

export default Comments;
