import {
  USER_LOGIN_LOCALSTORAGE_TOKEN_KEY,
  USER_LOGIN_LOCALSTORAGE_ADMIN_STATUS,
  USER_LOGIN_LOCALSTORAGE_ADMIN_ROLE,
  USER_LOCALSTORAGE_EVENT_ID,
} from "./config";

export const isLoggedIn = () => {
  return !!localStorage.getItem(USER_LOGIN_LOCALSTORAGE_TOKEN_KEY);
};

export const setToken = (token) => {
  localStorage.setItem(USER_LOGIN_LOCALSTORAGE_TOKEN_KEY, token);
  return true;
};

export const login_token = () => {
  return localStorage.getItem(USER_LOGIN_LOCALSTORAGE_TOKEN_KEY);
};

export const setAdminStatus = (isAdmin) => {
  localStorage.setItem(USER_LOGIN_LOCALSTORAGE_ADMIN_STATUS, isAdmin);
  return true;
};

export const admin_Status = () => {
  return localStorage.getItem(USER_LOGIN_LOCALSTORAGE_ADMIN_STATUS);
};

export const setAdminRole = (role) => {
  localStorage.setItem(USER_LOGIN_LOCALSTORAGE_ADMIN_ROLE, role);
  return true;
};

export const adminRole = () => {
  return localStorage.getItem(USER_LOGIN_LOCALSTORAGE_ADMIN_ROLE);
};

export const setEventId = (id) => {
  localStorage.setItem(USER_LOCALSTORAGE_EVENT_ID, id);
  return true;
};

export const eventId = () => {
  return localStorage.getItem(USER_LOCALSTORAGE_EVENT_ID);
};

