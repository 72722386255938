const userGetStartEvent = () => ({
  url: `/user/events?eventStatus=STARTED`,
});

const userGetCompleteEvent = () => ({
  url: `/user/events?eventStatus=COMPLETED`,
});

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  userGetStartEvent,
  userGetCompleteEvent,
};

