
import React from 'react';
import toastr from 'toastr';
import axios from "axios";
import Logo from '../assets/images/2021_Te Matatini_50_Year_Tohu-02.png'
import { Button, Input, Form, Row, Col, Image } from 'antd';
import './LoginPage.scss';

const forgetPassword = (props) => {

  /* eslint-disable no-template-curly-in-string */
  const validateMessages = {
    types: {
      email: '${label} is not a valid email!',
    },
  };
  /* eslint-enable no-template-curly-in-string */

  const onFinish = (values) => {

    axios.put(process.env.REACT_APP_API_BASE_URL + '/users/forgot-password', values)
      .then(response => {
        toastr.success('Successfully Submitted');
        localStorage.removeItem('user');
        window.location.href = '/login';
      })
      .catch(e => {
        toastr.error(e.response.data.message);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <React.Fragment>
      <Row>
        <Col span={24}>
          <div style={{ 'text-align': 'center', 'margin': '20px 0' }}>
            <Image
              width={200}
              src={Logo}
              preview={false}
            />
          </div>
        </Col>
      </Row>
      <div className="resetPassword">
        <Row>
          <Col span={24}>
            <Form
              name="nest-messages"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              validateMessages={validateMessages}>
              <Form.Item
                name='email'
                label="Email"
                rules={[{ type: 'email' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item>
                <div style={{ 'text-align': 'right' }}>
                  <Button type="primary" htmlType="submit" className="forgetPasswordBtn">
                    Submit
                  </Button>
                </div>

              </Form.Item>
            </Form>

          </Col>
        </Row>
      </div>

    </React.Fragment >
  );
};

export default forgetPassword;
