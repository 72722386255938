import React, { useState, useEffect } from 'react';
import { useParams, useHistory, useLocation } from "react-router-dom";
import { LoadingView } from '../../common';
import toastr from 'toastr';
import { EventApi } from '../../../api';
import { getData, postData } from '../../../_services/apiCommonService';
import { eventId } from '../../../_services/auth';

import {
  Row,
  Col,
  Button,
  Select,
  Breadcrumb,
  Radio,
  TimePicker,
  Input,
  Tabs,
  Popconfirm,
} from 'antd';
import moment from 'moment';
import '../../Judges/Event/EventTeams.scss';
import { CloseOutlined } from '@ant-design/icons';

const EventTimekeperScrutineer = props => {
  const location = useLocation();
  if (location.state === undefined) {
    window.location.href = '/';
  }

  let eventName = location.state.eventName;
  let teamName = location.state.teamName;
  let poolId = location.state.poolId;

  const { tId } = useParams();
  const history = useHistory();
  const { Option } = Select;
  const { TextArea } = Input;
  const { TabPane } = Tabs;

  const [loading, setloading] = useState(true);
  const [disciplinesList, setDisciplinesList] = useState([]);
  const [instrumentsList, setInstrumentsList] = useState([]);
  const [instrumentsPenaltyList, setInstrumentsPenaltyList] = useState([]);
  // const [averageTTPList, setAverageTTPList] = useState([]);
  const [averageTTPListStatus, setAverageTTPListStatus] = useState('false');
  const [statusCheck, setStatusCheck] = useState(null);
  const [instrumentViolation, setInstrumentViolation] = useState(false);
  const [waiataAtiraPerformedStatusCheck, setWaiataAtiraPerformedStatusCheck] = useState(false);
  const [penaltyList, setPenaltyList] = useState([{
    id: 1,
    isWaiataAtiraPerformed: null,
    isPerformedBeforeWhakaeke: null,
    isCompulsoryDisciplinesPerformed: null,
    timeTakenForWaiataAtira: null,
    penaltyForWaiataAtira: null,
    timeTakenForCompulsoryDisciplines: null,
    penaltyForCompulsoryDisciplines: null,
    disqualifyTeam: 'false',
    groupCount: null,
    comment: null,
    isScriptsLate: null,
    isKapaHakaLate: null,
    isGroupCountBetween24To40: null,
  }]);

  const [timekeepersDataStatus, setTimekeepersDataStatus] = useState(null);
  const [timekeepersInstrumentViolation, setTimekeepersInstrumentViolation] = useState(false);
  const [timekeepersInstrumentsPenaltyList, setTimekeepersInstrumentsPenaltyList] = useState([]);
  const [timekeepersList, setTimekeepersList] = useState([]);
  const [timekeepersPenaltyList, setTimekeepersPenaltyList] = useState([{
    id: 1,
    isWaiataAtiraPerformed: null,
    isPerformedBeforeWhakaeke: null,
    isCompulsoryDisciplinesPerformed: null,
    timeTakenForWaiataAtira: null,
    penaltyForWaiataAtira: null,
    timeTakenForCompulsoryDisciplines: null,
    penaltyForCompulsoryDisciplines: null,
    disqualifyTeam: 'false',
    groupCount: null,
    comment: null,
  }]);
  const [timekeepersPerformedStatus, setTimekeepersPerformedStatus] = useState(false);

  const commentTextType = 'NAME_VALUE';

  let timeValue1;
  let timeValue2;

  const loadAverageTimeTakenPerformances = () => {
    setloading(true);
    getData(EventApi.get_AverageTimeTakenPerformances(eventId(), poolId, tId).url)
      .then((response) => {
        const data = response.data.averageTimeTakenForPerformances;
        timeValue1 = data.waiataAtira;
        timeValue2 = data.compulsoryDisciplines;
        // setAverageTTPList(data);
        setAverageTTPListStatus(null);
        console.log('timeValue111', timeValue1);
      })
      .catch((err) => {
        // toastr.error(err.data.message);
        setAverageTTPListStatus(err.data.message);
      })
      .finally(() => {
        setloading(false);
      });
  };

  const loadAllDisciplines = () => {
    setloading(true);
    getData(EventApi.getEventTeamById(eventId(), tId).url)
      .then((response) => {
        const data = response.data.eventTeam;
        setDisciplinesList(data);
      })
      .catch((e) => {
        toastr.error(e.data.message);
      })
      .finally(() => {
        setloading(false);
      });
  };

  const loadAllInstruments = () => {
    setloading(true);
    getData(EventApi.timekepersInstruments().url)
      .then((response) => {
        const data = response.data.instruments;
        setInstrumentsList(data);
      })
      .catch((e) => {
        toastr.error(e.data.message);
      })
      .finally(() => {
        setloading(false);
      });
  };

  const loadTimekepersInstruments = () => {
    setloading(true);

    getData(EventApi.get_Timekepers_ScrutineerPenalty(eventId(), poolId, tId).url)
      .then((response) => {
        const data = response.data.penalty;
        console.log('timeValue', timeValue1);
        if (!data) {
          setStatusCheck('false')
          setPenaltyList([
            {
              id: 1,
              isWaiataAtiraPerformed: null,
              isPerformedBeforeWhakaeke: null,
              isCompulsoryDisciplinesPerformed: null,
              timeTakenForWaiataAtira: timeValue1,
              penaltyForWaiataAtira: 0,
              timeTakenForCompulsoryDisciplines: timeValue2,
              penaltyForCompulsoryDisciplines: 0,
              disqualifyTeam: 'false',
              groupCount: null,
              comment: null,
              isScriptsLate: null,
              isKapaHakaLate: null,
              isGroupCountBetween24To40: null,
            }
          ]);
        } else {
          setStatusCheck('true')
          setWaiataAtiraPerformedStatusCheck(true);
          setPenaltyList([
            {
              id: 1,
              isWaiataAtiraPerformed: `${data.isWaiataAtiraPerformed}`,
              isPerformedBeforeWhakaeke: `${data.isPerformedBeforeWhakaeke}`,
              isCompulsoryDisciplinesPerformed: `${data.isCompulsoryDisciplinesPerformed}`,
              timeTakenForWaiataAtira: data.timeTakenForWaiataAtira,
              penaltyForWaiataAtira: data.penaltyForWaiataAtira,
              timeTakenForCompulsoryDisciplines: data.timeTakenForCompulsoryDisciplines,
              penaltyForCompulsoryDisciplines: data.penaltyForCompulsoryDisciplines,
              disqualifyTeam: `${data.disqualifyTeam}`,
              groupCount: `${data.groupCount}`,
              comment: data.comment,
              isScriptsLate: `${data.isScriptsLate}`,
              isKapaHakaLate: `${data.isKapaHakaLate}`,
              isGroupCountBetween24To40: `${data.isGroupCountBetween24To40}`,
            }
          ]);

          setInstrumentsPenaltyList([]);
          if ((data.disciplineViolations || []).length !== 0) {
            setInstrumentViolation('true');
            const penaltyArray = [];
            (data.disciplineViolations || []).map((ac, index) => {
              penaltyArray.push(
                {
                  id: index,
                  disciplineId: ac.discipline.id,
                  instrumentId: ac.instrument.id,
                  penalty: ac.penalty
                }
              )
              return ac;
            })

            setInstrumentsPenaltyList(penaltyArray);
          }

          // if (data.isWaiataAtiraPerformed) {
          //   setWaiataAtiraPerformedStatusCheck(true);
          // }
          // else {
          //   setWaiataAtiraPerformedStatusCheck(false);
          // }
        }
      })
      .catch((err) => {
        toastr.error(err);
      })
      .finally(() => {
        setloading(false);
      });
  };

  const loadTimekepersDetails = () => {
    setloading(true);
    getData(EventApi.get_Timekepers_Details(eventId(), poolId, tId).url)
      .then((response) => {
        const data = response.data.timekeepers;
        setTimekeepersList(data);
      })
      .catch((err) => {
        toastr.error(err);
      })
      .finally(() => {
        setloading(false);
      });
  };



  useEffect(() => {
    if (tId) {
      loadAverageTimeTakenPerformances();
      loadAllDisciplines();
      loadAllInstruments();
      loadTimekepersDetails();
      setTimeout(function () {
        loadTimekepersInstruments();
      }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tId]);

  const onClickTimekeepers = async (key) => {
    if (key) {
      if (key === 'scrutineer') {
        console.log(key);
      }
      else {
        getData(EventApi.get_Timekepers_Scrutineer(eventId(), poolId, tId, key).url)
          .then((response) => {
            const data = response.data.penalty;
            setTimekeepersDataStatus(data);
            if (!data) {
              setTimekeepersPenaltyList([
                {
                  id: 1,
                  isWaiataAtiraPerformed: null,
                  isPerformedBeforeWhakaeke: null,
                  isCompulsoryDisciplinesPerformed: null,
                  timeTakenForWaiataAtira: null,
                  penaltyForWaiataAtira: null,
                  timeTakenForCompulsoryDisciplines: null,
                  penaltyForCompulsoryDisciplines: null,
                  disqualifyTeam: 'false',
                  groupCount: null,
                  comment: null,
                }
              ]);
            } else {
              setTimekeepersPenaltyList([
                {
                  id: 1,
                  isWaiataAtiraPerformed: `${data.isWaiataAtiraPerformed}`,
                  isPerformedBeforeWhakaeke: `${data.isPerformedBeforeWhakaeke}`,
                  isCompulsoryDisciplinesPerformed: `${data.isCompulsoryDisciplinesPerformed}`,
                  timeTakenForWaiataAtira: data.timeTakenForWaiataAtira,
                  penaltyForWaiataAtira: data.penaltyForWaiataAtira,
                  timeTakenForCompulsoryDisciplines: data.timeTakenForCompulsoryDisciplines,
                  penaltyForCompulsoryDisciplines: data.penaltyForCompulsoryDisciplines,
                  disqualifyTeam: `${data.disqualifyTeam}`,
                  groupCount: `${data.groupCount}`,
                  comment: data.comment,
                }
              ]);

              setTimekeepersInstrumentsPenaltyList([]);
              if ((data.disciplineViolations || []).length !== 0) {
                setTimekeepersInstrumentViolation('true');
                const penaltyArray = [];
                (data.disciplineViolations || []).map((ac, index) => {
                  penaltyArray.push(
                    {
                      id: index,
                      disciplineId: ac.discipline.id,
                      instrumentId: ac.instrument.id,
                      penalty: ac.penalty
                    }
                  )
                  return ac;
                })

                setTimekeepersInstrumentsPenaltyList(penaltyArray);
              }
              if (data.isWaiataAtiraPerformed) {
                setTimekeepersPerformedStatus(true);
              }
              else {
                setTimekeepersPerformedStatus(false);
              }
            }
          })
          .catch((err) => {
            toastr.error(err);
          })
          .finally(() => {
            setloading(false);
          });
      }
    }
  }

  const addPenaltiesRow = async () => {

    const minLength = instrumentsPenaltyList.length;
    const penaltyObj = {
      id: minLength,
      disciplineId: null,
      instrumentId: null,
      penalty: null,
    };
    setInstrumentsPenaltyList([...instrumentsPenaltyList, penaltyObj]);
  };

  const onDeletePenaltiesRow = async (id) => {
    setInstrumentsPenaltyList([...instrumentsPenaltyList.filter(SMD => SMD.id !== id)]);
  };

  const onChangeInstrumentViolation = (e) => {
    setInstrumentViolation(e.target.value);
    if (e.target.value === 'true') {
      addPenaltiesRow();
    } else {
      setInstrumentsPenaltyList([]);
    }
  };

  const onChangePenalties = ({
    id,
    isWaiataAtiraPerformed = null,
    isPerformedBeforeWhakaeke = null,
    isCompulsoryDisciplinesPerformed = null,
    timeTakenForWaiataAtira = null,
    penaltyForWaiataAtira = null,
    timeTakenForCompulsoryDisciplines = null,
    penaltyForCompulsoryDisciplines = null,
    disqualifyTeam = null,
    groupCount = null,
    comment = null,
    isScriptsLate = null,
    isKapaHakaLate = null,
    isGroupCountBetween24To40 = null,
    valueType = null,
  }) => {
    if (penaltyList.findIndex(SMD => SMD.id === id) > -1) {

      const selectedEntry = penaltyList.find(SMD => SMD.id === id);

      if (isWaiataAtiraPerformed) selectedEntry.isWaiataAtiraPerformed = isWaiataAtiraPerformed;
      if (isPerformedBeforeWhakaeke) selectedEntry.isPerformedBeforeWhakaeke = isPerformedBeforeWhakaeke;
      if (isCompulsoryDisciplinesPerformed) selectedEntry.isCompulsoryDisciplinesPerformed = isCompulsoryDisciplinesPerformed;
      if (timeTakenForWaiataAtira) selectedEntry.timeTakenForWaiataAtira = timeTakenForWaiataAtira;
      if (penaltyForWaiataAtira) selectedEntry.penaltyForWaiataAtira = penaltyForWaiataAtira;
      if (timeTakenForCompulsoryDisciplines) selectedEntry.timeTakenForCompulsoryDisciplines = timeTakenForCompulsoryDisciplines;
      if (penaltyForCompulsoryDisciplines) selectedEntry.penaltyForCompulsoryDisciplines = penaltyForCompulsoryDisciplines;
      if (disqualifyTeam) selectedEntry.disqualifyTeam = disqualifyTeam;
      if (groupCount) selectedEntry.groupCount = groupCount;
      if (comment) selectedEntry.comment = comment;
      if (isScriptsLate) selectedEntry.isScriptsLate = isScriptsLate;
      if (isKapaHakaLate) selectedEntry.isKapaHakaLate = isKapaHakaLate;
      if (isGroupCountBetween24To40) selectedEntry.isGroupCountBetween24To40 = isGroupCountBetween24To40;

      if (isWaiataAtiraPerformed === 'false') {
        setWaiataAtiraPerformedStatusCheck(false);
        selectedEntry.isPerformedBeforeWhakaeke = 'false';
        selectedEntry.penaltyForWaiataAtira = '0';
      } else if (isWaiataAtiraPerformed === 'true') {
        setWaiataAtiraPerformedStatusCheck(true);
      }

      if (valueType === commentTextType) {
        selectedEntry.comment = comment;
      }

      setPenaltyList([...penaltyList.map(SMD => (SMD.id === id ? selectedEntry : SMD))]);
    } else {
      console.log('Something Wrong',);
    }
  };

  const onChangeInstrumentsPenalties = ({
    id,
    disciplineId = null,
    instrumentId = null,
    penalty = null,
  }) => {
    if (instrumentsPenaltyList.findIndex(SMD => SMD.id === id) > -1) {

      const selectedEntry = instrumentsPenaltyList.find(SMD => SMD.id === id);

      if (disciplineId) selectedEntry.disciplineId = disciplineId;
      if (instrumentId) selectedEntry.instrumentId = instrumentId;
      if (penalty) selectedEntry.penalty = Number(penalty);

      setInstrumentsPenaltyList([...instrumentsPenaltyList.map(SMD => (SMD.id === id ? selectedEntry : SMD))]);
    } else {
      console.log('Something Wrong',);
    }
  };

  const updateTimekeper = async () => {

    const dataCaptured = penaltyList.map(({
      id, ...rest
    }) => ({ ...rest }));

    const dataPenalties = instrumentsPenaltyList.map(({
      id, ...rest
    }) => ({ ...rest }));

    dataCaptured[0].disciplineViolations = dataPenalties;

    const finalData = dataCaptured[0];

    if (finalData.isWaiataAtiraPerformed === null) {
      toastr.error('Please select Waiata ā-tira performed');
    } else if (finalData.isPerformedBeforeWhakaeke === null) {
      toastr.error('Please select performed before Whakaeke');
    } else if (finalData.isCompulsoryDisciplinesPerformed === null) {
      toastr.error('Please select Compulsory disciplines performed');
    } else if (finalData.timeTakenForCompulsoryDisciplines === null) {
      toastr.error('Please select time taken for Compulsory Disciplines');
    } else if (finalData.penaltyForCompulsoryDisciplines === null) {
      toastr.error('Please enter penalty for Compulsory Disciplines');
    } else if (finalData.isGroupCountBetween24To40 === null) {
      toastr.error('Please select Group Count Between 24 to 40');
    } else if (finalData.groupCount === null) {
      toastr.error('Please enter Group count');
    } else if (finalData.disqualifyTeam === null) {
      toastr.error('Please select disqualify team');
    } else if (finalData.isScriptsLate === null) {
      toastr.error('Please select Scripts Late');
    } else if (finalData.isKapaHakaLate === null) {
      toastr.error('Please select Kapa backstage check-in late');
    } else if ((instrumentsPenaltyList).find(o => o.disciplineId === null)) {
      toastr.error('Please Select disciplines');
    } else if ((instrumentsPenaltyList).find(o => o.instrumentId === null)) {
      toastr.error('Please Select Instruments');
    } else if ((instrumentsPenaltyList).find(o => o.penalty === null)) {
      toastr.error('Please Select Penalty');
    } else {
      setloading(true);
      postData(EventApi.add_Timekepers_ScrutineerPenalty(eventId(), poolId, tId).url, dataCaptured[0])
        .then((response) => {
          toastr.success('Successfully Submitted');
        })
        .catch((e) => {
          toastr.error(e.data.message);
        })
        .finally(() => {
          setloading(false)
          setInstrumentsPenaltyList([]);
          loadTimekepersInstruments();
        });
    }

  };

  const renderDataCaptured = () => {
    if (loading) return <LoadingView message="Loading..." />;
    return (
      <div className="teamDisciplines timekepers moderators">

        <Tabs accordion onChange={onClickTimekeepers}>

          <TabPane tab='Scrutineer' key='scrutineer'>
            {
              averageTTPListStatus === null ? (
                <React.Fragment>

                  <Row>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
                      {
                        (penaltyList || []).map(item => (
                          <React.Fragment key={item.id}>
                            <div>

                              <div className="PenaltiesRow">
                                <div className="PenaltiesRowCol">
                                  <strong>Waiata ā-tira performed</strong>
                                </div>
                                <div className="PenaltiesRowCol">
                                  <Radio.Group
                                    value={item.isWaiataAtiraPerformed}
                                    onChange={e => onChangePenalties({
                                      id: item.id,
                                      isWaiataAtiraPerformed: e.target.value === 'true' ? 'true' : 'false',
                                    })}
                                    disabled={statusCheck === 'false' ? null : 'disabled'}
                                  >
                                    <Radio value='true'>Yes</Radio>
                                    <Radio value='false'>No</Radio>
                                  </Radio.Group>
                                </div>
                              </div>

                              {waiataAtiraPerformedStatusCheck && (
                                <div className="PenaltiesRow">
                                  <div className="PenaltiesRowCol">
                                    <strong>Performed before Whakaeke</strong>
                                  </div>
                                  <div className="PenaltiesRowCol">
                                    <Radio.Group
                                      value={item.isPerformedBeforeWhakaeke}
                                      onChange={e => onChangePenalties({
                                        id: item.id,
                                        isPerformedBeforeWhakaeke: e.target.value === 'true' ? 'true' : 'false',
                                      })}
                                      disabled={statusCheck === 'false' ? null : 'disabled'}
                                    >
                                      <Radio value='true'>Yes</Radio>
                                      <Radio value='false'>No</Radio>
                                    </Radio.Group>
                                  </div>
                                </div>
                              )}

                              <div className="PenaltiesRow">
                                <div className="PenaltiesRowCol">
                                  <strong>Compulsory disciplines performed</strong>
                                </div>
                                <div className="PenaltiesRowCol">
                                  <Radio.Group
                                    value={item.isCompulsoryDisciplinesPerformed}
                                    onChange={e => onChangePenalties({
                                      id: item.id,
                                      isCompulsoryDisciplinesPerformed: e.target.value === 'true' ? 'true' : 'false',
                                    })}
                                    disabled={statusCheck === 'false' ? null : 'disabled'}
                                  >
                                    <Radio value='true'>Yes</Radio>
                                    <Radio value='false'>No</Radio>
                                  </Radio.Group>
                                </div>
                              </div>

                              {waiataAtiraPerformedStatusCheck && (
                                <React.Fragment>
                                  <br />
                                  <p><strong>Waiata ā-tira</strong></p>

                                  <div className="PenaltiesRow">
                                    <div className="PenaltiesRowCol">
                                      <strong>Average time taken</strong>
                                    </div>
                                    <div className="PenaltiesRowCol">
                                      <TimePicker
                                        format="00:mm:ss"
                                        value={item.timeTakenForWaiataAtira ? moment(item.timeTakenForWaiataAtira, 'HH:mm:ss') : null}
                                        onChange={(time, timeString) => onChangePenalties({
                                          id: item.id,
                                          timeTakenForWaiataAtira: timeString,
                                        })}
                                        disabled
                                      /><span className='textInfo'> (MM:SS)</span>
                                    </div>
                                  </div>

                                  <div className="PenaltiesRow">
                                    <div className="PenaltiesRowCol">
                                      <strong>Penalty</strong>
                                    </div>
                                    <div className="PenaltiesRowCol">
                                      <Input
                                        type="number"
                                        min='0'
                                        value={item.penaltyForWaiataAtira}
                                        onChange={e => onChangePenalties({
                                          id: item.id,
                                          penaltyForWaiataAtira: e.target.value,
                                        })}
                                        disabled={statusCheck === 'false' ? null : 'disabled'}
                                      />
                                    </div>
                                  </div>

                                </React.Fragment>
                              )}

                              <br />
                              <p><strong>Compulsory disciplines</strong></p>

                              <div className="PenaltiesRow">
                                <div className="PenaltiesRowCol">
                                  <strong>Average time taken</strong>
                                </div>
                                <div className="PenaltiesRowCol">
                                  <TimePicker
                                    format="00:mm:ss"
                                    value={item.timeTakenForCompulsoryDisciplines ? moment(item.timeTakenForCompulsoryDisciplines, 'HH:mm:ss') : null}
                                    onChange={(time, timeString) => onChangePenalties({
                                      id: item.id,
                                      timeTakenForCompulsoryDisciplines: timeString,
                                    })}
                                    disabled
                                  /><span className='textInfo'> (MM:SS)</span>
                                </div>
                              </div>

                              <div className="PenaltiesRow">
                                <div className="PenaltiesRowCol">
                                  <strong>Penalty</strong>
                                </div>
                                <div className="PenaltiesRowCol">
                                  <Input
                                    type='number'
                                    min='0'
                                    value={item.penaltyForCompulsoryDisciplines}
                                    onChange={e => onChangePenalties({
                                      id: item.id,
                                      penaltyForCompulsoryDisciplines: e.target.value,
                                    })}
                                    disabled={statusCheck === 'false' ? null : 'disabled'}
                                  />
                                </div>
                              </div>

                              <div className="PenaltiesRow">
                                <div className="PenaltiesRowCol">
                                  <strong>{`Group Count 24 < to > 40 `}</strong>
                                </div>
                                <div className="PenaltiesRowCol">
                                  <Radio.Group
                                    value={item.isGroupCountBetween24To40}
                                    onChange={e => onChangePenalties({
                                      id: item.id,
                                      isGroupCountBetween24To40: e.target.value === 'true' ? 'true' : 'false',
                                    })}
                                    disabled={statusCheck === 'false' ? null : 'disabled'}
                                  >
                                    <Radio value='true'>Yes</Radio>
                                    <Radio value='false'>No</Radio>
                                  </Radio.Group>
                                </div>
                              </div>

                              <div className="PenaltiesRow">
                                <div className="PenaltiesRowCol">
                                  <strong>Group Count</strong>
                                </div>
                                <div className="PenaltiesRowCol">
                                  <Input
                                    type="number"
                                    min='0'
                                    value={item.groupCount}
                                    onChange={e => onChangePenalties({
                                      id: item.id,
                                      groupCount: e.target.value,
                                    })}
                                    disabled={statusCheck === 'false' ? null : 'disabled'}
                                  />
                                </div>
                              </div>

                              <div className="PenaltiesRow">
                                <div className="PenaltiesRowCol">
                                  <p><strong>Should team be disqualified</strong></p>
                                </div>
                                <div className="PenaltiesRowCol">
                                  <p>
                                    <Radio.Group
                                      value={item.disqualifyTeam === 'true' ? 'true' : 'false'}
                                      disabled={statusCheck === 'false' ? null : 'disabled'}
                                      onChange={e => onChangePenalties({
                                        id: item.id,
                                        disqualifyTeam: e.target.value === 'true' ? 'true' : 'false',
                                      })}
                                    >
                                      <Radio value='true'>Yes</Radio>
                                      <Radio value='false'>No</Radio>
                                    </Radio.Group>
                                  </p>

                                </div>
                              </div>

                            </div>
                          </React.Fragment>
                        ))
                      }
                    </Col>

                  </Row>
                  <div className="PenaltiesRow">
                    <div className="PenaltiesRowCol">
                      <strong>Instrument violation</strong>
                    </div>
                    <div className="PenaltiesRowCol">
                      <Radio.Group
                        value={instrumentsPenaltyList.length > 0 ? 'true' : 'false'}
                        disabled={statusCheck === 'false' ? null : 'disabled'}
                        onChange={onChangeInstrumentViolation}
                      >
                        <Radio value='true'>Yes</Radio>
                        <Radio value='false'>No</Radio>
                      </Radio.Group>
                    </div>
                  </div>


                  {
                    instrumentViolation === 'true' ? (
                      <React.Fragment>
                        <Row>
                          <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
                            <div className="PenaltiesRow">
                              <div className="PenaltiesRowCol3"><strong>Disciplines</strong></div>
                              <div className="PenaltiesRowCol3"><strong>Instruments</strong></div>
                              <div className="PenaltiesRowCol3"><strong>Penalty</strong></div>
                            </div>
                          </Col>
                          <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
                            {
                              (instrumentsPenaltyList || []).map(item => (
                                <div className="PenaltiesRow InstrumentViolation" key={`IV${item.id}`}>
                                  <div className="PenaltiesRowCol3">
                                    <Select
                                      style={{ width: '100 %' }}
                                      placeholder="Select a Discipline"
                                      defaultValue={item.disciplineId}
                                      onChange={e => onChangeInstrumentsPenalties({
                                        id: item.id,
                                        disciplineId: e,
                                      })}
                                      disabled={statusCheck === 'false' ? null : 'disabled'}
                                    >
                                      {
                                        (disciplinesList.disciplineScores || []).map((item, index) => (
                                          <Option value={item.discipline.id} key={index}>{item.discipline.name}</Option>
                                        ))
                                      }
                                    </Select>
                                  </div>
                                  <div className="PenaltiesRowCol3">
                                    <Select
                                      allowClear
                                      style={{ width: '100 %' }}
                                      placeholder="Select an Instrument"
                                      defaultValue={item.instrumentId}
                                      onChange={e => onChangeInstrumentsPenalties({
                                        id: item.id,
                                        instrumentId: e,
                                      })}
                                      disabled={statusCheck === 'false' ? null : 'disabled'}
                                    >
                                      {
                                        (instrumentsList || []).map((item, index) => (
                                          <Option value={item.id} key={index}>{item.name}</Option>
                                        ))
                                      }
                                    </Select>

                                  </div>
                                  <div className="PenaltiesRowCol3">
                                    <Input
                                      style={{ width: '100 %' }}
                                      type='number'
                                      min='0'
                                      value={item.penalty}
                                      onChange={e => onChangeInstrumentsPenalties({
                                        id: item.id,
                                        penalty: e.target.value,
                                      })}
                                      disabled={statusCheck === 'false' ? null : 'disabled'}
                                    />

                                  </div>
                                  <div className="PenaltiesRowCol3">
                                    {statusCheck === 'true' ? null : (
                                      <span onClick={() => onDeletePenaltiesRow(item.id)} className="CloseBtn"><CloseOutlined /></span>
                                    )}

                                  </div>
                                </div>
                              ))
                            }
                          </Col>
                        </Row>
                        <Row>
                          <Col span={24}>
                            {statusCheck === 'true' ? null : (
                              <Button
                                type="primary"
                                onClick={() => addPenaltiesRow()}
                                className='addPenalties'
                                disabled={statusCheck === 'true' ? 'disabled' : null}
                              >
                                Add Instrument violation
                              </Button>
                            )}

                          </Col>
                        </Row>
                      </React.Fragment>
                    ) : null
                  }
                  {
                    (penaltyList || []).map(item => (
                      <React.Fragment key={item.id}>

                        <div className="PenaltiesRow">
                          <div className="PenaltiesRowCol">
                            <strong>Scripts Late</strong>
                          </div>
                          <div className="PenaltiesRowCol">
                            <Radio.Group
                              value={item.isScriptsLate}
                              onChange={e => onChangePenalties({
                                id: item.id,
                                isScriptsLate: e.target.value === 'true' ? 'true' : 'false',
                              })}
                              disabled={statusCheck === 'false' ? null : 'disabled'}
                            >
                              <Radio value='true'>Yes</Radio>
                              <Radio value='false'>No</Radio>
                            </Radio.Group>
                          </div>
                        </div>

                        <div className="PenaltiesRow">
                          <div className="PenaltiesRowCol">
                            <strong>Kapa backstage check-in late</strong>
                          </div>
                          <div className="PenaltiesRowCol">
                            <Radio.Group
                              value={item.isKapaHakaLate}
                              onChange={e => onChangePenalties({
                                id: item.id,
                                isKapaHakaLate: e.target.value === 'true' ? 'true' : 'false',
                              })}
                              disabled={statusCheck === 'false' ? null : 'disabled'}
                            >
                              <Radio value='true'>Yes</Radio>
                              <Radio value='false'>No</Radio>
                            </Radio.Group>
                          </div>
                        </div>



                        <div className="PenaltiesRow timekeeperComment">
                          <div className="PenaltiesRowCol">
                            <strong>Comments</strong>
                            <TextArea
                              style={{ width: '100%' }}
                              rows={4}
                              placeholder="Type your comment here"
                              value={item.comment === null ? '' : item.comment}
                              onChange={e => onChangePenalties({
                                valueType: commentTextType,
                                id: item.id,
                                comment: e.target.value,
                              })}
                              disabled={statusCheck === 'false' ? null : 'disabled'}
                            />
                          </div>
                        </div>

                      </React.Fragment>
                    ))
                  }

                  {
                    statusCheck === 'false' ? (
                      <Row>
                        <Col span={24}>
                          <div className="timekepersFooter">
                            <Popconfirm
                              title="Are you sure?"
                              onConfirm={() => updateTimekeper()}
                            >
                              <Button type="primary">Submit</Button>
                            </Popconfirm>
                          </div>
                        </Col>
                      </Row>
                    ) : null
                  }
                </React.Fragment>
              ) : <p><strong>{averageTTPListStatus}</strong></p>
            }
          </TabPane>
          {
            (timekeepersList || []).map(tkl => (
              <TabPane tab={tkl.name} key={tkl.id}>
                <React.Fragment>
                  {timekeepersDataStatus === null ? (
                    <h3>Pending...</h3>
                  ) :
                    (
                      <React.Fragment>
                        <Row>
                          <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
                            {
                              (timekeepersPenaltyList || []).map(item => (
                                <React.Fragment key={item.id}>
                                  <div>
                                    <div className="PenaltiesRow">
                                      <div className="PenaltiesRowCol">
                                        <strong>Waiata ā-tira performed</strong>
                                      </div>
                                      <div className="PenaltiesRowCol">
                                        <Radio.Group
                                          value={item.isWaiataAtiraPerformed}
                                          disabled
                                        >
                                          <Radio value='true'>Yes</Radio>
                                          <Radio value='false'>No</Radio>
                                        </Radio.Group>
                                      </div>
                                    </div>

                                    {timekeepersPerformedStatus && (
                                      <div className="PenaltiesRow">
                                        <div className="PenaltiesRowCol">
                                          <strong>Performed before Whakaeke</strong>
                                        </div>
                                        <div className="PenaltiesRowCol">
                                          <Radio.Group
                                            value={item.isPerformedBeforeWhakaeke}
                                            disabled
                                          >
                                            <Radio value='true'>Yes</Radio>
                                            <Radio value='false'>No</Radio>
                                          </Radio.Group>
                                        </div>
                                      </div>
                                    )}

                                    <div className="PenaltiesRow">
                                      <div className="PenaltiesRowCol">
                                        <strong>Compulsory disciplines performed</strong>
                                      </div>
                                      <div className="PenaltiesRowCol">
                                        <Radio.Group
                                          value={item.isCompulsoryDisciplinesPerformed}
                                          disabled
                                        >
                                          <Radio value='true'>Yes</Radio>
                                          <Radio value='false'>No</Radio>
                                        </Radio.Group>
                                      </div>
                                    </div>

                                    {timekeepersPerformedStatus && (
                                      <React.Fragment>
                                        <br />
                                        <p><strong>Waiata ā-tira</strong></p>

                                        <div className="PenaltiesRow">
                                          <div className="PenaltiesRowCol">
                                            <strong>Total time taken</strong>
                                          </div>
                                          <div className="PenaltiesRowCol">
                                            <TimePicker
                                              format="00:mm:ss"
                                              value={item.timeTakenForWaiataAtira ? moment(item.timeTakenForWaiataAtira, 'HH:mm:ss') : null}
                                              disabled
                                            /><span className='textInfo'> (MM:SS)</span>
                                          </div>
                                        </div>

                                        <div className="PenaltiesRow">
                                          <div className="PenaltiesRowCol">
                                            <strong>Penalty</strong>
                                          </div>
                                          <div className="PenaltiesRowCol">
                                            <Input
                                              type="number"
                                              min='0'
                                              value={item.penaltyForWaiataAtira}
                                              disabled
                                            />
                                          </div>
                                        </div>

                                      </React.Fragment>
                                    )}

                                    <br />
                                    <p><strong>Compulsory disciplines</strong></p>

                                    <div className="PenaltiesRow">
                                      <div className="PenaltiesRowCol">
                                        <strong>Total time taken</strong>
                                      </div>
                                      <div className="PenaltiesRowCol">
                                        <TimePicker
                                          format="00:mm:ss"
                                          value={item.timeTakenForCompulsoryDisciplines ? moment(item.timeTakenForCompulsoryDisciplines, 'HH:mm:ss') : null}
                                          disabled
                                        /><span className='textInfo'> (MM:SS)</span>
                                      </div>
                                    </div>

                                    <div className="PenaltiesRow">
                                      <div className="PenaltiesRowCol">
                                        <strong>Penalty</strong>
                                      </div>
                                      <div className="PenaltiesRowCol">
                                        <Input
                                          type='number'
                                          min='0'
                                          value={item.penaltyForCompulsoryDisciplines}
                                          disabled
                                        />
                                      </div>
                                    </div>

                                    <br />

                                    <div className="PenaltiesRow">
                                      <div className="PenaltiesRowCol">
                                        <strong>Group Count</strong>
                                      </div>
                                      <div className="PenaltiesRowCol">
                                        <Input
                                          type="number"
                                          min='0'
                                          value={item.groupCount}
                                          disabled
                                        />
                                      </div>
                                    </div>

                                    <div className="PenaltiesRow">
                                      <div className="PenaltiesRowCol">
                                        <p><strong>Should team be disqualified</strong></p>
                                      </div>
                                      <div className="PenaltiesRowCol">
                                        <p>
                                          <Radio.Group
                                            value={item.disqualifyTeam === 'true' ? 'true' : 'false'}
                                            disabled
                                          >
                                            <Radio value='true'>Yes</Radio>
                                            <Radio value='false'>No</Radio>
                                          </Radio.Group>
                                        </p>

                                      </div>
                                    </div>

                                  </div>
                                </React.Fragment>
                              ))
                            }
                          </Col>

                        </Row>
                        <div className="PenaltiesRow">
                          <div className="PenaltiesRowCol">
                            <strong>Instrument violation</strong>
                          </div>
                          <div className="PenaltiesRowCol">
                            <Radio.Group
                              value={timekeepersInstrumentsPenaltyList.length > 0 ? 'true' : 'false'}
                              disabled
                            >
                              <Radio value='true'>Yes</Radio>
                              <Radio value='false'>No</Radio>
                            </Radio.Group>
                          </div>
                        </div>
                        <br />
                        {
                          timekeepersInstrumentViolation === 'true' ? (
                            <React.Fragment>
                              <Row>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
                                  <div className="PenaltiesRow">
                                    <div className="PenaltiesRowCol3"><strong>Disciplines</strong></div>
                                    <div className="PenaltiesRowCol3"><strong>Instruments</strong></div>
                                    <div className="PenaltiesRowCol3"><strong>Penalty</strong></div>
                                  </div>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
                                  {
                                    (timekeepersInstrumentsPenaltyList || []).map(item => (
                                      <div className="PenaltiesRow InstrumentViolation" key={`IV${item.id}`}>
                                        <div className="PenaltiesRowCol3">
                                          <Select
                                            style={{ width: '100 %' }}
                                            placeholder="Select a Discipline"
                                            defaultValue={item.disciplineId}
                                            disabled
                                          >
                                            {
                                              (disciplinesList.disciplineScores || []).map((item, index) => (
                                                <Option value={item.discipline.id} key={index}>{item.discipline.name}</Option>
                                              ))
                                            }
                                          </Select>
                                        </div>
                                        <div className="PenaltiesRowCol3">
                                          <Select
                                            allowClear
                                            style={{ width: '100 %' }}
                                            placeholder="Select an Instrument"
                                            defaultValue={item.instrumentId}
                                            disabled
                                          >
                                            {
                                              (instrumentsList || []).map((item, index) => (
                                                <Option value={item.id} key={index}>{item.name}</Option>
                                              ))
                                            }
                                          </Select>

                                        </div>
                                        <div className="PenaltiesRowCol3">
                                          <Input
                                            style={{ width: '100 %' }}
                                            type='number'
                                            min='0'
                                            value={item.penalty}
                                            disabled
                                          />
                                        </div>
                                      </div>
                                    ))
                                  }
                                </Col>
                              </Row>
                            </React.Fragment>
                          ) : null
                        }
                        {
                          (timekeepersPenaltyList || []).map(item => (
                            <React.Fragment key={item.id}>

                              <div className="PenaltiesRow timekeeperComment">
                                <div className="PenaltiesRowCol">
                                  <strong>Comments</strong>
                                  <TextArea
                                    style={{ width: '100%' }}
                                    rows={4}
                                    value={item.comment}
                                    disabled
                                  />
                                </div>
                              </div>
                            </React.Fragment>
                          ))
                        }
                      </React.Fragment>
                    )}
                </React.Fragment>
              </TabPane>
            ))
          }

        </Tabs>

      </div>
    );
  };

  const renderBreadcrumb = () => (
    <Breadcrumb>
      <Breadcrumb.Item href="/">
        {eventName}
      </Breadcrumb.Item>
      <Breadcrumb.Item onClick={() => history.goBack()}>
        <span>{teamName}</span>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        <span>Scrutineer</span>
      </Breadcrumb.Item>
    </Breadcrumb>
  );

  return (
    <React.Fragment>
      {renderBreadcrumb()}
      <div className="EventTeams">
        {renderDataCaptured()}
      </div>
    </React.Fragment>

  )
}

export default EventTimekeperScrutineer
