
import React, { useState, useEffect } from 'react';
import axios from "axios";
import { LoadingView } from '../common';
import PageLayout from "../layout";
import { admin_Status, login_token } from '../../_services/auth';
import toastr from 'toastr';
import { UsersApi } from '../../api';
import { getData, putData } from '../../_services/apiCommonService';
import { Row, Col, Modal, Button, Input, Form, Checkbox, Radio, Table } from 'antd';
import './UsersInfo.scss';
import { EditFilled } from "@ant-design/icons";

const UserInfo = () => {
  if (admin_Status() === 'false') {
    window.location.href = '/login';
  }

  const [loading, setloading] = useState(true);
  const [activeUsersList, setActiveUsersList] = useState([]);
  const [inAcitveUsersList, setInAcitveUsersList] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [users, setUsers] = useState(null);
  const [activeUsers, setActiveUsers] = useState('acitve');
  const [activePage, setActivePage] = useState(1);
  const [form] = Form.useForm();
  const [picture, setPicture] = useState(null);
  const [imgData, setImgData] = useState(null);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: (a, b) => a.name.length - b.name.length,
      render: (record, val) => {
        return (
          <span
            className="nameLink"
            onClick={(e) => showEditUserModal(record, val)}
          >
            {record}
          </span>
        )
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      render: (record, val) => {
        return (
          <span>
            <EditFilled onClick={(e) => showEditEmailModal(record, val)} style={{ marginRight: "10px" }} />
            {record}
          </span>
        )
      },
    },
    {
      title: 'Admin',
      dataIndex: 'isAdmin',
      filters: [
        {
          text: 'Yes',
          value: `${true}`,
        },
        {
          text: 'No',
          value: `${false}`,
        },
      ],
      onFilter: (value, record) => record.isAdmin.indexOf(value) === 0,
      render: (record) => {
        return (
          `${record === 'true' ? 'Yes' : 'No'}`
        )
      },
    },
  ];

  const fetchAllAcitveUsers = () => {
    getData(UsersApi.getActiveUsers().url)
      .then((response) => {
        const data = response.data.users;
        setActiveUsersList(
          data.map(user => ({
            key: user.id,
            id: user.id,
            name: user.name,
            email: user.email,
            isAdmin: `${user.isAdmin}`,
            isActive: `${user.isActive}`,
          }))
        );
      })
      .catch((e) => {
        toastr.error(e.data.message);
      })
      .finally(() => {
        setloading(false)
      });
  };

  const fetchAllInAcitveUsers = () => {
    getData(UsersApi.getInActiveUsers().url)
      .then((response) => {
        const data = response.data.users;
        setInAcitveUsersList(
          data.map(user => ({
            key: user.id,
            id: user.id,
            name: user.name,
            email: user.email,
            isAdmin: `${user.isAdmin}`,
            isActive: `${user.isActive}`,
          }))
        );
      })
      .catch((e) => {
        toastr.error(e.data.message);
      })
      .finally(() => {
        setloading(false)
      });
  };

  useEffect(() => {
    setActiveUsers('acitve');
    fetchAllAcitveUsers();
  }, [users]);

  /***************************Start Add User*****************************/
  const OpenCreateUserModal = () => {
    setIsModalVisible(true);
    setPicture(null);
    setImgData(null);
  };

  const CloseCreateUserModal = () => {
    setIsModalVisible(false);
    setPicture(null);
    setImgData(null);
    form.resetFields();
  };

  const onChangeUserStatus = (e) => {
    setActiveUsers(e.target.value);

    if (e.target.value === 'acitve') {
      fetchAllAcitveUsers();
    }
    else {
      fetchAllInAcitveUsers();
    }
  }

  const onChangePicture = e => {
    setPicture(null);
    if (e.target.files[0]) {
      setPicture(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImgData(reader.result);
        setSelectedSignature(null)
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const addNewData = async (values) => {

    setloading(true);

    Object.assign(values, { signature: picture });
    const formData = new FormData()
    Object.keys(values).forEach((key) => {
      formData.append(key, values[key])
    })

    const headers = {
      'Authorization': `Bearer ${login_token()}`,
      'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
    };

    axios.post(process.env.REACT_APP_API_BASE_URL + '/users', formData, { headers })
      .then(response => {
        setUsers(response.data);
        toastr.success('Successfully Submitted');
        setIsModalVisible(false);
      })
      .catch(e => {
        toastr.error(e.data.message);
      })
      .finally(() => {
        setloading(false);
        form.resetFields();
      })
  };

  const renderModalContent = () => {
    return (
      <div className="CreateUser">
        <Row>
          <Col sm={24}>
            <Form
              form={form}
              layout="vertical"
              name="form_in_modal"
              initialValues={{ isAdmin: false, }}
            >
              <Form.Item
                name="name"
                label="Name"
                rules={[{ required: true, message: 'Please enter name' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name='email'
                label="Email"
                rules={[{ type: 'email', required: true, message: 'Please enter valid or correct email' }]}>
                <Input />
              </Form.Item>
              <Form.Item name="isAdmin" valuePropName="checked" label=''>
                <Checkbox>User is System Admin</Checkbox>
              </Form.Item>
            </Form>
            <p><strong>Signature</strong></p>
            <div className="signatureBox">
              {imgData !== null && (
                <div className="signatureBoxDisplayProfile">
                  <img alt="text" src={imgData} />
                </div>
              )}
              <div className="signatureBoxProfile">
                <label for="files" class="btn">Choose file</label>
                <input type="file" id="files" onChange={onChangePicture} />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  /***************************End Add User********************************/
  /***************************Start Edit User*****************************/

  const [selectedUserModal, setSelectedUserModal] = useState(false);
  const [userID, setUserID] = useState(null);
  const [selectedUserName, setSelectedUserName] = useState('');
  const [selectedUserEmail, setSelectedUserEmail] = useState('');
  const [selectedUserAdminStatus, setSelectedUserAdminStatus] = useState(null);
  const [selectedActiveUsers, setSelectedActiveUsers] = useState(null);
  const [selectedSignature, setSelectedSignature] = useState(null);
  const [editEmailModal, setEditEmailModal] = useState(false);

  const showEditUserModal = (record, data) => {
    setSelectedUserModal(true);
    setUserID(data.id);

    getData(UsersApi.getUsersById(data.id).url)
      .then((response) => {
        const data = response.data.user;
        setPicture(null);
        setImgData(null);
        setSelectedUserName(data.name);
        setSelectedUserEmail(data.email);
        setSelectedActiveUsers(`${data.isActive}`);
        setSelectedUserAdminStatus(`${data.isAdmin}`);
        setSelectedSignature(data.signature);
      })
      .catch((e) => {
        toastr.error(e);
      })
      .finally(() => {
        setloading(false)
      });
  };

  const closeEditUserDataModal = () => {
    setSelectedUserModal(false);
    setSelectedUserName('');
    setSelectedUserEmail('');
    setSelectedUserAdminStatus(null);
    setSelectedActiveUsers(null);
  };

  const onChangeEditUserName = (e) => {
    setSelectedUserName(e.target.value);
  };

  const onChangeEditUserEmail = (e) => {
    setSelectedUserEmail(e.target.value);
  };

  const onChangedEditUserIsSystemAdmin = (e) => {
    setSelectedUserAdminStatus(`${e.target.checked}`);
  };

  const onChangedEditUserStatus = (e) => {
    setSelectedActiveUsers(`${e.target.value}`);
  };

  const UpdateEditDataModal = () => {
    if (selectedUserName === '') {
      toastr.error('Please enter name');
    } else {
      const values = {
        name: selectedUserName,
        isAdmin: selectedUserAdminStatus,
        isActive: selectedActiveUsers,
        signature: picture
      }
      setloading(true);

      const formData = new FormData()
      Object.keys(values).forEach((key) => {
        formData.append(key, values[key])
      })

      const headers = {
        'Authorization': `Bearer ${login_token()}`,
        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
      };

      axios.put(process.env.REACT_APP_API_BASE_URL + `/users/${userID}`, formData, { headers })
        .then(response => {
          setUsers(response.data);
          toastr.success('Successfully Submitted');
          setIsModalVisible(false);
        })
        .catch(e => {
          toastr.error(e);
        })
        .finally(() => {
          setloading(false);
          setSelectedUserModal(false);
          setSelectedUserName('');
          setSelectedUserEmail('');
          setSelectedUserAdminStatus(null);
          setSelectedActiveUsers(null);
        })
    }
  };

  const renderEditUserModal = () => {
    return (
      <div className="CreateUser">
        <Row>
          <Col sm={24}>
            <p><span className="redMandatory">*</span><strong>Name</strong></p>
            <Input
              value={selectedUserName}
              onChange={onChangeEditUserName}
            />
            <p><span className="redMandatory">*</span><strong>Email</strong></p>
            <Input
              disabled
              value={selectedUserEmail}
            />
            <p><br /><strong>User Status</strong></p>
            <Radio.Group onChange={onChangedEditUserStatus} value={selectedActiveUsers === 'true' ? 'true' : 'false'}>
              <Radio value='true'>Active</Radio>
              <Radio value='false'>Inactive</Radio>
            </Radio.Group>
            <p>
              <br />
              <Checkbox
                checked={selectedUserAdminStatus === 'true' ? true : false}
                onChange={onChangedEditUserIsSystemAdmin}
              >
                <strong>User is System Admin</strong>
              </Checkbox>
            </p>
            <div className="signatureBox">
              <p><strong>Signature</strong></p>
              {imgData !== null && (
                <div className="signatureBoxDisplayProfile">
                  <img alt="text" src={imgData} />
                </div>
              )}
              {selectedSignature !== null && (
                <div className="signatureBoxDisplayProfile">
                  <img src={`data:image/jpeg;base64,${selectedSignature}`} alt="Signature" />
                </div>
              )}
              <div className="signatureBoxProfile">
                <label for="files" class="btn">Choose file </label>
                <input type="file" onChange={onChangePicture} />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  const showEditEmailModal = (record, data) => {
    setEditEmailModal(true);
    setSelectedUserEmail(data.email);
    setUserID(data.id);
  }

  const closeEditEmailModal = () => {
    setEditEmailModal(false);
    setSelectedUserEmail('');
    setUserID(null);
  };

  const UpdateEditEmailModal = () => {
    if (selectedUserEmail === '') {
      toastr.error('Please enter email');
      return;
    }

    const encoded = encodeURIComponent(selectedUserEmail);
    console.log('encoded', encoded);

    putData(UsersApi.updateUserEmail(userID, encoded).url)
      .then(response => {
        setUsers(response.data);
        toastr.success('Successfully Submitted');
        setIsModalVisible(false);
      })
      .catch(e => {
        toastr.error(e.data.message);
      })
      .finally(() => {
        closeEditEmailModal();
      })
  };

  const renderEditEmailModal = () => {
    return (
      <div className="CreateUser">
        <Row>
          <Col sm={24}>
            <p><span className="redMandatory">*</span><strong>Email</strong></p>
            <Input
              value={selectedUserEmail}
              onChange={onChangeEditUserEmail}
            />
          </Col>
        </Row>
      </div>
    )
  }

  /***************************End Edit User*****************************/
  const handlePageChange = value => {
    setActivePage(value.current);
  }

  const renderUsersInfoContent = () => {
    if (loading) return <LoadingView message="Loading..." />;
    return (
      <div className="site-card-wrapper">
        <Row gutter={16}>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
            <Button type="primary" onClick={OpenCreateUserModal}>
              Add User
            </Button>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
            <div className="userActiveRow">
              <Radio.Group defaultValue="acitve" buttonStyle="solid" onChange={onChangeUserStatus}>
                <Radio.Button value="acitve">Active Users</Radio.Button>
                <Radio.Button value="inacitve">Inactive Users</Radio.Button>
              </Radio.Group>
            </div>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
            {activeUsers === 'acitve' ? (
              <Table
                columns={columns}
                dataSource={activeUsersList}
                pagination={{ current: activePage }}
                onChange={handlePageChange}
              />
            ) : (
              <Table
                columns={columns}
                dataSource={inAcitveUsersList}
                pagination={{ current: activePage }}
                onChange={handlePageChange}
              />
            )
            }
          </Col>
        </Row>
      </div>
    );
  };
  return (
    <PageLayout>
      <div className="UsersInfo ">
        {renderUsersInfoContent()}
      </div>
      <Modal
        title="Add User"
        okText="Add User"
        visible={isModalVisible}
        onCancel={CloseCreateUserModal}
        cancelText="Cancel"
        onOk={() => {
          form
            .validateFields()
            .then(values => {
              form.resetFields();
              addNewData(values);
            })
            .catch(info => {
              console.log('Validate Failed:', info);
            });
        }}
      >
        {renderModalContent()}
      </Modal>
      <Modal
        title='Edit User'
        visible={selectedUserModal}
        okText="Update"
        onOk={UpdateEditDataModal}
        cancelText="Cancel"
        onCancel={closeEditUserDataModal}>
        {renderEditUserModal()}
      </Modal>
      <Modal
        title='Edit Email'
        visible={editEmailModal}
        okText="Update"
        onOk={UpdateEditEmailModal}
        cancelText="Cancel"
        onCancel={closeEditEmailModal}
      >
        {renderEditEmailModal()}
      </Modal>
    </PageLayout>
  );
};

export default UserInfo;
