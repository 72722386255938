import React, { useState, useEffect } from 'react';
import { useParams, Link, useLocation } from "react-router-dom";
import { LoadingView } from '../../common';

import toastr from 'toastr';
import { EventApi } from '../../../api';
import { getData } from '../../../_services/apiCommonService';
import { Row, Col, Table, Breadcrumb, Collapse } from 'antd';
import '../../Judges/Event/EventTeams.scss';

const EventPoolTeams = (props) => {
  const { eId } = useParams();
  const location = useLocation();

  let index = (location.state.userEvents).map((o) => o.event.id).indexOf(Number(eId));
  let eventName = location.state.userEvents[index].event.name

  if (location.state === undefined) {
    window.location.href = '/';
  }

  const [loading, setloading] = useState(true);
  const [eventTeamsPoolList, setEventTeamsPoolList] = useState([]);
  const [eventFinalTeamsPoolList, setEventFinalTeamsPoolList] = useState([]);

  const { Panel } = Collapse;
  const columns1 = [
    {
      title: 'Teams',
      dataIndex: 'teamName',
      width: '70%',
      render: (record, data) => {
        return (

          <Link
            to={{
              pathname: `/event/team/${data.teamId}`,
              state: {
                eventName: eventName,
                poolId: data.poolId,
                poolName: data.poolName,
                teamId: data.teamId,
                teamName: data.teamName,

              }
            }}
          >
            {record}
          </Link>
        )
      },
    },
    {
      title: 'Pools',
      dataIndex: 'poolName',
      width: '30%',
      className: "poolName",
      render: (record, data) => {
        return (

          <Link
            to={{
              pathname: `/event/team/${data.teamId}`,
              state: {
                eventName: eventName,
                poolId: data.poolId,
                poolName: data.poolName,
                teamId: data.teamId,
                teamName: data.teamName,
              }
            }}
          >
            {record}
          </Link>
        )
      },
    },
  ];

  const loadAllEventTeamsPool = () => {
    setloading(true);
    getData(EventApi.getEventTeamsPoolDetails(eId).url)
      .then((response) => {
        const dataRegularTeams = response.data.regularTeams;
        const dataFinalTeams = response.data.finalTeams;
        setEventTeamsPoolList(dataRegularTeams);
        setEventFinalTeamsPoolList(dataFinalTeams);
      })
      .catch((e) => {
        toastr.error(e.data.message);
      })
      .finally(() => {
        setloading(false)
      });

  };

  useEffect(() => {
    if (eId) {
      loadAllEventTeamsPool();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eId]);

  const renderEventsInfoContent = () => {
    if (loading) return <LoadingView message="Loading..." />;
    return (
      <Row gutter={16}>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
          <Table
            columns={columns1}
            dataSource={eventTeamsPoolList}
            rowKey={record => record.id}
          />
        </Col>
      </Row>
    );
  };

  const renderEventsFinalTeams = () => {
    if (loading) return <LoadingView message="Loading..." />;
    return (
      <Row gutter={16}>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
          <Table
            columns={columns1}
            dataSource={eventFinalTeamsPoolList}
            rowKey={record => record.id}
          />
        </Col>
      </Row>
    );
  };

  const renderBreadcrumb = () => (
    <Breadcrumb>
      <Breadcrumb.Item href="/">
        <span>{eventName}</span>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        <span>Teams</span>
      </Breadcrumb.Item>
    </Breadcrumb>
  );


  return (
    <React.Fragment>
      {renderBreadcrumb()}
      <div className="EventTeams">
        {
          <div className="teamDisciplines moderators eventTeamsPoolList">
            <Collapse accordion>
              <Panel header='Initial Teams' key='initialTeams'>
                {renderEventsInfoContent()}
              </Panel>
              <Panel header='Final Teams' key='finalTeams'>
                {renderEventsFinalTeams()}
              </Panel>
            </Collapse>

          </div>
        }

      </div>
    </React.Fragment>
  )
}

export default EventPoolTeams
