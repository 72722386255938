import { authHeader } from '../_helpers';
import { setToken, setAdminStatus } from '../_services/auth';
import jwt_decode from "jwt-decode";
export const userService = {
    login,
    logout,
    getAll
};

function login(email, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password })
    };

    return fetch(`${process.env.REACT_APP_API_BASE_URL}/auth/token`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // login successful if there's a user in the response

            if (user) {
                // store user details and basic auth credentials in local storage 
                // to keep user logged in between page refreshes
                user.authdata = window.btoa(email + ':' + password);
                localStorage.setItem('user', JSON.stringify(user));
                setToken(user.accessToken);

                const decoded = jwt_decode(user.accessToken);
                setAdminStatus(decoded.isAdmin)
            }
            // USER_LOGIN_LOCALSTORAGE_TOKEN_KEY
            return user;
        });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
    localStorage.removeItem('userName');
    // localStorage.removeItem('AUTH_TOKEN');
    // localStorage.removeItem('ADMIN_STATUS');
    localStorage.removeItem("userRole");
}


function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`/users`, requestOptions).then(handleResponse);

}

function handleResponse(response) {

    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                // window.location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
