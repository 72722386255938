import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { LoadingView } from '../common';
import PageLayout from "../layout"
import toastr from 'toastr';
import { HomeApi } from '../../api';
import { getData } from '../../_services/apiCommonService';

import { setAdminRole, setEventId } from '../../_services/auth';
import { Row, Col, Radio, Table, } from 'antd';
import './HomePage.scss';
import '../events/EventInfo.scss';
const HomePage = (props) => {
    const [userEventInfo, setUserEventInfo] = useState(null);
    const [loading, setloading] = useState(true);
    const [activeUsers, setInActiveUsers] = useState('acitve');
    const [activeEventsList, setActiveEventsList] = useState([]);
    const [inActiveEventsList, setInActiveEventsList] = useState([]);


    const columns = [
        {
            title: 'Event',
            dataIndex: 'event',
            key: 'event',
            width: '85%',
            render: record => {
                return (
                    <div>
                        <Link
                            className="btn btn-primary"
                            to={{
                                pathname: `/user/events/${record.id}`,
                                state: userEventInfo
                            }}
                        >
                            {record.name}
                        </Link>
                    </div>

                )
            },
        },
        {
            title: 'Role',
            dataIndex: 'role',
            key: 'event',
            width: '15%',
            render: role => {
                return (
                    <span>{role.name === 'Head_Judge' ? 'Head Judge' : role.name}</span>
                )
            },
        },
    ];

    const columns2 = [
        {
            title: 'Event',
            dataIndex: 'event',
            key: 'event',
            width: '85%',
            render: record => {
                return (
                    <div>
                        <Link
                            className="btn btn-primary">
                            {record.name}
                        </Link>
                    </div>

                )
            },
        },
        {
            title: 'Role',
            dataIndex: 'role',
            key: 'event',
            width: '15%',
            render: role => {
                return (
                    <span>{role.name === 'Head_Judge' ? 'Head Judge' : role.name}</span>
                )
            },
        },
    ];

    const fetchAllActiveEvents = async () => {
        setloading(true);
        getData(HomeApi.userGetStartEvent().url)
            .then((response) => {
                setUserEventInfo(response.data);
                const data = response.data.userEvents;
                setActiveEventsList(
                    data.map(ev => ({
                        event: {
                            key: ev.event.id,
                            id: ev.event.id,
                            name: ev.event.name,
                            startDate: ev.event.startDate,
                            endDate: ev.event.endDate,
                            isActive: `${ev.event.isActive}`,
                            status: ev.event.status,
                        },
                        role: {
                            id: ev.role.id,
                            name: ev.role.name,
                        }
                    }))
                );
                localStorage.setItem('userRole', data && data[0] && data[0].role.name);
            })
            .catch((err) => {
                toastr.error(err);
            })
            .finally(() => {
                setloading(false)
            });
    };

    useEffect(() => {
        fetchAllActiveEvents();
    }, []);

    const onChangeUserStatus = (e) => {
        setInActiveUsers(e.target.value);
        if (e.target.value === 'acitve') {
            getData(HomeApi.userGetStartEvent().url)
                .then((response) => {
                    setUserEventInfo(response.data);
                    const data = response.data.userEvents;
                    setActiveEventsList(
                        data.map(ev => ({
                            event: {
                                key: ev.event.id,
                                id: ev.event.id,
                                name: ev.event.name,
                                startDate: ev.event.startDate,
                                endDate: ev.event.endDate,
                                isActive: `${ev.event.isActive}`,
                                status: ev.event.status,
                            },
                            role: {
                                id: ev.role.id,
                                name: ev.role.name,
                            }
                        }))
                    );
                })
                .catch((err) => {
                    toastr.error(err);
                })
        } else {
            getData(HomeApi.userGetCompleteEvent().url)
                .then((response) => {
                    const data = response.data.userEvents;
                    setInActiveEventsList(
                        data.map(ev => ({
                            event: {
                                key: ev.event.id,
                                id: ev.event.id,
                                name: ev.event.name,
                                startDate: ev.event.startDate,
                                endDate: ev.event.endDate,
                                isActive: `${ev.event.isActive}`,
                                status: ev.event.status
                            },
                            role: {
                                id: ev.role.id,
                                name: ev.role.name,
                            }
                        }))
                    );
                })
                .catch((err) => {
                    toastr.error(err);
                })
        }
    }

    const renderEventsInfoContent = () => {
        if (loading) return <LoadingView message="Loading..." />;
        return (
            <div className="site-card-wrapper">
                <Row gutter={16}>
                    <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                        <div className="eventActiveRow">
                            <Radio.Group defaultValue="acitve" buttonStyle="solid" onChange={onChangeUserStatus}>
                                <Radio.Button value="acitve">Active Events</Radio.Button>
                                <Radio.Button value="inacitve">Completed Events</Radio.Button>
                            </Radio.Group>
                        </div>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>

                        {
                            activeUsers === 'acitve' ? (
                                <Table
                                    columns={columns}
                                    dataSource={activeEventsList}
                                    rowKey={record => record.event.id}
                                    onRow={(record) => ({
                                        onClick: () => {
                                            setAdminRole(record.role.name);
                                            setEventId(record.event.id);
                                        }
                                    })}
                                />
                            ) : (
                                <Table
                                    columns={columns2}
                                    dataSource={inActiveEventsList}
                                    rowKey={record => record.event.id}
                                    onRow={(record) => ({
                                        onClick: () => {
                                            setAdminRole(record.role.name);
                                            setEventId(record.event.id);
                                        }
                                    })}
                                />
                            )
                        }
                    </Col>
                </Row>
            </div>
        );
    };

    return (
        <PageLayout>
            <div className="homePage">
                <div className="eventsInfo">
                    <h4>Events</h4>
                    {renderEventsInfoContent()}
                </div>
            </div>
        </PageLayout>
    );
};

export default HomePage;
