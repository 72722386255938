import React from 'react';
import { Link } from 'react-router-dom';
import { Menu, Button, Badge } from 'antd';
import {
  MenuOutlined
} from '@ant-design/icons';

import './MainMenu.scss';
import { admin_Status } from '../../../_services/auth';


import toastr from 'toastr';
import { EventApi } from '../../../api';
import { getData } from '../../../_services/apiCommonService';

const { SubMenu } = Menu;

class MainMenu extends React.Component {

  state = {
    current: 'Home',
    mode: 'horizontal',
    isExpanded: false,
    isAdmin: admin_Status(),
    userId: localStorage.getItem("userId"),
    notifications: [],
  };

  componentDidMount() {
      getData(EventApi.getUserDetails().url)
        .then((response) => {
          this.setState({ userName: response.data.user.name });
          localStorage.setItem('userName', response.data.user.name);
          if (!response.data.user.isAdmin) this.getAllNotification(response.data.user.id);
          localStorage.setItem("userId", response.data.user.id)
        })
        .catch((err) => {
          toastr.error(err);
        })

        document.addEventListener("update-notifications", this.updateNotifications);
  }

  componentWillUnmount() {
    document.removeEventListener("update-notifications", this.updateNotifications);
  }

  updateNotifications = () => {
    this.getAllNotification(this.state.userId);
  }

  getAllNotification = (userID) => {
    getData(EventApi.getNotifications(userID).url)
      .then((response) => {
        this.setState({ notifications: response.data.userNotifications });
      })
      .catch((err) => {
        toastr.error(err);
      })
  }

  handleClick = e => {
    this.setState({ current: e.key });
  };

  handleToggle(e) {
    e.preventDefault();
    this.setState({
      isExpanded: !this.state.isExpanded
    });
  }

  render() {
    const { current, isExpanded, isAdmin } = this.state;
    return (
      <div className="MainMenu">
        <Button
          type="primary"
          onClick={e => this.handleToggle(e)}
          className="toggleBtn"
        >
          <MenuOutlined />
        </Button>
        <Menu onClick={this.handleClick} selectedKeys={[current]} mode={`${isExpanded ? "vertical" : "horizontal"}`}>
          <Menu.Item key="home">
            <Link to="/">Home</Link>
          </Menu.Item>
          {isAdmin === 'true' ? (
            <React.Fragment>
              <Menu.Item key="users">
                <Link to="../../users">Users</Link>
              </Menu.Item>
              <Menu.Item key="teams">
                <Link to="../../teams">Teams</Link>
              </Menu.Item>
              <Menu.Item key="discipline">
                <Link to="../../discipline">Disciplines</Link>
              </Menu.Item>
            </React.Fragment>
          ) : null
          }
          <Menu.Item key="comments">
            <Link to="../../comments">Comment</Link>
          </Menu.Item>
          {isAdmin === 'true' ? (
            <React.Fragment>
              <Menu.Item key="events">
                <Link to="../../events">Event</Link>
              </Menu.Item>
            </React.Fragment>
          ) : null
          }
          <SubMenu key="SubMenu" title="Profile" className="matatiniSubMenu">
            <Menu.ItemGroup title={localStorage.getItem('userName')}>
              <Menu.Item key="resetPassword">
                <Link to="/resetPassword">Reset Password</Link>
              </Menu.Item>
              <Menu.Item key="login">
                <Link to="/login">Logout</Link>
              </Menu.Item>
            </Menu.ItemGroup>

          </SubMenu>
          {
            isAdmin !== 'true' && localStorage.getItem("userRole") === 'Judge' && (
              <Menu.Item key="My Actions">
                <Badge
                  count={this.state.notifications.length}
                  showZero 
                >
                  <Link to="/my-actions">My Actions</Link>
                </Badge>
              </Menu.Item>
            )
          }
        </Menu>
      </div>

    );
  }
}
export default MainMenu;
