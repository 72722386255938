import { useState, useEffect, useRef } from "react";
import { useLocation, useHistory } from "react-router-dom";
import {
  Row,
  Col,
  InputNumber,
  Input,
  Checkbox,
  Button,
  Popconfirm,
  Breadcrumb,
} from "antd";
import PageLayout from "../../layout";
import { EventApi } from "../../../api";
import { getData, postData } from "../../../_services/apiCommonService";
import toastr from "toastr";
import "./Action.scss";

const { TextArea } = Input;

function Action(props) {
  const location = useLocation();
  const history = useHistory();
  // eslint-disable-next-line no-unused-vars
  const [notification, setNotification] = useState(
    location?.state?.notification
  );
  const [disciplineId, setDisciplineId] = useState(null);
  const [judgeComment, setJudgeComment] = useState("");
  const [disciplineScore, setDisciplineScore] = useState(0);
  const [submittedStatus, setSubmittedStatus] = useState("");
  const [selectedDisplayCommentList1, setSelectedDisplayCommentList1] =
    useState([]);
  const [selectedDisplayCommentList2, setSelectedDisplayCommentList2] =
    useState([]);
  const [previewStatus, setPreviewStatus] = useState(false);
  const [usersCommentsList, setUsersCommentsList] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [performanceText, setPerformanceText] = useState(null);
  const inputRef = useRef(null);

  useEffect(() => {
    getDisciplinesId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleWheel = (e) => {
    e.preventDefault();
    const input = inputRef.current;
    if (input) {
      input.blur();
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  const getDisciplinesId = async () => {
    let key = notification?.discipline?.id;
    let eventId = notification?.event?.id;
    let poolId = notification?.pool?.id;
    let teamId = notification?.team?.id;
    setDisciplineId(key);
    setJudgeComment("");
    setDisciplineScore(null);
    setSubmittedStatus("");
    setSelectedDisplayCommentList1([]);
    setSelectedDisplayCommentList2([]);
    setUsersCommentsList([]);
    setPreviewStatus(false);
    const newCommentsList = [];

    getData(
      EventApi.getEventTeamsDisciplinesCommentStats(
        eventId,
        poolId,
        teamId,
        key
      ).url
    ).then((response) => {
      const data = response.data.stats;
      if (data !== null) {
        setJudgeComment(data.judgeComment);
        setDisciplineScore(data.disciplineScore);
        setSubmittedStatus(data.status);

        let store = [];
        (data.commentScores || []).map((item) => {
          store.push({
            id: item.comment.id,
            commentId: item.comment.id,
            name: item.comment.name,
            score: item.score,
            checkedValue: "true",
          });
          return item;
        });
        setSelectedDisplayCommentList1(store);
      }
    });

    getData(
      EventApi.getEventTeamsDisciplinesComment(eventId, poolId, teamId, key).url
    ).then((response) => {
      const data = response.data.comments;
      data.map((item) => {
        newCommentsList.push({
          id: item.id,
          commentId: item.id,
          name: item.name,
          score: 0,
          checkedValue: "false",
        });
        return item;
      });
      setSelectedDisplayCommentList2(newCommentsList);
    });

    getData(
      EventApi.getUserCommentsList(eventId, poolId, teamId, key).url
    ).then((response) => {
      const data = response.data.commentTexts;
      setUsersCommentsList(data);
    });
  };

  const onChangeComment = ({
    id,
    commentId = null,
    name = null,
    score = null,
    checkedValue = null,
  }) => {
    if (selectedDisplayCommentList1.findIndex((SMD) => SMD.id === id) > -1) {
      const selectedEntry = selectedDisplayCommentList1.find(
        (SMD) => SMD.id === id
      );

      if (commentId) selectedEntry.commentId = commentId;
      if (name) selectedEntry.name = name;
      if (score) selectedEntry.score = Number(score);
      if (checkedValue) selectedEntry.checkedValue = checkedValue;

      setSelectedDisplayCommentList1([
        ...selectedDisplayCommentList1.map((SMD) =>
          SMD.id === id ? selectedEntry : SMD
        ),
      ]);
    } else {
      console.log("Something wrong");
    }
  };

  const onChangeComment2 = ({
    id,
    commentId = null,
    name = null,
    score = null,
    checkedValue = null,
  }) => {
    if (selectedDisplayCommentList2.findIndex((SMD) => SMD.id === id) > -1) {
      const selectedEntry = selectedDisplayCommentList2.find(
        (SMD) => SMD.id === id
      );

      if (commentId) selectedEntry.commentId = commentId;
      if (name) selectedEntry.name = name;
      if (score) selectedEntry.score = Number(score);
      if (checkedValue) selectedEntry.checkedValue = checkedValue;
      setSelectedDisplayCommentList2([
        ...selectedDisplayCommentList2.map((SMD) =>
          SMD.id === id ? selectedEntry : SMD
        ),
      ]);
    } else {
      console.log("Something wrong");
    }
  };

  const onChangeDisciplineScore = (e) => {
    setDisciplineScore(e);
  };

  const onChangejudgeComment = (e) => {
    setJudgeComment(e.target.value);
  };

  const savePost = async () => {
    let array1 = selectedDisplayCommentList1.filter(
      (o) => o.checkedValue === "true"
    );
    let array2 = selectedDisplayCommentList2.filter(
      (o) => o.checkedValue === "true"
    );

    let array3 = array1.concat(array2);
    const sortArrayMetaData = array3.map(({ checkedValue, ...rest }) => ({
      ...rest,
    }));

    if (disciplineScore === null) {
      toastr.error("Please fill score.");
    } else {
      setSubmittedStatus("SAVEDISABLED");
      const value = {
        commentScores: sortArrayMetaData,
        judgeComment: judgeComment,
        disciplineScore: disciplineScore,
        performanceName: performanceText,
      };
      setTimeout(function () {
        let eventId = notification?.event?.id;
        let poolId = notification?.pool?.id;
        let teamId = notification?.team?.id;
        postData(
          EventApi.saveEventTeamsDisciplinesComment(
            eventId,
            poolId,
            teamId,
            disciplineId
          ).url,
          value
        )
          .then((response) => {
            toastr.success("Saved Succesfully");
            setSubmittedStatus("");
          })
          .catch((e) => {
            toastr.error(e.data.message);
            setSubmittedStatus("");
          })
          .finally(() => {
            // setloading(false);
          });
      }, 500);
    }
  };

  const onPreview = () => {
    let array1 = selectedDisplayCommentList1.filter(
      (o) => o.checkedValue === "true"
    );
    let array2 = selectedDisplayCommentList2.filter(
      (o) => o.checkedValue === "true"
    );

    let array3 = array1.concat(array2);

    if (disciplineScore === null) {
      toastr.error("Please fill score.");
    } else if (array3.length === 0 && judgeComment === "") {
      setPreviewStatus(previewStatus);
      toastr.error("Please enter comment");
    } else {
      setPreviewStatus(!previewStatus);
    }

    if (submittedStatus === "JUDGE_SAVED_AS_DRAFT") {
      if (judgeComment === undefined && array3.length === 0) {
        toastr.error("Please enter comment");
        setPreviewStatus(previewStatus);
      }
    }
  };

  const updatePost = async () => {
    // setloading(true);
    setSubmittedStatus("JUDGE_SUBMITTED");

    let array1 = selectedDisplayCommentList1.filter(
      (o) => o.checkedValue === "true"
    );
    let array2 = selectedDisplayCommentList2.filter(
      (o) => o.checkedValue === "true"
    );

    let array3 = array1.concat(array2);
    const sortArrayMetaData = array3.map(({ checkedValue, ...rest }) => ({
      ...rest,
    }));

    if (disciplineScore === null) {
      toastr.error("Please fill score.");
    } else {
      const value = {
        commentScores: sortArrayMetaData,
        judgeComment: judgeComment,
        disciplineScore: disciplineScore,
        performanceName: performanceText,
      };

      setTimeout(function () {
        let eventId = notification?.event?.id;
        let poolId = notification?.pool?.id;
        let teamId = notification?.team?.id;
        postData(
          EventApi.addEventTeamsDisciplinesComment(
            eventId,
            poolId,
            teamId,
            disciplineId
          ).url,
          value
        )
          .then((response) => {
            setSubmittedStatus(response.data.stats.status);
            toastr.success("Successfully Submitted");
            history.push("/my-actions");
          })
          .catch((e) => {
            toastr.error(e.data.message);
            setSubmittedStatus("");
          })
          .finally(() => {
            // setloading(false);
            // loadTeamsDisciplines();
          });
      }, 500);
    }
  };

  const renderBreadcrumb = () => (
    <Breadcrumb>
      <Breadcrumb.Item>{notification?.event?.name}</Breadcrumb.Item>
      <Breadcrumb.Item>{notification?.team?.name}</Breadcrumb.Item>
      <Breadcrumb.Item>{notification?.discipline?.name}</Breadcrumb.Item>
    </Breadcrumb>
  );

  return (
    <PageLayout>
      {renderBreadcrumb()}
      <div className="action-container">
        <div className="teamDisciplines">
          <Row className={previewStatus ? "showText" : "hideText"}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 16 }}
              lg={{ span: 16 }}
            >
              {selectedDisplayCommentList1
                .filter((o) => o.checkedValue === "true")
                .map((item, index) => (
                  <Row className="previewStatus" key={index}>
                    <Col span={20}>
                      <Checkbox disabled>{item.name}</Checkbox>
                    </Col>
                    <Col span={4}>
                      <InputNumber
                        type="number"
                        value={item.score}
                        precision={0}
                        disabled
                      />
                    </Col>
                  </Row>
                ))}
              {selectedDisplayCommentList2
                .filter((o) => o.checkedValue === "true")
                .map((item, index) => (
                  <Row className="previewStatus" key={index}>
                    <Col span={20}>
                      <Checkbox disabled>{item.name}</Checkbox>
                    </Col>
                    <Col span={4}>
                      <InputNumber
                        type="number"
                        precision={0}
                        value={item.score}
                        disabled
                      />
                    </Col>
                  </Row>
                ))}
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 8 }}
              lg={{ span: 8 }}
            >
              <div className="scoreCircle">
                <Input type="number" value={disciplineScore} disabled />
              </div>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 24 }}
            >
              <p>
                <strong>Judge Comment</strong>
              </p>
              <TextArea rows={4} value={judgeComment} disabled />
            </Col>
          </Row>

          <Row className={previewStatus ? "hideText" : "showText"}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 16 }}
              lg={{ span: 16 }}
            >
              {(selectedDisplayCommentList1 || []).map((item) => (
                <Row key={item.id}>
                  <Col span={20}>
                    <Checkbox
                      onChange={(e) =>
                        onChangeComment({
                          id: item.id,
                          commentId: item.id,
                          name: item.name,
                          score: item.score,
                          checkedValue: `${e.target.checked}`,
                        })
                      }
                      checked={item.checkedValue === "true" ? true : false}
                      disabled={
                        submittedStatus === "APPROVED" ||
                        submittedStatus === "HEAD_JUDGE_REVIEW_REQUIRED" ||
                        submittedStatus === "JUDGE_RESUBMITTED" ||
                        submittedStatus === "JUDGE_SUBMITTED"
                          ? true
                          : false
                      }
                    >
                      {item.name}
                    </Checkbox>
                  </Col>
                  <Col span={4}>
                    <InputNumber
                      type="number"
                      defaultValue={item.score}
                      min={-5}
                      max={5}
                      precision={0}
                      onChange={(e) =>
                        onChangeComment({
                          id: item.id,
                          commentId: item.id,
                          name: item.name,
                          score: e,
                        })
                      }
                      disabled={
                        submittedStatus === "APPROVED" ||
                        submittedStatus === "HEAD_JUDGE_REVIEW_REQUIRED" ||
                        submittedStatus === "JUDGE_RESUBMITTED" ||
                        submittedStatus === "JUDGE_SUBMITTED"
                          ? true
                          : false
                      }
                    />
                  </Col>
                </Row>
              ))}
              {(selectedDisplayCommentList2 || [])
                .filter(
                  ({ name: id1 }) =>
                    !selectedDisplayCommentList1.some(
                      ({ name: id2 }) => id2 === id1
                    )
                )
                .map((item) => (
                  <Row
                    key={item.id}
                    className={
                      submittedStatus === "APPROVED" ||
                      submittedStatus === "HEAD_JUDGE_REVIEW_REQUIRED" ||
                      submittedStatus === "JUDGE_RESUBMITTED" ||
                      submittedStatus === "JUDGE_SUBMITTED"
                        ? "hideText"
                        : "showText"
                    }
                  >
                    <Col span={20}>
                      <Checkbox
                        onChange={(e) =>
                          onChangeComment2({
                            id: item.id,
                            name: item.name,
                            score: item.score,
                            checkedValue: `${e.target.checked}`,
                          })
                        }
                        checked={item.checkedValue === "true" ? true : false}
                        disabled={
                          submittedStatus === "APPROVED" ||
                          submittedStatus === "HEAD_JUDGE_REVIEW_REQUIRED" ||
                          submittedStatus === "JUDGE_RESUBMITTED" ||
                          submittedStatus === "JUDGE_SUBMITTED"
                            ? true
                            : false
                        }
                      >
                        {item.name}
                      </Checkbox>
                    </Col>
                    <Col span={4}>
                      <InputNumber
                        type="number"
                        defaultValue={item.score}
                        min={-5}
                        max={5}
                        precision={0}
                        onChange={(e) =>
                          onChangeComment2({
                            id: item.id,
                            name: item.name,
                            score: e,
                          })
                        }
                        disabled={
                          submittedStatus === "APPROVED" ||
                          submittedStatus === "HEAD_JUDGE_REVIEW_REQUIRED" ||
                          submittedStatus === "JUDGE_RESUBMITTED" ||
                          submittedStatus === "JUDGE_SUBMITTED"
                            ? true
                            : false
                        }
                      />
                    </Col>
                  </Row>
                ))}
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 8 }}
              lg={{ span: 8 }}
            >
              <div className="scoreCircle2">
                <InputNumber
                  type="number"
                  min={85}
                  max={100}
                  precision={0}
                  value={disciplineScore}
                  onChange={onChangeDisciplineScore}
                  placeholder="Score"
                  controls={false}
                  ref={inputRef}
                  onWheel={handleWheel}
                  disabled={
                    submittedStatus === "APPROVED" ||
                    submittedStatus === "HEAD_JUDGE_REVIEW_REQUIRED" ||
                    submittedStatus === "JUDGE_RESUBMITTED" ||
                    submittedStatus === "JUDGE_SUBMITTED"
                      ? true
                      : false
                  }
                  onKeyDown={handleKeyDown}
                />
              </div>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 24 }}
            >
              <p
                className={
                  submittedStatus === "APPROVED" ||
                  submittedStatus === "HEAD_JUDGE_REVIEW_REQUIRED" ||
                  submittedStatus === "JUDGE_RESUBMITTED" ||
                  submittedStatus === "JUDGE_SUBMITTED"
                    ? "hideText"
                    : "showText"
                }
              >
                <strong>Judge Comment</strong>
              </p>
              <TextArea
                rows={4}
                placeholder="Type your comment here"
                onChange={onChangejudgeComment}
                value={judgeComment}
                disabled={
                  submittedStatus === "APPROVED" ||
                  submittedStatus === "HEAD_JUDGE_REVIEW_REQUIRED" ||
                  submittedStatus === "JUDGE_RESUBMITTED" ||
                  submittedStatus === "JUDGE_SUBMITTED"
                    ? true
                    : false
                }
                className={
                  submittedStatus === "APPROVED" ||
                  submittedStatus === "HEAD_JUDGE_REVIEW_REQUIRED" ||
                  submittedStatus === "JUDGE_RESUBMITTED" ||
                  submittedStatus === "JUDGE_SUBMITTED"
                    ? "hideText"
                    : "showText"
                }
              />
            </Col>

            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 24 }}
            >
              <div className="commentsListBox">
                {usersCommentsList.length > 0 && (
                  <p>
                    <strong>Comments</strong>
                  </p>
                )}
                <ul>
                  {(usersCommentsList || []).map((item) => (
                    <li>
                      {item.roleName === "JUDGE" && <strong>Judge</strong>}
                      {item.roleName === "MODERATOR" && (
                        <strong>Moderator</strong>
                      )}
                      {item.roleName === "HEAD_JUDGE" && (
                        <strong>Head judge</strong>
                      )}
                      <br />
                      {item.comment}
                    </li>
                  ))}
                </ul>
              </div>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <div className="moderatorsFooter">
                {!previewStatus ? (
                  <Button
                    type="primary"
                    onClick={() => savePost()}
                    disabled={
                      submittedStatus === "APPROVED" ||
                      submittedStatus === "HEAD_JUDGE_REVIEW_REQUIRED" ||
                      submittedStatus === "JUDGE_RESUBMITTED" ||
                      submittedStatus === "JUDGE_SUBMITTED" ||
                      submittedStatus === "SAVEDISABLED"
                        ? true
                        : false
                    }
                  >
                    Save
                  </Button>
                ) : null}
                <Button
                  type="primary"
                  onClick={onPreview}
                  disabled={
                    submittedStatus === "APPROVED" ||
                    submittedStatus === "HEAD_JUDGE_REVIEW_REQUIRED" ||
                    submittedStatus === "JUDGE_RESUBMITTED" ||
                    submittedStatus === "JUDGE_SUBMITTED"
                      ? true
                      : false
                  }
                >
                  {previewStatus ? "Back" : "Preview"}
                </Button>
                {previewStatus ? (
                  <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => updatePost()}
                  >
                    <Button
                      type="primary"
                      // onClick={() => updatePost()}
                      disabled={
                        submittedStatus === "APPROVED" ||
                        submittedStatus === "HEAD_JUDGE_REVIEW_REQUIRED" ||
                        submittedStatus === "JUDGE_RESUBMITTED" ||
                        submittedStatus === "JUDGE_SUBMITTED"
                          ? true
                          : false
                      }
                    >
                      Submit
                    </Button>
                  </Popconfirm>
                ) : null}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </PageLayout>
  );
}

export default Action;
