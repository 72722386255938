import React, { useState, useEffect } from 'react';
import { useParams, Link, useLocation } from "react-router-dom";
import { LoadingView } from '../common';
import PageLayout from "../layout"


import toastr from 'toastr';
import { EventApi, DisciplinesApi } from '../../api';
import { postData, getData, putData, getPdfData, getExcelData } from '../../_services/apiCommonService';

import { Drawer, Col, Row, Tabs, Table, Popconfirm, Button, Modal, Steps, Radio, Checkbox, Space, Input, Select, InputNumber, Collapse, Tooltip, Form } from 'antd';
import { MinusCircleOutlined, DownloadOutlined, EditOutlined, UserSwitchOutlined } from '@ant-design/icons';
import './EventInfo.scss';

const { TabPane } = Tabs;
const { Option } = Select;
const { Panel } = Collapse;

const EventsDetails = props => {
  const location = useLocation();

  let eventName = location.state
  if (location.state === undefined) {
    window.location.href = '/';
  }

  const { eventId } = useParams();
  const [loading, setloading] = useState(true);
  const [usersList, setUsersList] = useState([]);
  const [teamsList, setTeamsList] = useState([]);
  const [disciplineList, setDisciplineList] = useState([]);
  const [eventTeamList, setEventTeamList] = useState([])
  const [eventPoolsList, setEventPoolsList] = useState([])
  const [eventUsersList, setEventUsersList] = useState([])
  const [rolesList, setRolesList] = useState([]);
  const { Step } = Steps;
  const [scoreEventPoolsList, setScoreEventPoolsList] = useState([])
  const [form] = Form.useForm();
  const [singleEvent, setSingleEvent] = useState({})
  const [eventProgressStatus, setEventProgressStatus] = useState('');

  const fetchAllUsers = () => {
    setloading(true)
    getData(EventApi.getAllAvailableEventUsers(eventId).url)
      .then((response) => {
        const data = response.data.users;
        setUsersList(
          (data || []).map(user => ({
            key: user.id,
            id: user.id,
            name: user.name,
            email: user.email,
            isAdmin: `${user.isAdmin}`,
            isActive: `${user.isActive}`,
          }))
        );
      })
      .catch((e) => {
        toastr.error(e.data.message);
      })
      .finally(() => {
        setloading(false)
      });
  };

  const fetchAllTeams = () => {
    setloading(true);
    getData(EventApi.getAllAvailableTeamsForEvent(eventId).url)
      .then((response) => {
        const data = response.data.teams;
        setTeamsList(
          (data || []).map(team => ({
            id: team.id,
            name: team.name,
          }))
        );
      })
      .catch((e) => {
        toastr.error(e.data.message);
      })
      .finally(() => {
        setloading(false)
      });
  };

  const fetchAllDiscipline = () => {
    setloading(true);
    getData(DisciplinesApi.getDisciplinesExcludeParents().url)
      .then((response) => {
        const data = response.data.excludedParentDisciplines;
        setDisciplineList(
          (data || []).map(discipline => ({
            key: discipline.id,
            id: discipline.id,
            name: discipline.name,
            isAggregate: `${discipline.isAggregate}`,
            isMandatory: `${discipline.isMandatory}`,
          }))
        );
      })
      .catch((e) => {
        toastr.error(e.data.message);
      })
      .finally(() => {
        setloading(false)
      });
  };

  const loadAllEventTeams = () => {
    setloading(true);
    getData(EventApi.getAllEventTeams(eventId).url)
      .then((response) => {
        const data = response.data.eventTeams;
        setEventTeamList(
          (data || []).map(et => ({
            key: et.team.id,
            id: et.team.id,
            name: et.team.name,
            position: et.position,
            score: et.score,
            disciplines: et.disciplineScores,
          }))
        );
      })
      .catch((e) => {
        toastr.error(e.data.message);
      })
      .finally(() => {
        setloading(false)
      });
  };

  const loadAllEventPools = () => {
    setloading(true)
    getData(EventApi.getAllEventPools(eventId).url)
      .then((response) => {
        const data = response.data.eventPools;
        setScoreEventPoolsList(data);
        setEventPoolsList(
          (data || []).map(et => ({
            key: et.id,
            id: et.id,
            name: et.name,
            isFinal: `${et.isFinal}`,
            teams: et.eventTeams,
          }))
        );
      })
      .catch((e) => {
        toastr.error(e.data.message);
      })
      .finally(() => {
        setloading(false)
      });
  };

  const loadAllEventUsers = () => {
    setloading(true);
    getData(EventApi.getAllEventUsers(eventId).url)
      .then((response) => {
        const data = response.data.eventUsers;
        setEventUsersList(
          (data || []).map((eu, i) => ({
            key: i,
            id: i,
            user: eu.user,
            role: eu.role,
            disciplines: eu.disciplines,
            teams: eu.teams,
            isActive: eu.isActive,
          }))
        );
      })
      .catch((e) => {
        toastr.error(e.data.message);
      })
      .finally(() => {
        setloading(false)
      });
  };

  const loadAllRoles = () => {
    setloading(true);

    getData(EventApi.getAllRole().url)
      .then((response) => {
        const data = response.data.userRoles;
        setRolesList(data);
      })
      .catch((e) => {
        toastr.error(e.data.message);
      })
      .finally(() => {
        setloading(false)
      });
  };

  const onLoadGetEventsById = () => {
    getData(EventApi.getEventsById(eventId).url)
      .then((response) => {
        const data = response.data.event;
        setEventProgressStatus(data.status);
        setSingleEvent(
          {
            id: data.id,
            name: data.name,
            startDate: data.startDate,
            endDate: data.endDate,
            isActive: `${data.isActive}`,
            status: data.status,
          }
        );
      })
      .catch((e) => {
        toastr.error(e.data.message);
        console.log(singleEvent);
      })
      .finally(() => {
        setloading(false)
      });
  };

  useEffect(() => {
    if (eventId) {
      fetchAllDiscipline();
      loadAllEventTeams();
      onLoadGetEventsById();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId]);


  const onClickEventStart = async () => {
    postData(EventApi.eventStart(eventId).url)
      .then((response) => {
        toastr.success('Successfully Submitted');
      })
      .catch((e) => {
        toastr.error(e.data.message);
      })
      .finally(() => {
        setloading(false);
        onLoadGetEventsById();
      });
  };

  const onClickEventComplete = async () => {
    postData(EventApi.eventComplete(eventId).url)
      .then((response) => {
        toastr.success('Successfully Submitted');
      })
      .catch((e) => {
        toastr.error(e.data.message);
      })
      .finally(() => {
        setloading(false);
        onLoadGetEventsById();
      });
  };

  const startEvent = () => (
    <React.Fragment>
      {eventProgressStatus === 'NOT_STARTED' ? (
        <Popconfirm
          title="Are you sure？"
          okText="Yes"
          cancelText="No"
          onConfirm={e => onClickEventStart(e)}
        >
          <Button type="primary" className="startEvent">
            Start Event
          </Button>
        </Popconfirm>
      ) : (
        <Popconfirm
          title="Are you sure？"
          okText="Yes"
          cancelText="No"
          onConfirm={() => onClickEventComplete()}
          disabled={eventProgressStatus === 'COMPLETED' ? 'disabled' : null}
          className={eventProgressStatus === 'COMPLETED' ? 'buttonDisabled' : null}
        >
          <Button type="primary" className="startEvent">
            Complete Event
          </Button>
        </Popconfirm>
      )}
    </React.Fragment>

  );

  //**********Teams Start***********//
  const [isTeamModalVisible, setIsTeamModalVisible] = useState(false);
  const [isSeparateTeamsModalVisible, setIsSeparateTeamsModalVisible] = useState(false);

  const [teamCurrent, setTeamCurrent] = useState(0);
  const [selectTeamId, setSelectTeamId] = useState(null);
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [selectDisciplinesValue, setSelectDisciplinesValue] = useState([]);
  const [nonMandatoryDisciplinesValue, setNonMandatoryDisciplinesValue] = useState([]);

  const [selectedTeamName, setSelectedTeamName] = useState('');
  const [selectedDisciplineList, setSelectedDisciplineList] = useState([]);
  const [tempSelectedTeamId, setTempSelectedTeamId] = useState(null);
  const [tempSelectedTeamPosition, setTempSelectedTeamPosition] = useState(null);

  //**********Start Add Teams***********//
  const OpenCreateTeamModal = () => {
    fetchAllTeams();
    fetchAllDiscipline();
    setIsTeamModalVisible(true);
    setSelectTeamId(null);
    setSelectedPosition(null);
    setSelectDisciplinesValue([]);
    setNonMandatoryDisciplinesValue([]);
  };

  const CloseCreateTeamModal = () => {
    setIsTeamModalVisible(false);
    setSelectTeamId(null);
    setSelectedPosition(null);
    setSelectDisciplinesValue([]);
    setNonMandatoryDisciplinesValue([]);
    if (teamCurrent === 1) {
      setTeamCurrent(teamCurrent - 1);
    }
  };

  const teamNext = () => {
    if (selectedPosition === "" || selectedPosition === null) {
      toastr.error('Please enter display order');
    } else if (selectTeamId === "" || selectTeamId === null) {
      toastr.error('Please select Team');
    } else {
      setTeamCurrent(teamCurrent + 1);
    }
  };

  const teamPrev = () => {
    setTeamCurrent(teamCurrent - 1);
    // setSelectDisciplinesValue([]);
  };

  const onChangeSelectedPosition = (value) => {
    setSelectedPosition(value)
  }

  const onChangeSelectTeamId = e => {
    setSelectTeamId(e.target.value);

    const defaultCheckboxValue = [];
    (disciplineList || []).map(item => {
      defaultCheckboxValue.push(Number(item.id))
      return item;
    })
    setSelectDisciplinesValue(defaultCheckboxValue);
  };

  const onChangeSelectDisciplinesValue = checkedValues => {
    setSelectDisciplinesValue(checkedValues);
  };

  const onChangeNonMandatoryDisciplinesValue = nonMandatory => {
    setNonMandatoryDisciplinesValue(nonMandatory);
  };

  const updateEventsTeam = async () => {
    const disciplinesValue = selectDisciplinesValue.concat(nonMandatoryDisciplinesValue);
    if (selectDisciplinesValue.length === 0 || selectDisciplinesValue.length === "") {
      toastr.error('Please select Disciplines');
    } else {
      const values = {
        teamId: selectTeamId,
        position: selectedPosition,
        disciplineIds: disciplinesValue,
      }
      setloading(true);
      postData(EventApi.addAllEventTeams(eventId).url, values)
        .then((response) => {
          toastr.success('Successfully Submitted');
        })
        .catch((e) => {
          toastr.error(e.data.message);
        })
        .finally(() => {
          setloading(false)
          setIsTeamModalVisible(false);
          loadAllEventTeams();
          teamPrev();
          setSelectedPosition(null);
          setSelectDisciplinesValue([]);
          setNonMandatoryDisciplinesValue([]);
        });
    }
  }
  //**********End Add Teams***********//
  //**********Start Edit Teams********//

  const onClickShowTeamModal = (record, data) => {
    form.resetFields();
    fetchAllDiscipline();
    setIsSeparateTeamsModalVisible(true);
    setTempSelectedTeamId(data.id);
    setSelectedTeamName(record);
    setTempSelectedTeamPosition(data.position);
    setSelectedDisciplineList(
      (data.disciplines || []).map(item => ({
        name: item.discipline.name,
        position: item.discipline.position,
        priority: item.discipline.priority,
        isAggregate: item.discipline.isAggregate,
        isMandatory: item.discipline.isMandatory,
        id: item.discipline.id,
      }))
    );
  };

  const onClickCloseTeamModal = () => {
    setIsSeparateTeamsModalVisible(false);
    setTempSelectedTeamId(null);
    setSelectedTeamName('');
    setTempSelectedTeamPosition(null);
    setSelectedDisciplineList([]);
    form.resetFields();
  };

  const onClickCancelTeamModal = () => {
    setIsSeparateTeamsModalVisible(false);
    setTempSelectedTeamId(null);
    setSelectedTeamName('');
    setTempSelectedTeamPosition(null);
    setSelectedDisciplineList([]);
    form.resetFields();
  };

  const OnClickRemoveEventTeamsDis = async (index, e) => {
    var newData = selectedDisciplineList;
    newData.splice(index, 1);
    setSelectedDisciplineList([...newData]);
  };

  let newSelectedDisciplines = [];
  const onChangeAddNewDiscipline = async (value) => {
    newSelectedDisciplines = value;
  };

  const updateEditEventTeamsDisciplines = async () => {

    const disciplineIds = [];

    (selectedDisciplineList || []).map(item => {
      disciplineIds.push(item.id);
      return item
    })

    const finalDisciplineIds = (disciplineIds.concat(newSelectedDisciplines)).filter(function (el) {
      return el != null;
    });

    if (finalDisciplineIds.length === 0) {
      toastr.error('Please select atleast one discipline');
    } else {
      const values = {
        position: tempSelectedTeamPosition,
        disciplineIds: finalDisciplineIds,
      }

      setloading(true);
      putData(EventApi.updateEditEventTeam(eventId, tempSelectedTeamId).url, values)
        .then((response) => {
          toastr.success('Successfully Submitted');
        })
        .catch((e) => {
          toastr.error(e.data.message);
        })
        .finally(() => {
          setloading(false);
          setIsSeparateTeamsModalVisible(false);
          setTempSelectedTeamId(null);
          setSelectedTeamName('');
          setTempSelectedTeamPosition(null);
          setSelectedDisciplineList([]);
          loadAllEventTeams();
          loadAllEventPools();
        })
    }


  };
  //**********End Edit Teams**********//

  const columns = [
    {
      title: 'Teams',
      dataIndex: 'name',
      width: '90%',
      render: (record, val) => {
        return (
          <Link
            to={{
              state: eventName
            }}
            onClick={(e) => onClickShowTeamModal(record, val)}
          >
            {record}
          </Link>
        )
      },
    }
  ];

  const renderTeamsTab = () => {
    if (loading) return <LoadingView message="Loading..." />;
    return (
      <Row gutter={16}>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
          <div className="buttonRow">
            {startEvent()}
            <Button
              type="primary"
              onClick={OpenCreateTeamModal}
              disabled={
                eventProgressStatus === 'STARTED' ||
                  eventProgressStatus === 'COMPLETED' ? 'disabled' : null}
              className={
                eventProgressStatus === 'STARTED' ||
                  eventProgressStatus === 'COMPLETED' ? 'buttonDisabled' : null}
            >
              Add Team
            </Button>
          </div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
          <Table
            columns={columns}
            dataSource={eventTeamList}
            rowKey={record => record.id}
          />
        </Col>
      </Row>
    );
  };

  const renderSelectTeam = () => {
    if (loading) return <LoadingView message="Loading..." />;
    return (
      <React.Fragment>
        <p><span className="redMandatory">*</span><strong>Display Order</strong></p>
        <Row>
          <Col span={24}>
            <InputNumber min={1} value={selectedPosition} onChange={onChangeSelectedPosition} />
          </Col>
        </Row>
        <p><span className="redMandatory">*</span><strong>Teams</strong></p>
        <Radio.Group onChange={onChangeSelectTeamId} value={selectTeamId}>
          <Space direction="vertical">
            {teamsList.filter(({ name: id1 }) => !eventTeamList
              .some(({ name: id2 }) => id2 === id1))
              .map(item => (
                <Row rowKey={item.id}>
                  <Col span={24}>
                    <Radio value={item.id} key={item.id}>{item.name}</Radio>
                  </Col>
                </Row>
              ))
            }
          </Space>
        </Radio.Group>
      </React.Fragment>
    );
  };

  const renderSelectDisciplines = () => {

    if (loading) return <LoadingView message="Loading..." />;
    return (
      <React.Fragment>
        <Checkbox.Group
          style={{ width: '100%' }}
          onChange={onChangeSelectDisciplinesValue}
          className="disciplinesChecks"
          defaultValue={selectDisciplinesValue}
        >
          <p><strong>Optional Disciplines</strong></p>
          {
            disciplineList.filter(MDT => MDT.isMandatory !== 'true').map(item => (
              <Row rowKey={item.id}>
                <Col span={24}>
                  <Checkbox value={item.id} key={item.id}> {item.name}</Checkbox>
                </Col>
              </Row>
            ))
          }
          <p><span className='redMandatory'>*</span><strong>Disciplines</strong></p>
          {
            disciplineList.filter(MDT => MDT.isMandatory === 'true').map(item => (
              <Row rowKey={item.id}>
                <Col span={24}>
                  <Checkbox value={item.id} key={item.id} disabled> {item.name}</Checkbox>
                </Col>
              </Row>
            ))
          }

        </Checkbox.Group>
        {
          <Select mode="tags" style={{ display: 'none' }} placeholder="Disciplines" onChange={onChangeNonMandatoryDisciplinesValue}>
            {
              disciplineList.filter(MDT => MDT.isMandatory !== 'true').map(item => (
                <Option key={item.id} value={item.id}>{item.name}</Option>
              ))
            }
          </Select>
        }
      </React.Fragment>
    );
  };

  const teamSteps = [
    {
      title: 'Select Team',
      content: renderSelectTeam(),

    },
    {
      title: 'Select Disciplines',
      content: renderSelectDisciplines(),
    },
  ];

  const renderSteps = () => {
    return (
      <Steps current={teamCurrent} className="modalHeadingSteps">
        {teamSteps.map(item => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
    );
  };
  const renderTeamsModalContent = () => {
    if (loading) return <LoadingView message="Loading..." />;
    return (
      <div className="steps-content">{teamSteps[teamCurrent].content}</div>
    );
  };
  const renderTeamsFooter = () => (
    <div className="steps-action">
      <Button type="default" onClick={() => CloseCreateTeamModal()}>Close</Button>
      {teamCurrent < teamSteps.length - 1 && (
        <Button type="primary" onClick={() => teamNext()}>
          Next
        </Button>
      )}
      {teamCurrent > 0 && (
        <Button onClick={() => teamPrev()}>
          Previous
        </Button>
      )}
      {teamCurrent === teamSteps.length - 1 && (
        <Button type="primary" onClick={() => updateEventsTeam()}>
          Done
        </Button>
      )}
    </div>
  );
  const renderTeamDetailsFooter = () => (
    <div className="steps-action">
      <Button type="default" onClick={() => onClickCancelTeamModal()}>Cancel</Button>
      <Button
        type="primary"
        onClick={() => updateEditEventTeamsDisciplines()}
        disabled={
          eventProgressStatus === 'STARTED' ||
            eventProgressStatus === 'COMPLETED' ? 'disabled' : null}
        className={
          eventProgressStatus === 'STARTED' ||
            eventProgressStatus === 'COMPLETED' ? 'buttonDisabled' : null}
      >Update</Button>
    </div>
  );
  const renderTeamDetailsModal = () => {
    return (
      <Modal
        title={selectedTeamName}
        visible={isSeparateTeamsModalVisible}
        okText="Update"
        onOk={onClickCloseTeamModal}
        onCancel={onClickCancelTeamModal}
        footer={renderTeamDetailsFooter()}
      >
        <div className="modalForm">
          <p><strong>Disciplines</strong></p>
          <ul className="selectedDisciplines">
            {(selectedDisciplineList || []).map((item, index) => (
              <li key={item.id}>
                <span>{item.name}</span>
                <span>
                  {!item.isMandatory && (
                    <Popconfirm
                      title="Sure to delete?"
                      onConfirm={e => OnClickRemoveEventTeamsDis(index, e)}
                      disabled={
                        eventProgressStatus === 'STARTED' ||
                          eventProgressStatus === 'COMPLETED' ? 'disabled' : null}
                    >
                      <Link className="rowDelete"><MinusCircleOutlined /></Link>
                    </Popconfirm>
                  )
                  }
                </span>
              </li>
            ))}
          </ul>

          <p><strong>Add More Disciplines</strong></p>

          <Form
            name="AddMoreDisciplines"
            form={form}
          >
            <Form.Item name="teamDisciplines">
              <Select
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                placeholder="Please select"
                onChange={onChangeAddNewDiscipline}
                disabled={
                  eventProgressStatus === 'STARTED' ||
                    eventProgressStatus === 'COMPLETED' ? 'disabled' : null}
              >
                {disciplineList.filter(({ name: id1 }) => !selectedDisciplineList
                  .some(({ name: id2 }) => id2 === id1))
                  .map(dis => (
                    <Option key={dis.id} value={dis.id}>{dis.name}</Option>
                  ))
                }
              </Select>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    );
  };
  //**********Teams End***********//

  //**********Pools Start***********//
  const [isPoolsModalVisible, setIsPoolsModalVisible] = useState(false);
  const [isSeparatePoolsModalVisible, setIsSeparatePoolsModalVisible] = useState(false);

  const [currentPool, setCurrentPool] = useState(0);
  const [poolName, setPoolName] = useState('');
  const [isFinalPool, setIsFinalPool] = useState(false);
  const [selectPoolTeamsValue, setSelectPoolTeamsValue] = useState([]);
  const [eventPoolTeamsList, setEventPoolTeamsList] = useState([]);

  const [editPoolId, setEditPoolId] = useState(null);
  const [editPoolName, setEditPoolName] = useState('');
  const [editIsFinalPool, setEditIsFinalPool] = useState('');
  const [selectedNewPoolTeamList, setSelectedNewPoolTeamList] = useState([]);

  const poolsColumns = [
    {
      title: 'Pool',
      dataIndex: 'name',
      width: '60%',
      sorter: (a, b) => a.name.length - b.name.length,
      render: (record, val) => {
        return (
          <Link
            to={{
              state: eventName
            }}
            onClick={(e) => onClickShowPoolModal(record, val)}
          >
            {record}
          </Link>
        )
      },
    },
    {
      title: 'Final Pool',
      dataIndex: 'isFinal',
      width: '30%',
      render: (record) => {
        return (
          `${record === 'true' ? 'Yes' : 'No'}`
        )
      },
    },
  ];

  //**********Start Add Pools************//

  const EventTeamsForPool = () => {
    getData(EventApi.getAllAvailableEventTeamsForPool(eventId).url)
      .then((response) => {
        const data = response.data.eventTeams;
        setEventPoolTeamsList(
          (data || []).map(et => ({
            key: et.team.id,
            id: et.team.id,
            name: et.team.name,
            position: et.position,
            score: et.score,
            disciplines: et.disciplineScores,
          }))
        );
      })
      .catch((e) => {
        toastr.error(e.data.message);
      })
      .finally(() => {
        setloading(false)
      });
  }

  const OpenCreatePoolModal = () => {
    setIsPoolsModalVisible(true);
    setPoolName('');
    setIsFinalPool(false);
    setSelectPoolTeamsValue([]);
  };

  const CloseCreatePoolModal = () => {
    setIsPoolsModalVisible(false);
    setPoolName('');
    setIsFinalPool(false);
    setSelectPoolTeamsValue([]);
    setCurrentPool(0)
  };

  const poolNext = () => {

    if (poolName === "" || poolName === null) {
      toastr.error('Please add Pool name');
    } else {
      setCurrentPool(currentPool + 1);
      EventTeamsForPool();
    }
  };

  const poolPrev = () => {
    setCurrentPool(currentPool - 1);
    setSelectPoolTeamsValue([]);
  };

  const onChangePoolName = e => {
    setPoolName(e.target.value);
  };

  const onChangeIsFinal = e => {
    setIsFinalPool(e.target.checked);
  };

  const onChangeSelectPoolTeams = checkedValues => {
    setSelectPoolTeamsValue(checkedValues);
  };

  const AddPools = async () => {
    const values = {
      'name': poolName,
      'isFinal': isFinalPool,
      'teamIds': selectPoolTeamsValue,
    };

    setloading(true);
    postData(EventApi.addAllEventPools(eventId).url, values)
      .then((response) => {
        toastr.success('Successfully Submitted');
        setIsPoolsModalVisible(false);
        loadAllEventPools();
      })
      .catch((e) => {
        toastr.error(e.data.message);
      })
      .finally(() => {
        setloading(false)
        setPoolName('');
        setSelectPoolTeamsValue([]);
        setIsFinalPool(false);
        // poolPrev();
      });
  }

  const updateAddPools = async () => {
    if (isFinalPool) {
      AddPools();
    }
    else {
      if (selectPoolTeamsValue.length === 0 || selectPoolTeamsValue.length === "") {
        toastr.error('Please select Teams');
      } else {
        AddPools();
        poolPrev();
      }
    }
  }

  const renderSelectedPool = () => {
    if (loading) return <LoadingView message="Loading..." />;
    return (
      <React.Fragment>
        <p><span className="redMandatory">*</span><strong>Name</strong></p>
        <Input
          placeholder="Pool Name"
          onChange={onChangePoolName}
          value={poolName}
        />
        <p><strong> </strong></p>
        <Checkbox
          checked={isFinalPool ? true : false}
          onChange={onChangeIsFinal}
          value={isFinalPool}
          style={{ border: 0 }}
        >
          Is Final Pool ?
        </Checkbox>
      </React.Fragment>
    );
  };

  const renderSelectedPoolTeam = () => {
    if (loading) return <LoadingView message="Loading..." />;
    return (
      <React.Fragment>
        <p><span className="redMandatory">*</span><strong>Teams</strong></p>
        <Checkbox.Group onChange={onChangeSelectPoolTeams}>
          <Row>
            {
              eventPoolTeamsList.map(item => (
                <Col span={24}>
                  <Checkbox value={item.id} key={item.id}>{item.name}</Checkbox>
                </Col>
              ))
            }
          </Row>
        </Checkbox.Group>

      </React.Fragment>
    );
  };

  const poolSteps = [
    {
      title: 'Pool',
      content: renderSelectedPool(),
    },
    {
      title: 'Select Teams',
      content: renderSelectedPoolTeam(),
    },
  ];

  const renderPoolSteps = () => {
    return (
      <Steps current={currentPool} className="modalHeadingSteps">
        {poolSteps.map(item => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
    );
  };

  const renderPoolsModalContent = () => {
    if (loading) return <LoadingView message="Loading..." />;
    return (
      <div className="steps-content">{poolSteps[currentPool].content}</div>
    );
  };

  const renderPoolsFooter = () => (
    <div className="steps-action">
      <Button type="default" onClick={() => CloseCreatePoolModal()}>Close</Button>
      {currentPool < poolSteps.length - 1 && (
        <React.Fragment>
          {isFinalPool ? (
            <Button type="primary" onClick={() => updateAddPools()}>Done</Button>
          ) : (
            <Button type="primary" onClick={() => poolNext()}>Next</Button>
          )

          }
        </React.Fragment>
      )}
      {currentPool > 0 && (
        <Button onClick={() => poolPrev()}>
          Previous
        </Button>
      )}
      {currentPool === poolSteps.length - 1 && (
        <Button type="primary" onClick={() => updateAddPools()}>
          Done
        </Button>
      )}

    </div>
  );

  //**********End Add Pools*************//
  //**********Start Edit Pools**********//

  const onClickShowPoolModal = (record, data) => {
    form.resetFields();
    EventTeamsForPool();
    setIsSeparatePoolsModalVisible(true);
    setEditPoolId(data.id);
    setEditPoolName(record);
    setEditIsFinalPool(data.isFinal);
    setSelectedNewPoolTeamList(data.teams);
  };

  const onClickClosePoolModal = () => {
    setIsSeparatePoolsModalVisible(false);
    setEditPoolId(null);
    setEditPoolName('');
    setEditIsFinalPool(false);
    setSelectedNewPoolTeamList([]);
    form.resetFields();
  };

  const onClickCancelPoolModal = () => {
    setIsSeparatePoolsModalVisible(false);
    setEditPoolId(null);
    setEditPoolName('');
    setEditIsFinalPool(false);
    setSelectedNewPoolTeamList([]);
    form.resetFields();
  };

  const onChangeEditIsFinal = e => {
    setEditIsFinalPool(`${e.target.checked}`);
  };

  const OnClickRemovePoolTeams = async (index, e) => {
    var newData = selectedNewPoolTeamList;
    newData.splice(index, 1);
    setSelectedNewPoolTeamList([...newData]);
    loadAllEventPools();
  };

  let newSelectedTeams = [];
  const onChangeAddNewTeamList = async (value) => {
    newSelectedTeams = value;
  };

  const updateNewPoolDetails = async () => {
    const teamsIds = [];

    (selectedNewPoolTeamList || []).map(item => {
      teamsIds.push(item.team.id);
      return item
    })

    const finalTeamsIds = (teamsIds.concat(newSelectedTeams)).filter(function (el) {
      return el != null;
    });
    setloading(true);

    if (editIsFinalPool === 'true') {
      const values = {
        name: editPoolName,
        isFinal: editIsFinalPool,
        teamIds: [],
      }
      putData(EventApi.updateEditEventPool(eventId, editPoolId).url, values)
        .then((response) => {
          toastr.success('Successfully Submitted');
        })
        .catch((e) => {
          toastr.error(e.data.message);
        })
        .finally(() => {
          setloading(false);
          setIsSeparatePoolsModalVisible(false);
          setEditPoolId(null);
          setEditPoolName('');
          setEditIsFinalPool(false);
          setSelectedNewPoolTeamList([]);
          loadAllEventPools();
        })
    }
    else {
      if (finalTeamsIds.length === 0) {
        toastr.error('Please select Teams');
      } else {
        const values = {
          name: editPoolName,
          isFinal: editIsFinalPool,
          teamIds: finalTeamsIds,
        }
        putData(EventApi.updateEditEventPool(eventId, editPoolId).url, values)
          .then((response) => {
            toastr.success('Successfully Submitted');
          })
          .catch((e) => {
            toastr.error(e.data.message);
          })
          .finally(() => {
            setloading(false);
            setIsSeparatePoolsModalVisible(false);
            setEditPoolId(null);
            setEditPoolName('');
            setEditIsFinalPool(false);
            setSelectedNewPoolTeamList([]);
            loadAllEventPools();
          })
      }
    }


  }


  const renderPoolDetailsModal = () => {
    return (
      <div>
        <div className="modalForm">
          {
            editIsFinalPool !== 'true' && (
              <React.Fragment>
                <p><span className='redMandatory'>*</span><strong>Teams</strong></p>
                <ul className="selectedDisciplines">
                  {(selectedNewPoolTeamList || []).map((item, index) => (
                    <li key={item.team.id}>
                      <span>{item.team.name}</span>
                      <span>
                        <Popconfirm
                          title="Sure to delete?"
                          onConfirm={e => OnClickRemovePoolTeams(index, e)}
                          disabled={
                            eventProgressStatus === 'STARTED' ||
                              eventProgressStatus === 'COMPLETED' ? 'disabled' : null}
                        >
                          <Link className="rowDelete"><MinusCircleOutlined /></Link>
                        </Popconfirm>
                      </span>
                    </li>
                  ))}
                </ul>
                <p><strong>Add More Teams</strong></p>

                <Form
                  name="AddMoreDisciplines"
                  form={form}
                >
                  <Form.Item name="teamDisciplines">
                    <Select
                      mode="multiple"
                      allowClear
                      style={{ width: '100%' }}
                      placeholder="Please select"
                      onChange={onChangeAddNewTeamList}
                      disabled={
                        eventProgressStatus === 'STARTED' ||
                          eventProgressStatus === 'COMPLETED' ? 'disabled' : null}
                    >
                      {
                        eventPoolTeamsList.map(dis => (
                          <Option key={dis.id} value={dis.id}>{dis.name}</Option>
                        ))

                        // eventTeamList.filter((item) => !selectedNewPoolTeamList
                        // .some((item2) => item2.team.name === item.name))
                        // .map(dis => (
                        //   <Option key={dis.id} value={dis.id}>{dis.name}</Option>
                        // ))
                      }
                    </Select>
                  </Form.Item>
                </Form>
              </React.Fragment>
            )
          }

          <br />
          <p>
            <Checkbox
              onChange={onChangeEditIsFinal}
              checked={editIsFinalPool === 'true' ? true : false}
              style={{ border: 0 }}
              disabled={
                eventProgressStatus === 'STARTED' ||
                  eventProgressStatus === 'COMPLETED' ? 'disabled' : null}
            >
              <strong>Is Final Pool ?</strong>
            </Checkbox>
          </p>

        </div>
      </div>
    );
  };

  const renderPoolDetailsFooter = () => (
    <div className="steps-action">
      <Button type="default" onClick={() => onClickCancelPoolModal()}>Cancel</Button>
      <Button
        type="primary"
        onClick={() => updateNewPoolDetails()}
        disabled={
          eventProgressStatus === 'STARTED' ||
            eventProgressStatus === 'COMPLETED' ? 'disabled' : null}
      >Update</Button>
    </div>
  );

  //**********End Edit Pools***********//

  const renderPoolsTab = () => {
    if (loading) return <LoadingView message="Loading..." />;
    return (
      <Row gutter={16}>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
          <div className="buttonRow">
            {startEvent()}
            <Button
              type="primary"
              onClick={OpenCreatePoolModal}
              disabled={
                eventProgressStatus === 'STARTED' ||
                  eventProgressStatus === 'COMPLETED' ? 'disabled' : null}
              className={
                eventProgressStatus === 'STARTED' ||
                  eventProgressStatus === 'COMPLETED' ? 'buttonDisabled' : null}
            >
              Add Pools
            </Button>
          </div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
          <Table
            columns={poolsColumns}
            dataSource={eventPoolsList}
            rowKey={record => record.id}
          />
        </Col>
      </Row>
    );
  };

  //**********Pools End*************//
  //**********Users Start***********//

  const [isUsersModalVisible, setIsUsersModalVisible] = useState(false);
  const [isSeparateUsersModalVisible, setIsSeparateUsersModalVisible] = useState(false);
  const [editJudgeModalVisible, setEditJudgeModalVisible] = useState(false);

  const [userName, setUserName] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [selectedUserDisciplines, setSelectedUserDisciplines] = useState([]);

  const [editUserId, setEditUserId] = useState(null);
  const [editUserName, setEditUserName] = useState(null);
  const [editUserRoleId, setEditUserRoleId] = useState(null);
  const [editUserRole, setEditUserRole] = useState(null);
  const [selectedNewUserDisciplinesList, setSelectedNewUserDisciplinesList] = useState([]);
  const [selectedNewUserEventTeamList, setSelectedNewUserEventTeamList] = useState([]);

  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [showDrawerInfo, setShowDrawerInfo] = useState([]);
  const [selectedOldJudge, setSelectedOldJudge] = useState(null);
  const [selectedJudge, setSelectedJudge] = useState(null);
  const [selectedUserTeamsValue, setSelectedUserTeamsValue] = useState([]);

  const [current, setCurrent] = React.useState(0);
  const [currentEditUser, setCurrentEditUser] = React.useState(0);
  const [editUserAddNewDisciplines, setEditUserAddNewDisciplines] = useState([]);

  const [conflictOfInterestTeamsList, SetConflictOfInterestTeamsList] = useState([]);
  const [conflictOfInterestIdsList, SetConflictOfInterestIdsList] = useState([]);


  const showDrawer = (data) => {
    setShowDrawerInfo(data)
    setVisibleDrawer(true);
  };
  const onCloseDrawer = () => {
    setVisibleDrawer(false);
  };

  const usersColumns = [
    {
      title: 'User',
      dataIndex: 'user',
      sorter: (a, b) => a.user.length - b.user.length,
      render: (record, data) => {
        return (
          <React.Fragment>
            <span>
              <Link
                to={{
                  state: eventName
                }}
                onClick={(e) => onClickShowEditUserModal(record, data)}
              >
                {data.user.name}
              </Link>
            </span>
            {
              eventProgressStatus === 'STARTED' ? (
                <React.Fragment>
                  {data.isActive ? (
                    <React.Fragment>
                      {data.role.name === "Judge" ? (
                        <Tooltip title="Replace Judge">
                          <span className='JudgeEdit' onClick={(e) => onClickShowEditJudgeModal(record, data)}><EditOutlined /></span>
                        </Tooltip>

                      ) : null}
                      <span></span>
                    </React.Fragment>
                  ) : null}
                </React.Fragment>
              ) : null
            }


          </React.Fragment>
        )
      },
    },
    {
      title: 'Role',
      dataIndex: 'role',
      sorter: (a, b) => a.role.length - b.role.length,
      render: (record, data) => {
        return (
          <React.Fragment>{data.role.name}</React.Fragment>
        )
      },
    },
    {
      title: 'Disciplines',
      dataIndex: 'disciplines',
      render: (record, data) => {
        return (
          <React.Fragment>
            {data.disciplines.map(tag => (
              <span key={tag.name}>{tag.name}<span>, </span></span>
            ))}
          </React.Fragment>
        )
      },
    },
    {
      title: '',
      dataIndex: 'teams',
      render: (record, data) => {
        return (
          <React.Fragment>
            {data.role.name === "Judge" ? (
              <span
                className='showJudgeTeams'
                onClick={(e) => showDrawer(data.teams)}
              >
                Teams
              </span>
            ) : null}
          </React.Fragment>
        )
      },
    },
  ];

  let mergeTeamValue = [];
  let conflictOfInterestIdsValue = [];
  //**********Start Add Users***********//
  const OpenCreateUserModal = () => {
    SetConflictOfInterestIdsList([]);
    loadAllEventTeams();
    fetchAllUsers();
    setIsUsersModalVisible(true);
    setUserName(null);
    setUserRole(null);
    setSelectedUserDisciplines([]);

    const defaultUserTeamsValue = [];
    (eventTeamList || []).map(item => {
      defaultUserTeamsValue.push(Number(item.id))
      return item;
    })
    setSelectedUserTeamsValue(defaultUserTeamsValue);
    SetConflictOfInterestTeamsList(eventTeamList)
  };

  const CloseCreateUserModal = () => {
    setCurrent(0);
    setIsUsersModalVisible(false);
    setUserName(null);
    setUserRole(null);
    setSelectedUserDisciplines([]);
    setSelectedUserTeamsValue([]);
    SetConflictOfInterestIdsList([]);
  };

  const onChangeUserName = value => {
    setUserName(value);
  };

  const onChangeUserRole = async (value) => {
    setUserRole(value);
  };

  const onChangeUserDisciplines = checkedValues => {
    setSelectedUserDisciplines(checkedValues);
  };

  const onChangeSelectUserTeamsValue = checkedValues => {
    setSelectedUserTeamsValue(checkedValues);
    const results = eventTeamList.filter(({ id: id1 }) => checkedValues.some((id2) => id2 === id1));
    SetConflictOfInterestTeamsList(results)
  };

  const onChangeConflictOfInterest = values => {
    SetConflictOfInterestIdsList(values);
  };

  const callFunctionAdduser = (values) => {
    setloading(true);
    postData(EventApi.addAllEventUsers(eventId).url, values)
      .then((response) => {
        toastr.success('Successfully Submitted');
        loadAllEventUsers();
      })
      .catch((e) => {
        toastr.error(e.data.message);
        console.log(editUserRole);
      })
      .finally(() => {
        setCurrent(0);
        setIsUsersModalVisible(false);
        setloading(false)
        setUserName(null);
        setUserRole(null);
        setSelectedUserDisciplines([]);
        setSelectedUserTeamsValue([]);
      });
  };

  const updateAddUsers = async () => {

    mergeTeamValue = selectedUserTeamsValue.concat(conflictOfInterestIdsList);
    conflictOfInterestIdsValue = mergeTeamValue.filter((item, index) => mergeTeamValue.indexOf(item) !== index);

    const values = {
      'userId': userName,
      'roleId': userRole,
      'disciplineIds': selectedUserDisciplines,
      'teamIds': selectedUserTeamsValue,
      'conflictofInterestIds': conflictOfInterestIdsValue
    };

    if (userName === null || userName === "") {
      toastr.error('Please select User ');
    } else if (userRole === null || userRole === "") {
      toastr.error('Please select Role');
    } else if (userRole === 2) {
      if (selectedUserDisciplines.length === 0) {
        toastr.error('Please select disciplines');
      } else {
        callFunctionAdduser(values);
      }
    } else if (userRole === 1) {
      if (selectedUserTeamsValue.length === 0) {
        toastr.error('Please select team');
      } else {
        callFunctionAdduser(values);
      }
    } else {
      callFunctionAdduser(values);
    }
  }

  const next = () => {
    if (userName === null || userName === '') {
      toastr.error('Please select user ');
    } else if (userRole === null || userRole === "") {
      toastr.error('Please select role');
    } else if (userRole === 2 || userRole === 1) {
      if (selectedUserDisciplines.length === 0) {
        toastr.error('Please select disciplines');
      } else {
        setCurrent(current + 1);
      }
    } else {
      setCurrent(current + 1);
    }
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const renderUserFirstContent = () => (
    <React.Fragment>
      <p><strong>Users</strong></p>
      <Select
        showSearch
        placeholder="Select a person"
        optionFilterProp="children"
        onChange={onChangeUserName}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        value={userName}
      >
        {(usersList || []).map((user, index) => (
          <Option value={user.id} key={user.id}>{user.name}</Option>
        ))}

      </Select>
      <p><strong>Roles</strong></p>
      <Select
        allowClear
        style={{ width: '100%' }}
        placeholder="Please Role"
        onChange={onChangeUserRole}
        value={userRole}
      >
        {
          (rolesList || []).map(role => (
            <Option key={role.id} value={role.id}>{role.name}</Option>
          ))
        }
      </Select>
      {userRole === 1 || userRole === 2
        ? <div>
          <p><strong>Disciplines</strong></p>
          <Select
            mode="multiple"
            showSearch
            placeholder="Select Disciplines"
            optionFilterProp="children"
            onChange={onChangeUserDisciplines}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {(disciplineList || []).map((dis, index) => (
              <Option key={dis.id} value={dis.id}>{dis.name}</Option>
            ))}

          </Select>
        </div> : null}
    </React.Fragment>
  )

  const renderUserSecondContent = () => (
    <React.Fragment>
      {userRole === 1
        ? <React.Fragment>
          <div>
            <p><strong>Teams</strong></p>
            <Checkbox.Group
              style={{ width: '100%' }}
              onChange={onChangeSelectUserTeamsValue}
              className="disciplinesChecks"
              defaultValue={selectedUserTeamsValue}
            >
              <Row>

                {(eventTeamList || []).map((eTeam, index) => (
                  <Col span={8} key={eTeam.id}>
                    <Checkbox value={eTeam.id} key={eTeam.id}>{eTeam.name}</Checkbox>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
          </div>
          <br />
          <div>
            <p><strong>Conflict of Interest</strong></p>
            <Select
              mode="multiple"
              showSearch
              placeholder="Select Team"
              optionFilterProp="children"
              onChange={onChangeConflictOfInterest}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {(conflictOfInterestTeamsList || []).map((eTeam, index) => (
                <Option key={eTeam.id} value={eTeam.id}>{eTeam.name}</Option>
              ))}

            </Select>
          </div>
        </React.Fragment> : null}
    </React.Fragment>
  )

  const userSteps = userRole === 1 ? [
    {
      title: 'First',
      content: renderUserFirstContent(),
    },
    {
      title: 'Second',
      content: renderUserSecondContent(),
    }
  ] : [
    {
      title: 'First',
      content: renderUserFirstContent(),
    }
  ]

  const renderUsersModalContent = () => {
    if (loading) return <LoadingView message="Loading..." />;
    return (
      <React.Fragment>
        <div className="steps-content">{userSteps[current].content}</div>
      </React.Fragment>
    );
  };

  const renderUsersFooter = () => (
    <div className="steps-action">
      <Button type="default" onClick={() => CloseCreateUserModal()}>Close</Button>
      {current > 0 && (
        <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
          Previous
        </Button>
      )}
      {current < userSteps.length - 1 && (
        <Button type="primary" onClick={() => next()}>
          Next
        </Button>
      )}
      {current === userSteps.length - 1 && (
        <Button type="primary" onClick={() => updateAddUsers()}>Add User</Button>
      )}
    </div>
  );
  //**********End Add Users***********//
  //**********Start Edit Users***********//
  const onClickShowEditUserModal = (record, data) => {
    setIsSeparateUsersModalVisible(true);
    setEditUserId(data.user.id);
    setEditUserName(data.user.name);
    setEditUserRoleId(data.role.id);
    setEditUserRole(data.role.name);
    setSelectedNewUserDisciplinesList(data.disciplines);
    setSelectedNewUserEventTeamList(data.teams);

    const defaultUserTeamsValue = [];
    (data.teams || []).map(item => {
      defaultUserTeamsValue.push(Number(item.id))
      return item;
    })
    setSelectedUserTeamsValue(defaultUserTeamsValue);
    SetConflictOfInterestTeamsList(data.teams)

    const newArray = (data.teams || []).filter(function (el) {
      return el.isConflictofInterest === true
    }
    );

    const preSelectedConflictOfInterestIds = [];
    (newArray || []).map(item => {
      preSelectedConflictOfInterestIds.push(Number(item.id))
      return item;
    })
    SetConflictOfInterestIdsList(preSelectedConflictOfInterestIds);
    form.resetFields();
  };

  const onClickCloseEditUserModal = () => {
    setCurrentEditUser(0);
    setIsSeparateUsersModalVisible(false);
    setEditUserId(null);
    setEditUserName('');
    setEditUserRoleId(null);
    setEditUserRole('');
    setSelectedNewUserDisciplinesList([]);
    setSelectedNewUserEventTeamList([]);
    setSelectedUserTeamsValue([]);
    setEditUserAddNewDisciplines([])
    SetConflictOfInterestIdsList([])
  };

  const onClickCancelEditUserModal = () => {
    setCurrentEditUser(0);
    setIsSeparateUsersModalVisible(false);
    setEditUserId(null);
    setEditUserName('');
    setEditUserRoleId(null);
    setEditUserRole('');
    setSelectedNewUserDisciplinesList([]);
    setSelectedNewUserEventTeamList([]);
    setSelectedUserTeamsValue([]);
    setEditUserAddNewDisciplines([])
    SetConflictOfInterestIdsList([])
    form.resetFields();
  };

  const onChangeEditUserRoleId = (value) => {
    setEditUserRoleId(value);
  };

  const OnClickRemoveUserDisciplines = async (index, e) => {
    var newData = selectedNewUserDisciplinesList;
    newData.splice(index, 1);
    setSelectedNewUserDisciplinesList([...newData]);
  };

  const onChangeAddUserNewDisciplinesList = async (value) => {
    setEditUserAddNewDisciplines(value)
  };

  const callFunctionEdituser = (values) => {
    setloading(true);
    putData(EventApi.updateEditEventUser(eventId, editUserId).url, values)
      .then((response) => {
        toastr.success('Successfully Submitted');
      })
      .catch((e) => {
        toastr.error(e.data.message);
      })
      .finally(() => {
        setCurrentEditUser(0);
        setloading(false);
        setIsSeparateUsersModalVisible(false);
        setEditUserId(null);
        setEditUserName('');
        setEditUserRoleId(null);
        setEditUserRole('');
        setSelectedNewUserDisciplinesList([]);
        setSelectedNewUserEventTeamList([]);
        setSelectedUserTeamsValue([]);
        setEditUserAddNewDisciplines([])
        SetConflictOfInterestIdsList([]);
        loadAllEventUsers();
        loadAllRoles();
      })
  }

  const updateNewUserDetails = async () => {
    const disciplineIds = [];

    (selectedNewUserDisciplinesList || []).map(item => {
      disciplineIds.push(item.id);
      return item
    })

    let finaldiscipline = (disciplineIds.concat(editUserAddNewDisciplines)).filter(function (el) {
      return el != null;
    });

    const eventsTeamIds = [];

    (selectedNewUserEventTeamList || []).map(item => {
      eventsTeamIds.push(item.id);
      return item
    })

    const deleteUserTeamsIds = eventsTeamIds.filter(item => !selectedUserTeamsValue.includes(item));

    let newConflictOfInterestIdsList = [];
    let V1 = [];

    V1 = selectedUserTeamsValue.concat(conflictOfInterestIdsList);
    newConflictOfInterestIdsList = V1.filter((item, index) => V1.indexOf(item) !== index);

    const deletedConflictofInterestIdsList = selectedUserTeamsValue.filter(item => !newConflictOfInterestIdsList.includes(item));

    if (editUserRoleId !== 1 && editUserRoleId !== 2) {
      finaldiscipline = [];
    }

    const values = {
      roleId: editUserRoleId,
      disciplineIds: finaldiscipline,
      teamIds: selectedUserTeamsValue,
      deletedTeamIds: deleteUserTeamsIds,
      conflictofInterestIds: newConflictOfInterestIdsList,
      deletedConflictofInterestIds: deletedConflictofInterestIdsList
    }
    if (editUserRoleId === 1) {
      if (selectedUserTeamsValue.length === 0) {
        toastr.error('Please select Teams');
      } else {
        callFunctionEdituser(values);
      }
    } else {
      callFunctionEdituser(values);
    }

  }

  const EditUserNext = () => {
    setCurrentEditUser(currentEditUser + 1);
  };

  const EditUserPrev = () => {
    setCurrentEditUser(currentEditUser - 1);
  };

  const renderEditUserFirstContent = () => (
    <React.Fragment>
      <p><span className="redMandatory">*</span><strong>Role</strong></p>
      <Select
        allowClear
        style={{ width: '100%' }}
        onChange={onChangeEditUserRoleId}
        value={editUserRoleId}
        disabled={
          eventProgressStatus === 'STARTED' ||
            eventProgressStatus === 'COMPLETED' ? 'disabled' : null}
      >
        {
          (rolesList || []).map(role => (
            <Option key={role.id} value={role.id}>{role.name}</Option>
          ))
        }
      </Select>
      <Form
        name="AddMoreDisciplines"
        form={form}
      >
        {
          editUserRoleId === 1 || editUserRoleId === 2 ? (
            <React.Fragment>
              <p><span className="redMandatory">*</span><strong>Disciplines</strong></p>
              <ul className="selectedDisciplines">
                {(selectedNewUserDisciplinesList || []).map((item, index) => (
                  <li key={item.id}>
                    <span>{item.name}</span>
                    <span>
                      <Popconfirm
                        title="Sure to delete?"
                        onConfirm={e => OnClickRemoveUserDisciplines(index, e)}
                        disabled={
                          eventProgressStatus === 'STARTED' ||
                            eventProgressStatus === 'COMPLETED' ? 'disabled' : null}
                      >
                        <Link className="rowDelete"><MinusCircleOutlined /></Link>
                      </Popconfirm>
                    </span>
                  </li>
                ))}
              </ul>
              <p><strong>Add More Disciplines</strong></p>
              <Form.Item name="disciplines">
                <Select
                  mode="multiple"
                  placeholder="Select Disciplines"
                  onChange={onChangeAddUserNewDisciplinesList}
                  allowClear
                  disabled={
                    eventProgressStatus === 'STARTED' ||
                      eventProgressStatus === 'COMPLETED' ? 'disabled' : null}
                >
                  {
                    disciplineList.filter(({ name: id1 }) => !selectedNewUserDisciplinesList
                      .some(({ name: id2 }) => id2 === id1))
                      .map(dis => (
                        <Option key={dis.id} value={dis.id}>{dis.name}</Option>
                      ))
                  }
                </Select>
              </Form.Item>
            </React.Fragment>
          ) : null
        }

      </Form>
    </React.Fragment>
  )
  const renderEditUsersecondContent = () => (
    <React.Fragment>
      {editUserRoleId === 1
        ? <React.Fragment>
          <div>
            <p><strong>Teams</strong></p>
            <Checkbox.Group
              style={{ width: '100%' }}
              onChange={onChangeSelectUserTeamsValue}
              className="disciplinesChecks"
              defaultValue={selectedUserTeamsValue}
            >
              <Row>

                {(eventTeamList || []).map((eTeam, index) => (
                  <Col span={8} key={eTeam.id}>
                    <Checkbox value={eTeam.id} key={eTeam.id}>{eTeam.name}</Checkbox>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
          </div>
          <br />
          <div>
            <p><strong>Conflict of Interest</strong></p>
            <Select
              mode="multiple"
              showSearch
              placeholder="Select Team"
              optionFilterProp="children"
              onChange={onChangeConflictOfInterest}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              defaultValue={conflictOfInterestIdsList}
            >
              {(conflictOfInterestTeamsList || []).map((eTeam, index) => (
                <Option key={eTeam.id} value={eTeam.id}>{eTeam.name}</Option>
              ))}

            </Select>
          </div>
        </React.Fragment> : null}
    </React.Fragment>
  )

  const editUserSteps = editUserRoleId === 1 ? [
    {
      title: 'First',
      content: renderEditUserFirstContent(),
    },
    {
      title: 'Second',
      content: renderEditUsersecondContent(),
    }
  ] : [
    {
      title: 'First',
      content: renderEditUserFirstContent(),
    }
  ]

  const renderUserDetailsModal = () => {
    return (
      <div className="modalForm">
        <div className="steps-content">{editUserSteps[currentEditUser].content}</div>
      </div>
    );
  };

  const renderUserDetailsFooter = () => (

    <div className="steps-action">
      <Button type="default" onClick={() => onClickCancelEditUserModal()}>Cancel</Button>
      {currentEditUser > 0 && (
        <Button style={{ margin: '0 8px' }} onClick={() => EditUserPrev()}>
          Previous
        </Button>
      )}
      {currentEditUser < editUserSteps.length - 1 && (
        <Button type="primary" onClick={() => EditUserNext()}>
          Next
        </Button>
      )}
      {currentEditUser === editUserSteps.length - 1 && (
        <Button
          type="primary"
          onClick={() => updateNewUserDetails()}
          disabled={
            eventProgressStatus === 'STARTED' ||
              eventProgressStatus === 'COMPLETED' ? 'disabled' : null}
          className={
            eventProgressStatus === 'STARTED' ||
              eventProgressStatus === 'COMPLETED' ? 'buttonDisabled' : null}
        >Update</Button>
      )}
    </div>
  );
  //**********End Edit Users***********//

  //**********Start Edit Judge Modal***********//

  const onClickShowEditJudgeModal = (record, data) => {
    setEditJudgeModalVisible(true);
    fetchAllUsers();
    setSelectedOldJudge(data.user.id)
    setSelectedJudge(null)
    setEditUserRoleId(data.role.id);
    setEditUserName(data.user.name);
  };
  // 
  const onClickCloseEditJudgeModal = () => {
    setEditJudgeModalVisible(false);
    setSelectedOldJudge(null)
    setSelectedJudge(null)
    setEditUserId(null);
    setEditUserRoleId(null);
    setEditUserName('');
  };


  const onChangSelectNewJudge = (value) => {
    setSelectedJudge(value)
  };

  const updateEventJudge = async () => {
    if (selectedJudge === null || selectedJudge === "") {
      toastr.error('Please select Judge ');
    } else if (editUserRoleId === 0 || editUserRoleId === "") {
      toastr.error('Please select Role');
    } else {
      const values = {
        'userId': selectedJudge,
        'roleId': editUserRoleId,
      };

      setloading(true);

      postData(EventApi.updateEventJudge(eventId, selectedOldJudge).url, values)
        .then((response) => {
          toastr.success('Successfully Submitted');
          loadAllEventUsers();
        })
        .catch((e) => {
          toastr.error(e.data.message);
        })
        .finally(() => {
          onClickCloseEditJudgeModal(false);
          setloading(false)
          loadAllEventUsers();
        });
    }
  }

  const renderEventJudgeModal = () => {
    return (
      <div className="modalForm">

        <p><span className="redMandatory">*</span><strong>Role</strong></p>
        <Select
          allowClear
          style={{ width: '100%' }}
          onChange={onChangeEditUserRoleId}
          value={editUserRoleId}
          disabled
        >
          {
            (rolesList || []).map(role => (
              <Option key={role.id} value={role.id}>{role.name}</Option>
            ))
          }
        </Select>
        <p><span className="redMandatory">*</span><strong>Replace Judge By</strong></p>
        <Select
          style={{ width: '100%' }}
          showSearch
          placeholder="Select a Judge"
          optionFilterProp="children"
          onChange={onChangSelectNewJudge}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          value={selectedJudge === null ? '' : selectedJudge}
        >
          {(usersList || []).map((user, index) => (
            <Option value={user.id} key={user.id}>{user.name}</Option>
          ))}

        </Select>
      </div>
    );
  };

  const renderEventJudgeFooter = () => (
    <div className="steps-action">
      <Button type="default" onClick={() => onClickCloseEditJudgeModal()}>Cancel</Button>
      <Button type="primary" onClick={() => updateEventJudge()}>Update</Button>
    </div>
  );
  //**********End Edit Judge Modal***********//
  const renderUsersTab = () => {
    return (
      <Row gutter={16}>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
          <div className="buttonRow">
            {startEvent()}
            <Button
              type="primary"
              onClick={OpenCreateUserModal}
              disabled={
                eventProgressStatus === 'STARTED' ||
                  eventProgressStatus === 'COMPLETED' ? 'disabled' : null}
              className={
                eventProgressStatus === 'STARTED' ||
                  eventProgressStatus === 'COMPLETED' ? 'buttonDisabled' : null}
            >
              Add Users
            </Button>
          </div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
          <Table
            columns={usersColumns}
            dataSource={eventUsersList}
            rowKey={record => record.id}
            // rowClassName={record => `${record.isActive}`}
            rowClassName={record => record.isActive === false && "disabled-row"}
          />
        </Col>
      </Row>
    );
  };
  //**********Users End************//

  //**********Score Start***********//
  const [scorePoolModalVisible, setScorePoolModalVisible] = useState(false);
  const [scorePoolId, setScorePoolId] = useState(null);
  const [scoreTeamId, setScoreTeamId] = useState(null);
  const [scoreDisciplineId, setScoreDisciplineId] = useState(null);
  const [selectedScoreList, setSelectedScoreList] = useState([]);

  const [finalTeamList, setFinalTeamList] = useState([]);
  const [selectedPoolTeams, setSelectedPoolTeams] = useState([]);
  const [selectedPoolTeamsDisciplines, setSelectedPoolTeamsDisciplines] = useState([]);
  const [penaltyScoreList, setPenaltyScoreList] = useState([]);
  const [totalPenaltyScore, setTotalPenaltyScore] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const scoreColumns = [
    {
      title: 'Judge',
      dataIndex: 'name',
      width: '50%',
      render: (record, val) => {
        return (
          `${record}`
        )
      },
    },
    {
      title: 'Score',
      dataIndex: 'score',
      width: '15%',
      render: (record, val) => {
        return (
          <span>{record !== null ? (`${record}`) : '--'}</span>
        )
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: '35%',
      render: (record, val) => {
        return (
          <span>
            {
              record === 'APPROVED' ? 'APPROVED'
                : (record === 'HEAD_JUDGE_REVIEW_REQUIRED' ? 'Head Judge Review'
                  : (record === 'JUDGE_RESUBMITTED' ? 'Moderator Review'
                    : (record === 'JUDGE_REVIEW_REQUIRED' ? 'Sent back to Judge'
                      : (record === 'JUDGE_SUBMITTED' ? 'Moderator Review'
                        : 'Judge not Submitted'
                      )
                    )
                  )
                )
            }
          </span>
        )
      },
    },
  ];

  const TopTeamColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      width: '50%',
      render: (record) => {
        return (
          `${record}`
        )
      },
    },
    {
      title: 'Score',
      dataIndex: 'score',
      width: '25%',
      render: (record) => {
        return (
          `${record}`
        )
      },
    },
    {
      title: 'Final Position',
      dataIndex: 'finalPosition',
      width: '25%',
      render: (record) => {
        return (
          `${record}`
        )
      },
    },
  ];

  const scorePoolShowModal = (poolId, e) => {
    //e.stopPropagation()
    setScorePoolModalVisible(true);
    setScorePoolId(poolId);
    getData(EventApi.getFinalTeams(eventId, poolId).url)
      .then((response) => {
        const data = response.data.finalTeams;
        setFinalTeamList(
          (data || []).map((item, index) => ({
            key: index,
            id: index,
            name: item.team.name,
            score: item.score,
            finalPosition: item.finalPosition,
          }))
        );
      })
      .catch((e) => {
        setErrorMessage(e.data.message);
        // toastr.error(e.data.message);
        console.log(e.data.message);
      })
  };

  const scorePoolCancelModal = () => {
    setScorePoolModalVisible(false);
    setFinalTeamList([]);
    setErrorMessage('');
  };

  const scorePoolSubmitModal = () => {
    postData(EventApi.movefinalizeTeams(eventId, scorePoolId).url)
      .then((response) => {
        toastr.success('Successfully Submitted');
      })
      .catch((e) => {
        toastr.error(e.data.message);
      })
      .finally(() => {
        setScorePoolModalVisible(false);
        setFinalTeamList([]);
        setErrorMessage('');
      });
  };

  const singlePoolMove = (e, eventID, poolID, teamID) => {
    e.stopPropagation()
    if (e.defaultPrevented) return;
    console.log('eventId', eventID);
    console.log('scorePoolId', poolID);
    console.log('teamId', teamID);
    postData(EventApi.singlePoolMoveTeam(eventID, poolID, teamID).url)
      .then((response) => {
        toastr.success('Successfully Submitted');
      })
      .catch((e) => {
        if(e.data.message === undefined){
          toastr.error(e.data.error);
        }else{
          toastr.error(e.data.message);
        }
      })
      .finally(() => {
        setFinalTeamList([]);
        setErrorMessage('');
      });

  };
  
  const confirmPoolMove = (e, eventID, poolID) => {
    e.stopPropagation()
    if (e.defaultPrevented) return;
    console.log('eventId', eventID);
    console.log('scorePoolId', poolID);
    postData(EventApi.publishFinalizeTeams(eventID, poolID).url)
      .then((response) => {
        toastr.success('Successfully Submitted');
      })
      .catch((e) => {
        toastr.error(e.data.message);
      })
      .finally(() => {
        setFinalTeamList([]);
        setErrorMessage('');
        setScorePoolId(null);
      });

  };
  const cancel = (e) => {
    e.stopPropagation()
  };

  function poolCallback(key) {
    setScorePoolId(key);
    if (key) {
      getData(EventApi.getJudgesTeamsScore(eventId, key).url)
        .then((response) => {
          const data = response.data.eventTeams;
          setSelectedPoolTeams(data);
        })
        .catch((e) => {
          console.log(scorePoolId);
          toastr.error(e.data.message);
        })
    }
  }

  const teamCallback = async (key) => {
    setScoreTeamId(key);
    if (key) {
      getData(EventApi.getJudgesTeamsDisciplinesScore(eventId, scorePoolId, key).url)
        .then((response) => {
          const data = response.data.eventTeam.disciplineScores;
          setSelectedPoolTeamsDisciplines(data);
        })
        .catch((e) => {
          toastr.error(e.data.message);
        })

      getData(EventApi.getPenalties(eventId, scorePoolId, key).url)
        .then((response) => {
          const data = response.data.penalties;
          setPenaltyScoreList([data]);
          setTotalPenaltyScore([data][0].totalPenalty);
        })
        .catch((e) => {
          toastr.error(e.data.message);
        })
    }
  }

  function disciplineCallback(key) {
    setScoreDisciplineId(key);
    if (key) {

      getData(EventApi.getJudgesScores(eventId, scorePoolId, scoreTeamId, key).url)
        .then((response) => {
          const data = response.data.judgesAndScores;
          setSelectedScoreList(data);
        })
        .catch((e) => {
          console.log(scoreDisciplineId);
        })
    }
  }

  function disciplineChildrenCallback(key) {

    if (key) {
      getData(EventApi.getJudgesScoresChild(eventId, scorePoolId, scoreTeamId, scoreDisciplineId, key).url)
        .then((response) => {
          const data = response.data.judgesAndScores;
          setSelectedScoreList(data);
        })
        .catch((e) => {
          console.log(scoreDisciplineId);
        })
    }
  }

  const onClickGetCalculateTeamScores = (id, e) => {
    e.stopPropagation()
    postData(EventApi.getCalculateTeamScores(eventId, scorePoolId, id).url)
      .then((response) => {
        toastr.success('Successfully Submitted');
        setloading(false);
      })
      .catch((e) => {
        toastr.error(e.data.message);
      })
      .finally(() => {
        setloading(false)
        getData(EventApi.getJudgesTeamsScore(eventId, scorePoolId).url)
          .then((response) => {
            const data = response.data.eventTeams;
            setSelectedPoolTeams(data);
          })
      });
  }

  const genExtra = (id, e) => {
    e.stopPropagation()

    postData(EventApi.getCalculateDisciplineScores(eventId, scorePoolId, scoreTeamId, id).url)
      .then((response) => {
        toastr.success('Successfully Submitted');
        setloading(false);
      })
      .catch((e) => {
        toastr.error(e.data.message);
      })
      .finally(() => {
        setloading(false)
        getData(EventApi.getJudgesTeamsDisciplinesScore(eventId, scorePoolId, scoreTeamId).url)
          .then((response) => {
            const data = response.data.eventTeam.disciplineScores;
            setSelectedPoolTeamsDisciplines(data);
          })
      });
  }

  const genExtraChild = (key, e) => {
    e.stopPropagation()

    postData(EventApi.getCalculateDisciplineScoresChild(eventId, scorePoolId, scoreTeamId, scoreDisciplineId, key).url)
      .then((response) => {
        toastr.success('Successfully Submitted');
        setloading(false);
      })
      .catch((e) => {
        toastr.error(e.data.message);
      })
      .finally(() => {
        setloading(false)
        getData(EventApi.getJudgesTeamsDisciplinesScore(eventId, scorePoolId, scoreTeamId).url)
          .then((response) => {
            const data = response.data.eventTeam.disciplineScores;
            setSelectedPoolTeamsDisciplines(data);
          })
      });
  }

  const reportDownload = (scoreTeamId, e) => {
    e.stopPropagation()

    getPdfData(EventApi.getReportDownload(eventId, scorePoolId, scoreTeamId).url)
      .then((response) => {
      })
      .catch((err) => {
        /* For getting Error */
        if (err.status === 400) {
          getData(EventApi.getReportDownload(eventId, scorePoolId, scoreTeamId).url)
            .then((response) => {
            })
            .catch((err) => {
              setErrorMessage(err.data.message);
              toastr.error(err.data.message);
            })
        } else if (err.status === 500) {
          toastr.error('Internal Server Error');
        }
        /* For getting Error */
      })
  };

  const TopTeamsInInitialPoolsReport = (e) => {
    e.stopPropagation()

    getPdfData(EventApi.getTopTeamsInInitialPoolsReport(eventId).url)
      .then((response) => {
      })
      .catch((err) => {
        /* For getting Error */
        if (err.status === 400) {
          getData(EventApi.getTopTeamsInInitialPoolsReport(eventId).url)
            .then((response) => {
            })
            .catch((err) => {
              setErrorMessage(err.data.message);
              toastr.error(err.data.message);
            })
        } else if (err.status === 500) {
          toastr.error('Internal Server Error');
        }
        /* For getting Error */
      })
  };

  const TopTeamsInEachDisciplineReport = (e) => {
    e.stopPropagation()

    getPdfData(EventApi.getTopTeamsInEachDisciplineReport(eventId).url)
      .then((response) => {
      })
      .catch((err) => {
        /* For getting Error */
        if (err.status === 400) {
          getData(EventApi.getTopTeamsInEachDisciplineReport(eventId).url)
            .then((response) => {
            })
            .catch((err) => {
              setErrorMessage(err.data.message);
              toastr.error(err.data.message);
            })
        } else if (err.status === 500) {
          toastr.error('Internal Server Error');
        }
        /* For getting Error */
      })
  };

  const downloadFinalPoolTeamsScores = (e) => {
    e.stopPropagation()

    getPdfData(EventApi.getTeamPlacingsInFinalPoolReport(eventId).url)
      .then((response) => {
      })
      .catch((err) => {
        /* For getting Error */
        if (err.status === 400) {
          getData(EventApi.getTeamPlacingsInFinalPoolReport(eventId).url)
            .then((response) => {
            })
            .catch((err) => {
              setErrorMessage(err.data.message);
              toastr.error(err.data.message);
            })
        } else if (err.status === 500) {
          toastr.error('Internal Server Error');
        }
        /* For getting Error */
      })
  };

  const downloadMasterReport = (e) => {
    e.stopPropagation()

    getExcelData(EventApi.getMasterReport(eventId).url)
      .then((response) => {
      })
      .catch((err) => {
        /* For getting Error */
        if (err.status === 400) {
          getData(EventApi.getMasterReport(eventId).url)
            .then((response) => {
            })
            .catch((err) => {
              setErrorMessage(err.data.message);
              toastr.error(err.data.message);
            })
        } else if (err.status === 500) {
          toastr.error('Internal Server Error');
        }
        /* For getting Error */
      })
  };

  const renderScoreTab = () => {
    if (loading) return <LoadingView message="Loading..." />;
    return (
      <div className="scoreBoard">
        <Row gutter={16}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
            <div className="buttonRow">
              {startEvent()}
            </div>
          </Col>
        </Row>
        <Collapse onChange={poolCallback} accordion className="poolAccordion">
          {
            (scoreEventPoolsList || []).map(EPL => (
              <Panel
                header={EPL.name}
                key={EPL.id}
                extra={
                  !EPL.isFinal ? (<div><span
                    className="scoreAccordianBtn"
                    onClick={e => scorePoolShowModal(EPL.id, e)}
                  >
                    Top Teams
                  </span>
                    <Popconfirm
                      title={"Do you want to freeze the teams moving to the final pool from '" + EPL.name + "'?"}
                      //description="Are you sure to pool finalized?"
                      onConfirm={e => confirmPoolMove(e, eventId ,EPL.id)}
                      onCancel={cancel}
                      okText="Yes"
                      cancelText="No"
                    >
                      <span className='movePool' onClick={e => e.stopPropagation()}><UserSwitchOutlined />Freeze teams to final pool</span>
                    </Popconfirm>
                  </div>
                  ) : null

                }
              >
                <h6>Teams</h6>
                <Collapse onChange={teamCallback} accordion className="teamAccordion">
                  {
                    (selectedPoolTeams || []).map(EPTL => (
                      <Panel
                        header={EPTL.team.name}
                        key={EPTL.team.id}
                        extra={EPTL.score !== null ? (
                          <React.Fragment>

                            <span>{EPTL.score}</span>
                            {!EPL.isFinal ? <Popconfirm
                              title={"Do you want to move '" + EPTL.team.name + "' to the final pool?"}
                              //description="Are you sure to move team?"
                              onConfirm={e => singlePoolMove(e, eventId, scorePoolId, EPTL.team.id)}
                              onCancel={cancel}
                              okText="Yes"
                              cancelText="No"
                            >
                              <span className='movePool' onClick={e => e.stopPropagation()}><UserSwitchOutlined /> Move to final pool </span>
                            </Popconfirm> : null}

                            <Tooltip title="Download Report">
                              <span
                                className="scoreAccordianBtn"
                                onClick={e => reportDownload(EPTL.team.id, e)}
                              >
                                <DownloadOutlined />
                              </span>
                            </Tooltip>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <span
                              className="scoreAccordianBtn"
                              onClick={e => onClickGetCalculateTeamScores(EPTL.team.id, e)}
                            >
                              Calculate score
                            </span>
                          </React.Fragment>
                        )
                        }
                      >
                        <h6>Penalty</h6>
                        <Collapse accordion className="disciplineAccordion">
                          <Panel
                            header='Total Penalty'
                            extra={<span className="">{totalPenaltyScore}</span>}
                          >
                            {
                              (penaltyScoreList || []).map(item => (
                                <React.Fragment>
                                  <div className="PSL-Row">
                                    <div className="PSL-Col2">Waiata ā-tira total time taken penalty</div>
                                    <div className="PSL-Col2">{item.penaltyForWaiataAtira}</div>
                                  </div>
                                  <div className="PSL-Row">
                                    <div className="PSL-Col2">Compulsory disciplines total time taken penalty</div>
                                    <div className="PSL-Col2">{item.penaltyForCompulsoryDisciplines}</div>
                                  </div>
                                  <br />
                                  <p><strong>Instrument violation</strong></p>
                                  {
                                    (item.disciplineViolations || []).map(disc => (
                                      <div className="PSL-Row">
                                        <div className="PSL-Col3">{disc.discipline.name}</div>
                                        <div className="PSL-Col3">{disc.instrument.name}</div>
                                        <div className="PSL-Col3">{disc.penalty}</div>
                                      </div>
                                    ))
                                  }
                                </React.Fragment>
                              ))
                            }

                          </Panel>
                        </Collapse>
                        <h6>Disciplines</h6>
                        <Collapse
                          onChange={disciplineCallback}
                          accordion className="disciplineAccordion">
                          {
                            (selectedPoolTeamsDisciplines || []).map(EPTDL => (
                              <Panel
                                header={EPTDL.discipline.name}
                                key={EPTDL.discipline.id}
                                extra={EPTDL.score !== null ? (
                                  <span>{EPTDL.score}</span>
                                ) : (
                                  <span
                                    className="scoreAccordianBtn"
                                    onClick={e => genExtra(EPTDL.discipline.id, e)}
                                  >
                                    Calculate score
                                  </span>
                                )
                                }
                              >
                                {
                                  (EPTDL.children || []).length > 0 ? (
                                    <React.Fragment>
                                      <Collapse onChange={disciplineChildrenCallback} accordion>
                                        {(EPTDL.children || []).map(item => (
                                          <Panel
                                            header={item.name}
                                            key={item.name}
                                            extra={item.score !== null ? (
                                              <span>{item.score}</span>
                                            ) : (
                                              <span
                                                className="scoreAccordianBtn"
                                                onClick={e => genExtraChild(item.name, e)}
                                              >
                                                Calculate score
                                              </span>
                                            )
                                            }
                                          >
                                            <Table
                                              dataSource={selectedScoreList}
                                              columns={scoreColumns}
                                              pagination={false}
                                            />
                                          </Panel>
                                        ))
                                        }

                                      </Collapse>
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment>
                                      <Table
                                        dataSource={selectedScoreList}
                                        columns={scoreColumns}
                                        pagination={false}
                                      />
                                    </React.Fragment>
                                  )
                                }

                              </Panel>
                            ))
                          }
                        </Collapse>
                      </Panel>
                    ))
                  }
                </Collapse>
              </Panel>
            ))
          }
        </Collapse>
      </div>
    );
  };

  const finalTeamModalFooter = () => (
    <div>
      {errorMessage ? null : (
        <React.Fragment>
          {finalTeamList.length === 0 ? null : (

            <React.Fragment>
              <Button type="default" onClick={() => scorePoolCancelModal()}>Cancel</Button>
              <Button type="primary" onClick={() => scorePoolSubmitModal()} style={{ display: 'none' }}>Move to final pool</Button>
            </React.Fragment>
          )}

        </React.Fragment>
      )}
    </div>
  );
  //**********Score End*************//

  const renderReportsTab = () => {
    if (loading) return <LoadingView message="Loading..." />;
    return (
      <div className="scoreBoard">
        <Row gutter={16}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
            <div className="buttonRow">
              {startEvent()}
            </div>
          </Col>
        </Row>
        <Collapse collapsible="disabled" className="poolAccordion reportsTabPanel">
          <Panel
            header="Top Teams in Initial pools"
            showArrow={false}
            key="1"
            extra={
              <React.Fragment>
                <Tooltip title="Download Report">
                  <span
                    className="scoreAccordianBtn"
                    onClick={e => TopTeamsInInitialPoolsReport(e)}
                  >
                    <DownloadOutlined />
                  </span>
                </Tooltip>
              </React.Fragment>
            }
          >
            <p>Top Teams in Initial pools</p>
          </Panel>
          <Panel
            header="Top Teams in Each Discipline"
            showArrow={false}
            key="2"
            extra={
              <React.Fragment>
                <Tooltip title="Download Report">
                  <span
                    className="scoreAccordianBtn"
                    onClick={e => TopTeamsInEachDisciplineReport(e)}
                  >
                    <DownloadOutlined />
                  </span>
                </Tooltip>
              </React.Fragment>
            }
          >
            <p>Top Teams in Each Discipline</p>
          </Panel>
          <Panel
            header="Final Pool Teams Scores"
            showArrow={false}
            key="3"
            extra={
              <React.Fragment>
                <Tooltip title="Download Report">
                  <span
                    className="scoreAccordianBtn"
                    onClick={e => downloadFinalPoolTeamsScores(e)}
                  >
                    <DownloadOutlined />
                  </span>
                </Tooltip>
              </React.Fragment>
            }
          >
            <p>Final Pool Teams Scores</p>
          </Panel>
          <Panel
            header="Master Report"
            showArrow={false}
            key="4"
            extra={
              <React.Fragment>
                <Tooltip title="Download Report">
                  <span
                    className="scoreAccordianBtn"
                    onClick={e => downloadMasterReport(e)}
                  >
                    <DownloadOutlined />
                  </span>
                </Tooltip>
              </React.Fragment>
            }
          >
            <p>Master Report</p>
          </Panel>
        </Collapse>
      </div>
    );
  }

  function tabsCallBack(key) {
    if (key === 'teamsTab') {
      loadAllEventTeams();
    } else if (key === 'poolsTab') {
      loadAllEventPools();
    } else if (key === 'usersTab') {
      loadAllEventUsers();
      loadAllRoles();
    } else if (key === 'scoreTab') {
      loadAllEventPools();
    }
  }

  return (
    <PageLayout>
      <div className="headingName">
        {eventName}
      </div>
      <Tabs tabPosition="left" className="tabStyle" onChange={tabsCallBack}>
        <TabPane tab="Teams" key="teamsTab" className="teamsTab">
          {renderTeamsTab()}
        </TabPane>
        <TabPane tab="Pools" key="poolsTab" className="poolsTab">
          {renderPoolsTab()}
        </TabPane>
        <TabPane tab="Users" key="usersTab" className="usersTab">
          {renderUsersTab()}
        </TabPane>
        <TabPane tab="Score" key="scoreTab" className="scoreTab">
          {renderScoreTab()}
        </TabPane>
        <TabPane tab="Reports" key="reportsTab" className="reportsTab">
          {renderReportsTab()}
        </TabPane>
      </Tabs>
      <Modal
        className="addTeamModal"
        title={renderSteps()}
        visible={isTeamModalVisible}
        onCancel={CloseCreateTeamModal}
        footer={renderTeamsFooter()}
      >
        {renderTeamsModalContent()}
      </Modal>
      {renderTeamDetailsModal()}
      <Modal
        className="addTeamModal"
        title={renderPoolSteps()}
        visible={isPoolsModalVisible}
        onCancel={CloseCreatePoolModal}
        footer={renderPoolsFooter()}
      >
        {renderPoolsModalContent()}
      </Modal>
      <Modal
        title={poolName}
        visible={isSeparatePoolsModalVisible}
        okText="Update"
        onOk={onClickClosePoolModal}
        onCancel={onClickCancelPoolModal}
        footer={renderPoolDetailsFooter()}
      >
        {renderPoolDetailsModal()}
      </Modal>
      <Modal
        className="eventUsersModal"
        title='Add User'
        visible={isUsersModalVisible}
        onCancel={CloseCreateUserModal}
        footer={renderUsersFooter()}
      >
        {renderUsersModalContent()}
      </Modal>
      <Modal
        className="eventUsersModal"
        title={editUserName}
        visible={isSeparateUsersModalVisible}
        okText="Update"
        onOk={onClickCloseEditUserModal}
        onCancel={onClickCancelEditUserModal}
        footer={renderUserDetailsFooter()}
      >
        {renderUserDetailsModal()}
      </Modal>
      <Modal
        className="finalizedTeamModal"
        title='Final Teams'
        visible={scorePoolModalVisible}
        // okText="Finalized Teams"
        // onOk={scorePoolSubmitModal}
        onCancel={scorePoolCancelModal}
        footer={finalTeamModalFooter()}
      >

        {errorMessage ? (
          <p className="errorMsg"><strong>{errorMessage}</strong></p>
        ) : (
          <Table
            dataSource={finalTeamList}
            columns={TopTeamColumns}
            pagination={false}
          />
        )}
      </Modal>
      <Modal
        className="eventEditJudgeModal"
        title={`Replace Judge - ${editUserName}`}
        visible={editJudgeModalVisible}
        okText="Update"
        // onOk={onClickCloseEditJudgeModal}
        onCancel={onClickCloseEditJudgeModal}
        footer={renderEventJudgeFooter()}
      >
        {renderEventJudgeModal()}
      </Modal>
      <Drawer title="Teams" placement="right" onClose={onCloseDrawer} visible={visibleDrawer} className="judgeTeamsList">
        <p><strong>Teams</strong></p>
        <ul>
          {
            (showDrawerInfo || []).map(item => (
              <li>{item.name}</li>
            ))
          }
        </ul>
        <br />
        {
          showDrawerInfo.filter(d => d.isConflictofInterest === true).length > 0 ? (
            <React.Fragment>
              <p><strong>Conflict of Interest</strong></p>
              <ul>
                {

                  showDrawerInfo.filter(d => d.isConflictofInterest === true)
                    .map(COI => (<li>{COI.name}</li>))
                }
              </ul>
            </React.Fragment>
          ) : null
        }
      </Drawer>
    </PageLayout>
  )
}


export default EventsDetails
