import React, { useState, useEffect, useRef } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { LoadingView } from "../../common";

import toastr from "toastr";
import { EventApi, MehiApi } from "../../../api";
import { getData, postData } from "../../../_services/apiCommonService";

import { eventId } from "../../../_services/auth";
import {
  Row,
  Col,
  Collapse,
  Button,
  Checkbox,
  InputNumber,
  Input,
  Breadcrumb,
  Popconfirm,
} from "antd";
import "../../Judges/Event/EventTeams.scss";

const EventJudges = (props) => {
  const location = useLocation();
  if (location.state === undefined) {
    window.location.href = "/";
  }

  let eventName = location.state.eventName;
  let teamName = location.state.teamName;
  let poolId = location.state.poolId;

  const { tId } = useParams();
  const history = useHistory();
  const { Panel } = Collapse;
  const { TextArea } = Input;
  const [loading, setloading] = useState(true);
  const [eventTeamDisciplinesList, setEventTeamDisciplinesList] = useState([]);
  const [mehiText, setMehiText] = useState("");
  const [judgeComment, setJudgeComment] = useState("");
  // const [moderatorComment, setModeratorComment] = useState(null);
  const [disciplineScore, setDisciplineScore] = useState(0);
  const [submittedStatus, setSubmittedStatus] = useState("");
  const [disciplineId, setDisciplineId] = useState(null);
  const [selectedDisplayCommentList1, setSelectedDisplayCommentList1] =
    useState([]);
  const [selectedDisplayCommentList2, setSelectedDisplayCommentList2] =
    useState([]);
  const [previewStatus, setPreviewStatus] = useState(false);
  const [usersCommentsList, setUsersCommentsList] = useState([]);

  const [performanceText, setPerformanceText] = useState(null);
  const inputRef = useRef(null);

  const loadTeamsDisciplines = () => {
    setloading(true);
    getData(EventApi.getEventTeamsDisciplines(eventId(), poolId, tId).url)
      .then((response) => {
        const data = response.data.disciplines;
        setEventTeamDisciplinesList(
          data.map((et) => ({
            key: et.id,
            id: et.id,
            name: et.name,
            status: et.status,
            children: et.children,
          }))
        );
      })
      .catch((e) => {
        toastr.error(e.data.message);
      })
      .finally(() => {
        setloading(false);
      });
  };

  const loadMehi = () => {
    getData(MehiApi.getMehi(eventId(), poolId, tId).url)
      .then((response) => {
        const data = response.data.mehi;
        setMehiText(data.mehi);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setloading(false);
      });
  };

  useEffect(() => {
    if (tId) {
      loadTeamsDisciplines();
      loadMehi();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tId]);

  const onChangeMehiText = (e) => {
    setMehiText(e.target.value);
  };

  const getDisciplinesId = async (key) => {
    onClose();
    setDisciplineId(key);
    setJudgeComment("");
    // setModeratorComment(null);
    setDisciplineScore(null);
    setSubmittedStatus("");
    setSelectedDisplayCommentList1([]);
    setSelectedDisplayCommentList2([]);
    setUsersCommentsList([]);
    setPreviewStatus(false);
    const newCommentsList = [];

    if (!key) {
      console.log(key);
    } else {
      getData(
        EventApi.getEventTeamsDisciplinesCommentStats(
          eventId(),
          poolId,
          tId,
          key
        ).url
      ).then((response) => {
        const data = response.data.stats;
        if (data !== null) {
          setJudgeComment(data.judgeComment);
          // setModeratorComment(data.moderatorComment);
          setDisciplineScore(data.disciplineScore);
          setSubmittedStatus(data.status);

          let store = [];
          (data.commentScores || []).map((item) => {
            store.push({
              id: item.comment.id,
              commentId: item.comment.id,
              name: item.comment.name,
              score: item.score,
              checkedValue: "true",
            });
            return item;
          });
          setSelectedDisplayCommentList1(store);
        }
      });

      getData(
        EventApi.getEventTeamsDisciplinesComment(eventId(), poolId, tId, key)
          .url
      ).then((response) => {
        const data = response.data.comments;
        data.map((item) => {
          newCommentsList.push({
            id: item.id,
            commentId: item.id,
            name: item.name,
            score: 0,
            checkedValue: "false",
          });
          return item;
        });

        setSelectedDisplayCommentList2(newCommentsList);
      });

      getData(
        EventApi.getUserCommentsList(eventId(), poolId, tId, key).url
      ).then((response) => {
        const data = response.data.commentTexts;
        setUsersCommentsList(data);
      });
    }
  };

  const handleWheel = (e) => {
    e.preventDefault();
    const input = inputRef.current;
    if (input) {
      input.blur();
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  const onClickParentDetails = async (key) => {
    setPerformanceText(key);
    setJudgeComment("");
    // setModeratorComment(null);
    setDisciplineScore(null);
    setSubmittedStatus("");
    setSelectedDisplayCommentList1([]);
    setSelectedDisplayCommentList2([]);
    setUsersCommentsList([]);
    setPreviewStatus(false);
    const newCommentsList = [];

    if (!key) {
      console.log(key);
    } else {
      getData(
        EventApi.getEventTeamsDisciplinesCommentStatsParent(
          eventId(),
          poolId,
          tId,
          disciplineId,
          key
        ).url
      ).then((response) => {
        const data = response.data.stats;

        if (data !== null) {
          setJudgeComment(data.judgeComment);
          // setModeratorComment(data.moderatorComment);
          setDisciplineScore(data.disciplineScore);
          setSubmittedStatus(data.status);
          let store = [];
          data.commentScores.map((item) => {
            store.push({
              id: item.comment.id,
              commentId: item.comment.id,
              name: item.comment.name,
              score: item.score,
              checkedValue: "true",
            });
            return item;
          });
          setSelectedDisplayCommentList1(store);
        }
      });

      getData(
        EventApi.getEventTeamsDisciplinesCommentParent(
          eventId(),
          poolId,
          tId,
          disciplineId
        ).url
      ).then((response) => {
        const data = response.data.comments;

        data.map((item) => {
          newCommentsList.push({
            id: item.id,
            commentId: item.id,
            name: item.name,
            score: 0,
            checkedValue: "false",
          });
          return item;
        });

        setSelectedDisplayCommentList2(newCommentsList);
      });

      getData(
        EventApi.getUserCommentsListParent(
          eventId(),
          poolId,
          tId,
          disciplineId,
          key
        ).url
      ).then((response) => {
        const data = response.data.commentTexts;
        setUsersCommentsList(data);
      });
    }
  };

  const onChangeComment = ({
    id,
    commentId = null,
    name = null,
    score = null,
    checkedValue = null,
  }) => {
    if (selectedDisplayCommentList1.findIndex((SMD) => SMD.id === id) > -1) {
      const selectedEntry = selectedDisplayCommentList1.find(
        (SMD) => SMD.id === id
      );

      if (commentId) selectedEntry.commentId = commentId;
      if (name) selectedEntry.name = name;
      if (score) selectedEntry.score = Number(score);
      if (checkedValue) selectedEntry.checkedValue = checkedValue;

      setSelectedDisplayCommentList1([
        ...selectedDisplayCommentList1.map((SMD) =>
          SMD.id === id ? selectedEntry : SMD
        ),
      ]);
    } else {
      console.log("Something wrong");
    }
  };

  const onChangeComment2 = ({
    id,
    commentId = null,
    name = null,
    score = null,
    checkedValue = null,
  }) => {
    if (selectedDisplayCommentList2.findIndex((SMD) => SMD.id === id) > -1) {
      const selectedEntry = selectedDisplayCommentList2.find(
        (SMD) => SMD.id === id
      );

      if (commentId) selectedEntry.commentId = commentId;
      if (name) selectedEntry.name = name;
      if (score) selectedEntry.score = Number(score);
      if (checkedValue) selectedEntry.checkedValue = checkedValue;
      setSelectedDisplayCommentList2([
        ...selectedDisplayCommentList2.map((SMD) =>
          SMD.id === id ? selectedEntry : SMD
        ),
      ]);
    } else {
      console.log("Something wrong");
    }
  };

  const onChangejudgeComment = (e) => {
    setJudgeComment(e.target.value);
  };

  const onChangeDisciplineScore = (e) => {
    // let { value, min, max } = e.target;
    // value = Math.max(Number(min), Math.min(Number(max), Number(value)));
    setDisciplineScore(e);
  };

  const onClose = (e) => {
    const x = document.querySelectorAll(".parentKey .ant-collapse-item-active");
    if (x.length) {
      for (let i = 0; i < x.length; i++) {
        setTimeout(function () {
          const el = document.querySelector(".ant-collapse-item-active");
          el.children[0].click();
        }, 100);
      }
    }
  };

  const savePost = async () => {
    let array1 = selectedDisplayCommentList1.filter(
      (o) => o.checkedValue === "true"
    );
    let array2 = selectedDisplayCommentList2.filter(
      (o) => o.checkedValue === "true"
    );

    let array3 = array1.concat(array2);
    const sortArrayMetaData = array3.map(({ checkedValue, ...rest }) => ({
      ...rest,
    }));

    if (disciplineScore === null) {
      toastr.error("Please fill score.");
    } else {
      setSubmittedStatus("SAVEDISABLED");
      const value = {
        commentScores: sortArrayMetaData,
        judgeComment: judgeComment,
        disciplineScore: disciplineScore,
        performanceName: performanceText,
      };
      setTimeout(function () {
        postData(
          EventApi.saveEventTeamsDisciplinesComment(
            eventId(),
            poolId,
            tId,
            disciplineId
          ).url,
          value
        )
          .then((response) => {
            toastr.success("Saved Succesfully");
            setSubmittedStatus("");
          })
          .catch((e) => {
            toastr.error(e.data.message);
            setSubmittedStatus("");
          })
          .finally(() => {
            setloading(false);
          });
      }, 500);
    }
  };

  const updatePost = async () => {
    setloading(true);
    setSubmittedStatus("JUDGE_SUBMITTED");

    let array1 = selectedDisplayCommentList1.filter(
      (o) => o.checkedValue === "true"
    );
    let array2 = selectedDisplayCommentList2.filter(
      (o) => o.checkedValue === "true"
    );

    let array3 = array1.concat(array2);
    const sortArrayMetaData = array3.map(({ checkedValue, ...rest }) => ({
      ...rest,
    }));

    if (disciplineScore === null) {
      toastr.error("Please fill score.");
    } else {
      const value = {
        commentScores: sortArrayMetaData,
        judgeComment: judgeComment,
        disciplineScore: disciplineScore,
        performanceName: performanceText,
      };

      setTimeout(function () {
        postData(
          EventApi.addEventTeamsDisciplinesComment(
            eventId(),
            poolId,
            tId,
            disciplineId
          ).url,
          value
        )
          .then((response) => {
            setSubmittedStatus(response.data.stats.status);
            toastr.success("Successfully Submitted");
            document.dispatchEvent(new Event("update-notifications"));
          })
          .catch((e) => {
            toastr.error(e.data.message);
            setSubmittedStatus("");
          })
          .finally(() => {
            setloading(false);
            loadTeamsDisciplines();
          });
      }, 500);
    }
  };

  const updateMehi = async () => {
    const value = {
      mehi: mehiText,
    };
    postData(MehiApi.getMehi(eventId(), poolId, tId).url, value)
      .then((response) => {
        toastr.success("Successfully Submitted");
        return response;
      })
      .catch((e) => {
        toastr.error(e.data.message);
      })
      .finally(() => {
        setloading(false);
      });
  };

  const onPreview = () => {
    let array1 = selectedDisplayCommentList1.filter(
      (o) => o.checkedValue === "true"
    );
    let array2 = selectedDisplayCommentList2.filter(
      (o) => o.checkedValue === "true"
    );

    let array3 = array1.concat(array2);

    if (disciplineScore === null) {
      toastr.error("Please fill score.");
    } else if (array3.length === 0 && judgeComment === "") {
      setPreviewStatus(previewStatus);
      toastr.error("Please enter comment");
    } else {
      setPreviewStatus(!previewStatus);
    }

    if (submittedStatus === "JUDGE_SAVED_AS_DRAFT") {
      if (judgeComment === undefined && array3.length === 0) {
        toastr.error("Please enter comment");
        setPreviewStatus(previewStatus);
      }
    }
  };

  const renderEventsInfoContent = () => {
    return (
      <div className="teamDisciplines">
        <Collapse accordion onChange={getDisciplinesId}>
          {eventTeamDisciplinesList.map((disc) => (
            <Panel
              header={disc.name}
              key={disc.id}
              extra={
                disc.status === "APPROVED"
                  ? "APPROVED"
                  : disc.status === "HEAD_JUDGE_REVIEW_REQUIRED"
                  ? "Head Judge Review"
                  : disc.status === "JUDGE_RESUBMITTED"
                  ? "Moderator Review"
                  : disc.status === "JUDGE_REVIEW_REQUIRED"
                  ? "Sent back to Judge"
                  : disc.status === "JUDGE_SUBMITTED"
                  ? "Moderator Review"
                  : disc.children === null
                  ? "Score Pending"
                  : disc.children.find(
                      (o) => o.status === "JUDGE_REVIEW_REQUIRED"
                    )
                  ? "Sent back to Judge"
                  : null
              }
            >
              {disc.children === null ? (
                <div>
                  {/*********Start Preview Screen Parent Null***********/}
                  <Row className={previewStatus ? "showText" : "hideText"}>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 16 }}
                      lg={{ span: 16 }}
                    >
                      {selectedDisplayCommentList1
                        .filter((o) => o.checkedValue === "true")
                        .map((item, index) => (
                          <Row className="previewStatus" key={index}>
                            <Col span={20}>
                              <Checkbox disabled>{item.name}</Checkbox>
                            </Col>
                            <Col span={4}>
                              <InputNumber
                                type="number"
                                value={item.score}
                                precision={0}
                                disabled
                              />
                            </Col>
                          </Row>
                        ))}
                      {selectedDisplayCommentList2
                        .filter((o) => o.checkedValue === "true")
                        .map((item, index) => (
                          <Row className="previewStatus" key={index}>
                            <Col span={20}>
                              <Checkbox disabled>{item.name}</Checkbox>
                            </Col>
                            <Col span={4}>
                              <InputNumber
                                type="number"
                                precision={0}
                                value={item.score}
                                disabled
                              />
                            </Col>
                          </Row>
                        ))}
                    </Col>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 8 }}
                      lg={{ span: 8 }}
                    >
                      <div className="scoreCircle">
                        <Input type="number" value={disciplineScore} disabled />
                      </div>
                    </Col>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 24 }}
                    >
                      <p>
                        <strong>Judge Comment</strong>
                      </p>
                      <TextArea rows={4} value={judgeComment} disabled />
                    </Col>
                  </Row>
                  {/*********ENd Preview Screen  Parent Null***********/
                  /*********Start Wroking Screen  Parent Null***********/}
                  <Row className={previewStatus ? "hideText" : "showText"}>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 16 }}
                      lg={{ span: 16 }}
                    >
                      {(selectedDisplayCommentList1 || []).map((item) => (
                        <Row key={item.id}>
                          <Col span={20}>
                            <Checkbox
                              onChange={(e) =>
                                onChangeComment({
                                  id: item.id,
                                  commentId: item.id,
                                  name: item.name,
                                  score: item.score,
                                  checkedValue: `${e.target.checked}`,
                                })
                              }
                              checked={
                                item.checkedValue === "true" ? true : false
                              }
                              disabled={
                                submittedStatus === "APPROVED" ||
                                submittedStatus ===
                                  "HEAD_JUDGE_REVIEW_REQUIRED" ||
                                submittedStatus === "JUDGE_RESUBMITTED" ||
                                submittedStatus === "JUDGE_SUBMITTED"
                                  ? true
                                  : false
                              }
                            >
                              {item.name}
                            </Checkbox>
                          </Col>
                          <Col span={4}>
                            <InputNumber
                              type="number"
                              defaultValue={item.score}
                              min={-5}
                              max={5}
                              precision={0}
                              onChange={(e) =>
                                onChangeComment({
                                  id: item.id,
                                  commentId: item.id,
                                  name: item.name,
                                  score: e,
                                })
                              }
                              disabled={
                                submittedStatus === "APPROVED" ||
                                submittedStatus ===
                                  "HEAD_JUDGE_REVIEW_REQUIRED" ||
                                submittedStatus === "JUDGE_RESUBMITTED" ||
                                submittedStatus === "JUDGE_SUBMITTED"
                                  ? true
                                  : false
                              }
                            />
                          </Col>
                        </Row>
                      ))}
                      {(selectedDisplayCommentList2 || [])
                        .filter(
                          ({ name: id1 }) =>
                            !selectedDisplayCommentList1.some(
                              ({ name: id2 }) => id2 === id1
                            )
                        )
                        .map((item) => (
                          <Row
                            key={item.id}
                            className={
                              submittedStatus === "APPROVED" ||
                              submittedStatus ===
                                "HEAD_JUDGE_REVIEW_REQUIRED" ||
                              submittedStatus === "JUDGE_RESUBMITTED" ||
                              submittedStatus === "JUDGE_SUBMITTED"
                                ? "hideText"
                                : "showText"
                            }
                          >
                            <Col span={20}>
                              <Checkbox
                                onChange={(e) =>
                                  onChangeComment2({
                                    id: item.id,
                                    name: item.name,
                                    score: item.score,
                                    checkedValue: `${e.target.checked}`,
                                  })
                                }
                                checked={
                                  item.checkedValue === "true" ? true : false
                                }
                                disabled={
                                  submittedStatus === "APPROVED" ||
                                  submittedStatus ===
                                    "HEAD_JUDGE_REVIEW_REQUIRED" ||
                                  submittedStatus === "JUDGE_RESUBMITTED" ||
                                  submittedStatus === "JUDGE_SUBMITTED"
                                    ? true
                                    : false
                                }
                              >
                                {item.name}
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <InputNumber
                                type="number"
                                defaultValue={item.score}
                                min={-5}
                                max={5}
                                precision={0}
                                onChange={(e) =>
                                  onChangeComment2({
                                    id: item.id,
                                    name: item.name,
                                    score: e,
                                  })
                                }
                                disabled={
                                  submittedStatus === "APPROVED" ||
                                  submittedStatus ===
                                    "HEAD_JUDGE_REVIEW_REQUIRED" ||
                                  submittedStatus === "JUDGE_RESUBMITTED" ||
                                  submittedStatus === "JUDGE_SUBMITTED"
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                          </Row>
                        ))}
                    </Col>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 8 }}
                      lg={{ span: 8 }}
                    >
                      <div className="scoreCircle2">
                        <InputNumber
                          type="number"
                          min={85}
                          max={100}
                          precision={0}
                          value={disciplineScore}
                          onChange={onChangeDisciplineScore}
                          placeholder="Score"
                          controls={false}
                          ref={inputRef}
                          onWheel={handleWheel}
                          disabled={
                            submittedStatus === "APPROVED" ||
                            submittedStatus === "HEAD_JUDGE_REVIEW_REQUIRED" ||
                            submittedStatus === "JUDGE_RESUBMITTED" ||
                            submittedStatus === "JUDGE_SUBMITTED"
                              ? true
                              : false
                          }
                          onKeyDown={handleKeyDown}
                        />
                      </div>
                    </Col>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 24 }}
                    >
                      <p
                        className={
                          submittedStatus === "APPROVED" ||
                          submittedStatus === "HEAD_JUDGE_REVIEW_REQUIRED" ||
                          submittedStatus === "JUDGE_RESUBMITTED" ||
                          submittedStatus === "JUDGE_SUBMITTED"
                            ? "hideText"
                            : "showText"
                        }
                      >
                        <strong>Judge Comment</strong>
                      </p>
                      <TextArea
                        rows={4}
                        placeholder="Type your comment here"
                        onChange={onChangejudgeComment}
                        value={judgeComment}
                        disabled={
                          submittedStatus === "APPROVED" ||
                          submittedStatus === "HEAD_JUDGE_REVIEW_REQUIRED" ||
                          submittedStatus === "JUDGE_RESUBMITTED" ||
                          submittedStatus === "JUDGE_SUBMITTED"
                            ? true
                            : false
                        }
                        className={
                          submittedStatus === "APPROVED" ||
                          submittedStatus === "HEAD_JUDGE_REVIEW_REQUIRED" ||
                          submittedStatus === "JUDGE_RESUBMITTED" ||
                          submittedStatus === "JUDGE_SUBMITTED"
                            ? "hideText"
                            : "showText"
                        }
                      />
                    </Col>

                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 24 }}
                    >
                      <div className="commentsListBox">
                        {usersCommentsList.length > 0 && (
                          <p>
                            <strong>Comments</strong>
                          </p>
                        )}
                        <ul>
                          {(usersCommentsList || []).map((item) => (
                            <li>
                              {item.roleName === "JUDGE" && (
                                <strong>Judge</strong>
                              )}
                              {item.roleName === "MODERATOR" && (
                                <strong>Moderator</strong>
                              )}
                              {item.roleName === "HEAD_JUDGE" && (
                                <strong>Head judge</strong>
                              )}
                              <br />
                              {item.comment}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col span={24}>
                      <div className="moderatorsFooter">
                        {!previewStatus ? (
                          <Button
                            type="primary"
                            onClick={() => savePost()}
                            disabled={
                              submittedStatus === "APPROVED" ||
                              submittedStatus ===
                                "HEAD_JUDGE_REVIEW_REQUIRED" ||
                              submittedStatus === "JUDGE_RESUBMITTED" ||
                              submittedStatus === "JUDGE_SUBMITTED" ||
                              submittedStatus === "SAVEDISABLED"
                                ? true
                                : false
                            }
                          >
                            Save
                          </Button>
                        ) : null}
                        <Button
                          type="primary"
                          onClick={onPreview}
                          disabled={
                            submittedStatus === "APPROVED" ||
                            submittedStatus === "HEAD_JUDGE_REVIEW_REQUIRED" ||
                            submittedStatus === "JUDGE_RESUBMITTED" ||
                            submittedStatus === "JUDGE_SUBMITTED"
                              ? true
                              : false
                          }
                        >
                          {previewStatus ? "Back" : "Preview"}
                        </Button>
                        {previewStatus ? (
                          <Popconfirm
                            title="Are you sure?"
                            onConfirm={() => updatePost()}
                          >
                            <Button
                              type="primary"
                              // onClick={() => updatePost()}
                              disabled={
                                submittedStatus === "APPROVED" ||
                                submittedStatus ===
                                  "HEAD_JUDGE_REVIEW_REQUIRED" ||
                                submittedStatus === "JUDGE_RESUBMITTED" ||
                                submittedStatus === "JUDGE_SUBMITTED"
                                  ? true
                                  : false
                              }
                            >
                              Submit
                            </Button>
                          </Popconfirm>
                        ) : null}
                      </div>
                    </Col>
                  </Row>

                  {/*********End Wroking Screen Parent Null***********/}
                </div>
              ) : (
                <div className="parentKey">
                  <Collapse accordion onChange={onClickParentDetails}>
                    {(disc.children || []).map((child) => (
                      <Panel
                        header={child.name}
                        key={child.name}
                        extra={
                          child.status === "APPROVED"
                            ? "Approved"
                            : child.status === "HEAD_JUDGE_REVIEW_REQUIRED"
                            ? "Head Judge Review"
                            : child.status === "JUDGE_RESUBMITTED"
                            ? "Moderator Review"
                            : child.status === "JUDGE_REVIEW_REQUIRED"
                            ? "Sent back to Judge"
                            : child.status === "JUDGE_SUBMITTED"
                            ? "Moderator Review"
                            : "Score Pending"
                        }
                      >
                        <div className="parentKeyBefore">
                          {/*********Start Preview Screen Parent Null***********/}
                          <Row
                            className={previewStatus ? "showText" : "hideText"}
                          >
                            <Col
                              xs={{ span: 24 }}
                              sm={{ span: 24 }}
                              md={{ span: 16 }}
                              lg={{ span: 16 }}
                            >
                              {selectedDisplayCommentList1
                                .filter((o) => o.checkedValue === "true")
                                .map((item, index) => (
                                  <Row className="previewStatus" key={index}>
                                    <Col span={20}>
                                      <Checkbox disabled>{item.name}</Checkbox>
                                    </Col>
                                    <Col span={4}>
                                      <InputNumber
                                        type="number"
                                        precision={0}
                                        value={item.score}
                                        disabled
                                      />
                                    </Col>
                                  </Row>
                                ))}
                              {selectedDisplayCommentList2
                                .filter((o) => o.checkedValue === "true")
                                .map((item, index) => (
                                  <Row className="previewStatus" key={index}>
                                    <Col span={20}>
                                      <Checkbox disabled>{item.name}</Checkbox>
                                    </Col>
                                    <Col span={4}>
                                      <InputNumber
                                        type="number"
                                        precision={0}
                                        value={item.score}
                                        disabled
                                      />
                                    </Col>
                                  </Row>
                                ))}
                            </Col>
                            <Col
                              xs={{ span: 24 }}
                              sm={{ span: 24 }}
                              md={{ span: 8 }}
                              lg={{ span: 8 }}
                            >
                              <div className="scoreCircle">
                                <Input
                                  type="number"
                                  value={disciplineScore}
                                  disabled
                                />
                              </div>
                            </Col>
                            <Col
                              xs={{ span: 24 }}
                              sm={{ span: 24 }}
                              md={{ span: 24 }}
                              lg={{ span: 24 }}
                            >
                              <p>
                                <strong>Judge Comment</strong>
                              </p>
                              <TextArea
                                rows={4}
                                value={judgeComment}
                                disabled
                              />
                            </Col>
                            {
                              // moderatorComment !== null ? (
                              //   <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                              //     <br />
                              //     <p><strong>Moderator Comment</strong></p>
                              //     <TextArea
                              //       rows={4}
                              //       placeholder="Type your comment here"
                              //       value={moderatorComment}
                              //       disabled
                              //     />
                              //   </Col>
                              // ) : null
                            }
                          </Row>
                          {/*********ENd Preview Screen  Parent Null***********/
                          /*********Start Wroking Screen  Parent Null***********/}
                          <Row
                            className={previewStatus ? "hideText" : "showText"}
                          >
                            <Col
                              xs={{ span: 24 }}
                              sm={{ span: 24 }}
                              md={{ span: 16 }}
                              lg={{ span: 16 }}
                            >
                              {(selectedDisplayCommentList1 || []).map(
                                (item) => (
                                  <Row key={item.id}>
                                    <Col span={20}>
                                      <Checkbox
                                        onChange={(e) =>
                                          onChangeComment({
                                            id: item.id,
                                            commentId: item.id,
                                            name: item.name,
                                            score: item.score,
                                            checkedValue: `${e.target.checked}`,
                                          })
                                        }
                                        checked={
                                          item.checkedValue === "true"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          submittedStatus === "APPROVED" ||
                                          submittedStatus ===
                                            "HEAD_JUDGE_REVIEW_REQUIRED" ||
                                          submittedStatus ===
                                            "JUDGE_RESUBMITTED" ||
                                          submittedStatus === "JUDGE_SUBMITTED"
                                            ? true
                                            : false
                                        }
                                      >
                                        {item.name}
                                      </Checkbox>
                                    </Col>
                                    <Col span={4}>
                                      <InputNumber
                                        type="number"
                                        defaultValue={item.score}
                                        min={-5}
                                        max={5}
                                        precision={0}
                                        onChange={(e) =>
                                          onChangeComment({
                                            id: item.id,
                                            commentId: item.id,
                                            name: item.name,
                                            score: e,
                                          })
                                        }
                                        disabled={
                                          submittedStatus === "APPROVED" ||
                                          submittedStatus ===
                                            "HEAD_JUDGE_REVIEW_REQUIRED" ||
                                          submittedStatus ===
                                            "JUDGE_RESUBMITTED" ||
                                          submittedStatus === "JUDGE_SUBMITTED"
                                            ? true
                                            : false
                                        }
                                      />
                                    </Col>
                                  </Row>
                                )
                              )}
                              {(selectedDisplayCommentList2 || [])
                                .filter(
                                  ({ name: id1 }) =>
                                    !selectedDisplayCommentList1.some(
                                      ({ name: id2 }) => id2 === id1
                                    )
                                )
                                .map((item) => (
                                  <Row
                                    key={item.id}
                                    className={
                                      submittedStatus === "APPROVED" ||
                                      submittedStatus ===
                                        "HEAD_JUDGE_REVIEW_REQUIRED" ||
                                      submittedStatus === "JUDGE_RESUBMITTED" ||
                                      submittedStatus === "JUDGE_SUBMITTED"
                                        ? "hideText"
                                        : "showText"
                                    }
                                  >
                                    <Col span={20}>
                                      <Checkbox
                                        onChange={(e) =>
                                          onChangeComment2({
                                            id: item.id,
                                            name: item.name,
                                            score: item.score,
                                            checkedValue: `${e.target.checked}`,
                                          })
                                        }
                                        checked={
                                          item.checkedValue === "true"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          submittedStatus === "APPROVED" ||
                                          submittedStatus ===
                                            "HEAD_JUDGE_REVIEW_REQUIRED" ||
                                          submittedStatus ===
                                            "JUDGE_RESUBMITTED" ||
                                          submittedStatus === "JUDGE_SUBMITTED"
                                            ? true
                                            : false
                                        }
                                      >
                                        {item.name}
                                      </Checkbox>
                                    </Col>
                                    <Col span={4}>
                                      <InputNumber
                                        type="number"
                                        defaultValue={item.score}
                                        min={-5}
                                        max={5}
                                        precision={0}
                                        onChange={(e) =>
                                          onChangeComment2({
                                            id: item.id,
                                            name: item.name,
                                            score: e,
                                          })
                                        }
                                        disabled={
                                          submittedStatus === "APPROVED" ||
                                          submittedStatus ===
                                            "HEAD_JUDGE_REVIEW_REQUIRED" ||
                                          submittedStatus ===
                                            "JUDGE_RESUBMITTED" ||
                                          submittedStatus === "JUDGE_SUBMITTED"
                                            ? true
                                            : false
                                        }
                                      />
                                    </Col>
                                  </Row>
                                ))}
                            </Col>
                            <Col
                              xs={{ span: 24 }}
                              sm={{ span: 24 }}
                              md={{ span: 8 }}
                              lg={{ span: 8 }}
                            >
                              <div className="scoreCircle2">
                                <InputNumber
                                  type="number"
                                  min={43}
                                  max={50}
                                  precision={0}
                                  value={disciplineScore}
                                  onChange={onChangeDisciplineScore}
                                  placeholder="Score"
                                  controls={false}
                                  ref={inputRef}
                                  onWheel={handleWheel}
                                  disabled={
                                    submittedStatus === "APPROVED" ||
                                    submittedStatus ===
                                      "HEAD_JUDGE_REVIEW_REQUIRED" ||
                                    submittedStatus === "JUDGE_RESUBMITTED" ||
                                    submittedStatus === "JUDGE_SUBMITTED"
                                      ? true
                                      : false
                                  }
                                  onKeyDown={handleKeyDown}
                                />
                              </div>
                            </Col>
                            <Col
                              xs={{ span: 24 }}
                              sm={{ span: 24 }}
                              md={{ span: 24 }}
                              lg={{ span: 24 }}
                            >
                              <p
                                className={
                                  submittedStatus === "APPROVED" ||
                                  submittedStatus ===
                                    "HEAD_JUDGE_REVIEW_REQUIRED" ||
                                  submittedStatus === "JUDGE_RESUBMITTED" ||
                                  submittedStatus === "JUDGE_SUBMITTED"
                                    ? "hideText"
                                    : "showText"
                                }
                              >
                                <strong>Judge Comment</strong>
                              </p>
                              <TextArea
                                rows={4}
                                placeholder="Type your comment here"
                                onChange={onChangejudgeComment}
                                value={judgeComment}
                                disabled={
                                  submittedStatus === "APPROVED" ||
                                  submittedStatus ===
                                    "HEAD_JUDGE_REVIEW_REQUIRED" ||
                                  submittedStatus === "JUDGE_RESUBMITTED" ||
                                  submittedStatus === "JUDGE_SUBMITTED"
                                    ? true
                                    : false
                                }
                                className={
                                  submittedStatus === "APPROVED" ||
                                  submittedStatus ===
                                    "HEAD_JUDGE_REVIEW_REQUIRED" ||
                                  submittedStatus === "JUDGE_RESUBMITTED" ||
                                  submittedStatus === "JUDGE_SUBMITTED"
                                    ? "hideText"
                                    : "showText"
                                }
                              />
                            </Col>
                            {
                              //   moderatorComment !== null ? (
                              //   <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                              //     <br />
                              //     <p
                              //       className={(
                              //         submittedStatus === 'APPROVED' ||
                              //         submittedStatus === 'HEAD_JUDGE_REVIEW_REQUIRED' ||
                              //         submittedStatus === 'JUDGE_RESUBMITTED' ||
                              //         submittedStatus === 'JUDGE_SUBMITTED' ||
                              //         submittedStatus === 'JUDGE_SAVED_AS_DRAFT' ||
                              //         submittedStatus === 'JUDGE_REVIEW_REQUIRED'
                              //       ) ? 'hideText' : 'showText'}
                              //     ><strong>Moderator Comment</strong></p>
                              //     <TextArea
                              //       rows={4}
                              //       placeholder="Type your comment here"
                              //       value={moderatorComment}
                              //       disabled={(
                              //         submittedStatus === 'APPROVED' ||
                              //         submittedStatus === 'HEAD_JUDGE_REVIEW_REQUIRED' ||
                              //         submittedStatus === 'JUDGE_RESUBMITTED' ||
                              //         submittedStatus === 'JUDGE_SUBMITTED' ||
                              //         submittedStatus === 'JUDGE_SAVED_AS_DRAFT' ||
                              //         submittedStatus === 'JUDGE_REVIEW_REQUIRED'
                              //       ) ? true : false}
                              //       className={(
                              //         submittedStatus === 'APPROVED' ||
                              //         submittedStatus === 'HEAD_JUDGE_REVIEW_REQUIRED' ||
                              //         submittedStatus === 'JUDGE_RESUBMITTED' ||
                              //         submittedStatus === 'JUDGE_SUBMITTED' ||
                              //         submittedStatus === 'JUDGE_SAVED_AS_DRAFT' ||
                              //         submittedStatus === 'JUDGE_REVIEW_REQUIRED'
                              //       ) ? 'hideText' : 'showText'}
                              //     />
                              //   </Col>
                              // ) : null
                            }

                            <Col
                              xs={{ span: 24 }}
                              sm={{ span: 24 }}
                              md={{ span: 24 }}
                              lg={{ span: 24 }}
                            >
                              <div className="commentsListBox">
                                {usersCommentsList.length > 0 && (
                                  <p>
                                    <strong>Comments</strong>
                                  </p>
                                )}
                                <ul>
                                  {(usersCommentsList || []).map((item) => (
                                    <li>
                                      {item.roleName === "JUDGE" && (
                                        <strong>Judge</strong>
                                      )}
                                      {item.roleName === "MODERATOR" && (
                                        <strong>Moderator</strong>
                                      )}
                                      {item.roleName === "HEAD_JUDGE" && (
                                        <strong>Head judge</strong>
                                      )}
                                      <br />
                                      {item.comment}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col span={24}>
                              <div className="moderatorsFooter">
                                {!previewStatus ? (
                                  <Button
                                    type="primary"
                                    onClick={() => savePost()}
                                    disabled={
                                      submittedStatus === "APPROVED" ||
                                      submittedStatus ===
                                        "HEAD_JUDGE_REVIEW_REQUIRED" ||
                                      submittedStatus === "JUDGE_RESUBMITTED" ||
                                      submittedStatus === "JUDGE_SUBMITTED" ||
                                      submittedStatus === "SAVEDISABLED"
                                        ? true
                                        : false
                                    }
                                  >
                                    Save
                                  </Button>
                                ) : null}
                                <Button
                                  type="primary"
                                  onClick={onPreview}
                                  disabled={
                                    submittedStatus === "APPROVED" ||
                                    submittedStatus ===
                                      "HEAD_JUDGE_REVIEW_REQUIRED" ||
                                    submittedStatus === "JUDGE_RESUBMITTED" ||
                                    submittedStatus === "JUDGE_SUBMITTED"
                                      ? true
                                      : false
                                  }
                                >
                                  {previewStatus ? "Back" : "Preview"}
                                </Button>
                                {previewStatus ? (
                                  <Popconfirm
                                    title="Are you sure?"
                                    onConfirm={() => updatePost()}
                                  >
                                    <Button
                                      type="primary"
                                      // onClick={() => updatePost()}
                                      disabled={
                                        submittedStatus === "APPROVED" ||
                                        submittedStatus ===
                                          "HEAD_JUDGE_REVIEW_REQUIRED" ||
                                        submittedStatus ===
                                          "JUDGE_RESUBMITTED" ||
                                        submittedStatus === "JUDGE_SUBMITTED"
                                          ? true
                                          : false
                                      }
                                    >
                                      Submit
                                    </Button>
                                  </Popconfirm>
                                ) : null}
                              </div>
                            </Col>
                          </Row>

                          {/*********End Wroking Screen Parent Null***********/}
                        </div>
                      </Panel>
                    ))}
                  </Collapse>
                </div>
              )}
            </Panel>
          ))}
        </Collapse>
      </div>
    );
  };

  const renderBreadcrumb = () => (
    <Breadcrumb>
      <Breadcrumb.Item href="/">{eventName}</Breadcrumb.Item>
      <Breadcrumb.Item onClick={() => history.goBack()}>
        <span>{teamName}</span>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        <span>Disciplines</span>
      </Breadcrumb.Item>
    </Breadcrumb>
  );

  const renderMehi = () => {
    if (loading) return <LoadingView message="Loading..." />;
    return (
      <div className="mehiRow">
        <Input
          placeholder="The Mihi entered by Judge"
          onChange={onChangeMehiText}
          value={mehiText}
        />
        <Button type="primary" onClick={() => updateMehi()}>
          Update
        </Button>
      </div>
    );
  };

  return (
    <React.Fragment>
      {renderBreadcrumb()}
      <div className="EventTeams">
        {renderMehi()}
        {renderEventsInfoContent()}
      </div>
    </React.Fragment>
  );
};

export default EventJudges;
