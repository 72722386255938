const getDisciplines = () => ({
  url: `/disciplines`,
});
const getDisciplinesById = disciplinesID => ({
  url: `/disciplines/${disciplinesID}`,
});
const addDisciplines = () => ({
  url: `/disciplines`,
});
const updateEditDisciplines = disciplinesID => ({
  url: `/disciplines/${disciplinesID}`,
});
const getEligibleParents = () => ({
  url: `/disciplines/eligible-parents`,
});
const getDisciplinesExcludeParents = () => ({
  url: `/disciplines/exclude-parents`,
});

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getDisciplines,
  getDisciplinesById,
  addDisciplines,
  updateEditDisciplines,
  getEligibleParents,
  getDisciplinesExcludeParents,
};

