const getTeams = () => ({
  url: `/teams`,
});
const getTeamsById = teamID => ({
  url: `/teams/${teamID}`,
});
const addTeams = () => ({
  url: `/teams`,
});
const getRegions = () => ({
  url: `/regions`,
});
const updateEditTeam = teamID => ({
  url: `/teams/${teamID}`,
});
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getTeams,
  getTeamsById,
  addTeams,
  getRegions,
  updateEditTeam,
};

